/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Col, Row } from "antd";
import InstaIcon from "assets/icons/social-icons/insta-icon.svg";
import FbIcon from "assets/icons/social-icons/fb-icon.svg";
import { useMediaQuery } from "react-responsive";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CopyrightSection = () => {
    const year = new Date().getFullYear();
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    return (
        <div className="mb-4">
            <Row gutter={[16, 0]} className="justify-content-between">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={`${isMobile ? "justify-content-center text-center" : ""} regular-16 sub-text-color w-100`}>
                        Copyright &copy; {year} Emolo - All Rights Reserved.
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={`${isMobile ? "justify-content-center mt-3" : "justify-content-end"} d-flex w-100`}>
                        <a
                            href={"https://wa.me/18185611034"}
                            className="orange-text-color text-center"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{ width: "22px", height: "22px" }}
                        >
                            <FontAwesomeIcon
                                className="w-100 h-100"
                                icon={faWhatsapp}
                                /* style={{ marginTop: isMobile ? "2px" : "5px" }} */
                            />
                        </a>
                        <a
                            href={"#"}
                            className="text-center disabled-link ms-4"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{ width: "22px", height: "22px" }}
                        >
                            <img className="w-100 h-100" src={FbIcon} alt="facebook" />
                        </a>
                        <a
                            href={"#"}
                            className="text-center disabled-link ms-4"
                            target={"_blank"}
                            rel="noreferrer"
                            style={{ width: "22px", height: "22px" }}
                        >
                            <img className="w-100 h-100" src={InstaIcon} alt="instagram" />
                        </a>
                    </div>

                </Col>
            </Row>

        </div>
    );
};

export default CopyrightSection;
