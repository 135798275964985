import { HOME, MY_ACCOUNT } from "constants/routes-constants";
import { Route, Switch, useHistory } from "react-router-dom";
import useRoute from "routes/useRoute";

const ProtectedRoutes = () => {

  const [routes] = useRoute();
  const history = useHistory();

  return (

    <Switch>
      {
        routes.map(({ component: Component, path, exact, data = null, hasPermission, accessAllowed }) => (
          <Route
            path={`${path}`}
            key={path}
            exact={exact}
            render={() =>
              accessAllowed
                ? hasPermission
                  ? <Component data={data} />
                  : history.push(HOME)
                : history.push(MY_ACCOUNT)
            }
          />
        ))
      }
    </Switch>
  );
};

export default ProtectedRoutes;
