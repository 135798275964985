/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { apiPost } from "services/apis";
import { errorResponseMapper } from "services/errorResponseMapper";
import { useParams } from "react-router-dom";
import "./product-view.css";
import LoadingSpinner from "../loading-spinner";
import ProductImgs from "./product-imgs";
import RelatedProducts from "./related-products";
import ProductDescription from "./product-description";
import ProductInfo from "./product-info";
import { useMediaQuery } from "react-responsive";
import Breadcrumb from "../breadcrumb";
import { HOME, PRODUCTS } from "constants/routes-constants";
import { useSelector } from "react-redux";
import { REVIEWS_PAGE_SIZE } from "constants/app-constants";
import { modifyFileKey } from "services/helpers";

export default function ProductDetails() {
  const { id } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const credentials = useSelector((state) => state.credentials);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [isGetReviewsLoading, setIsGetReviewsLoading] = useState(false);
  const [reviewsList, setReviewsList] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortValue, setSortValue] = useState("date_desc");

  useEffect(() => {
    getData();
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (data) {
      getReviews(data?.key);
    }
  }, [data, currentPage, sortValue]);

  const getData = () => {
    setIsLoading(true);
    const url = credentials?.isLoggedIn ? "/item/get_store_item_info" : "/item/get_home_item_info";
    const postData = { item: decodeURIComponent(id) };
    apiPost(url, { ...postData })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setData(res?.data?.payload?.item);
        } else {
          message.error(errorResponseMapper(res.data));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const getReviews = (key) => {

    setIsGetReviewsLoading(true);
    apiPost("/store_order/get_item_reviews", {
      item_id: key,
      order_by: sortValue?.split("_")[0],
      direction: sortValue?.split("_")[1],
      page: currentPage,
      page_size: REVIEWS_PAGE_SIZE,
    })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setPages(res?.data?.payload?.pages);
          setReviewsList(res?.data?.payload);
        } else {
          message.error(errorResponseMapper(res.data));
        }
      })
      .finally(() => setIsGetReviewsLoading(false));
  };

  const updateAfterWishlist = () => {
    setData({ ...data, is_favorite: !data?.is_favorite });
  };

  const keywords = `${data?.title} UPC: ${data?.upc} ${data?.model && `Model: ${data?.model} `} ${data?.description}`;

  return (
    <div>
      {
        isLoading
          ? <LoadingSpinner />
          : (
            <div className="mt-3">
              <Breadcrumb
                data={[{ path: HOME, title: "Home" }, { path: PRODUCTS, title: "Products" }]}
                title={data?.title}
                showBack={true}
                metaDetails={{
                  title: data?.title,
                  description: data?.description,
                  imageUrl: data?.[modifyFileKey("image")]?.length > 0 && data?.[modifyFileKey("image")][0],
                  url: window.location.origin + window.location.pathname,
                  keywords: keywords
                }}
              />
              <Row className={`mt-4 ${isMobile && "mx-0"}`} gutter={[30, 30]}>
                <Col className={isMobile && "px-0"} lg={10} md={10} sm={24} xs={24}>
                  <ProductImgs data={data} />
                </Col>
                <Col className={isMobile && "px-0"} lg={14} md={14} sm={24} xs={24} >
                  <ProductInfo data={data} updateAfterWishlist={updateAfterWishlist} />
                </Col>
              </Row >
              <div className="mt-5">
                <ProductDescription
                  data={data}
                  pages={pages}
                  sortValue={sortValue}
                  setSortValue={setSortValue}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  getReviews={getReviews}
                  isGetReviewsLoading={isGetReviewsLoading}
                  reviewsList={reviewsList}
                />
              </div>
            </div >
          )
      }
      <div className="mt-5">
        <RelatedProducts itemId={id} />
      </div>
    </div >
  );
}
