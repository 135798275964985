import React, { useEffect, useState } from "react";
import CategoriesMenu from "components/new-order/categories-menu";
import { Col, Row } from "antd";
import Products from "components/new-order/products";
import { useMediaQuery } from "react-responsive";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiPost } from "services/apis";
import { cancelPreviousPageRequests } from "services/axios-instance";
import axios from "axios";
import { LIMIT } from "constants/app-constants";
import useQueryParams from "hooks/useQueryParams";
import ProductsHeader from "components/new-order/products/products-header";

export default function NewOrder({ is_favorite = false }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const credentials = useSelector((state) => state?.credentials);
  const { id } = useParams();
  const { search } = useLocation();
  const { queryParams } = useQueryParams({ item: "", min_price: null, max_price: null, category: [], brand: [], page: 1, order_by: "", direction: "", min_rating: null });
  const { item, min_price, max_price, category, brand, page, order_by, direction, min_rating } = queryParams;

  const [isGrid, setIsGrid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getStoreItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getStoreItems = () => {
    cancelPreviousPageRequests(window.location.pathname);
    let url = credentials?.isLoggedIn ? `/item/get_store_items` : `/item/get_landing_items`;
    if (!isLoading) {
      setIsLoading(true);
    }
    apiPost(url, {
      data_type: credentials?.isLoggedIn ? "store_items" : undefined,
      page_size: LIMIT,
      page: page ?? 1,
      item: item,
      category: category ? JSON.stringify(category) : undefined,
      brands: brand ? JSON.stringify(brand) : undefined,
      min_price: min_price,
      max_price: max_price,
      min_rating: min_rating,
      order_by: order_by,
      direction: direction,
      is_favorite: is_favorite,
    })
      .then((res) => {
        if (res?.data?.status === "SUCCESS") {
          let itemsLocal = res?.data?.payload?.items ?? [];
          setItems([...itemsLocal]);
          setPages(res?.data?.payload?.pages);
        }
        setIsLoading(false);
      })
      .catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          setIsLoading(false);
        }
      });
  };

  return (
    <div>
      <ProductsHeader />
      <Row gutter={[30]} className="mx-0">
        {!isMobile && (
          <Col span={5} className="ps-0">
            <CategoriesMenu />
          </Col>
        )}
        <Col span={isMobile ? 24 : 19} className={`${isMobile && "ps-0"} pe-0`}>
          <Products
            setIsGrid={setIsGrid}
            isGrid={isGrid}
            is_favorite={is_favorite}
            id={id}
            getStoreItems={getStoreItems}
            isLoading={isLoading}
            items={items}
            pages={pages}
            setItems={setItems}
          />
        </Col>
      </Row>
    </div>
  );
}
