import { HeaderMenuItems, StyledDrawer } from "styles/globalStyling.styled";
import MobileMenuCloseIcon from "assets/icons/header/mobile-menu-close-icon.svg";
import { CONTACT_US, HOME, LOG_IN, PRODUCTS, SIGNUP } from "constants/routes-constants";
import { Button, Menu } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MobileMenu({ setIsLanMenuOpen, isLanMenuOpen }) {
    const history = useHistory();
    const credentials = useSelector((state) => state?.credentials);

    return (
        <StyledDrawer
            className="landing-menu-mobile"
            closable
            placement="right"
            onClose={() => { setIsLanMenuOpen(false) }}
            open={isLanMenuOpen}
            closeIcon={
                <div
                    style={{
                        width: "200px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <img className="ms-auto" src={MobileMenuCloseIcon} style={{ height: "22px" }} alt="" />
                </div>
            }
        >
            <Menu
                theme="light"
                defaultSelectedKeys={[HOME]}
                selectedKeys={[history.location.pathname]}
                mode="inline"
                onSelect={() => { setIsLanMenuOpen(false) }}
            >
                <HeaderMenuItems key={HOME}>
                    <Link className="regular-18" to={HOME}>HOME</Link>
                </HeaderMenuItems>
                <HeaderMenuItems key={PRODUCTS}>
                    <Link className="regular-18" to={PRODUCTS}>PRODUCTS</Link>
                </HeaderMenuItems>
                <HeaderMenuItems key={CONTACT_US}>
                    <Link className="regular-18" to={CONTACT_US}>CONTACT US</Link>
                </HeaderMenuItems>

            </Menu>

            {
                !credentials?.isLoggedIn && (
                    <div className="w-100 text-center" style={{ position: "absolute", bottom: "45px" }}>
                        <Button
                            type="ghost"
                            className="mx-auto fs-16 d-block bold16"
                            onClick={() => {
                                history.push(LOG_IN);
                                setIsLanMenuOpen(false);
                            }}
                            style={{
                                color: "#0073B4",
                                border: "2px solid #0073B4",
                                borderRadius: "40px",
                                background: "transparent",
                                width: "108px",
                                height: "40px",
                                paddingTop: "6px"
                            }}
                        >
                            Login
                        </Button>
                        <Button
                            className="mx-auto d-block mt-4 bold16"
                            type="primary"
                            onClick={() => {
                                history.push(SIGNUP);
                                setIsLanMenuOpen(false);
                            }}
                            style={{
                                width: "135px",
                                height: "40px",
                                borderRadius: "40px",
                                color: "white",
                                paddingTop: "6px"
                            }}
                        >
                            Register
                        </Button>
                    </div>
                )
            }

        </StyledDrawer>
    )
}