import { Button, Form } from "antd";
import React, { useState } from "react";
import { apiPost } from "services/apis";
import { errorResponseMapper } from "services/errorResponseMapper";
import { getCredentials, getUser } from "reduxToolkit/checkCredentials.redux";

import { useDispatch } from "react-redux";
import { setAuthToken } from "services/authentication";
import { HOME } from "constants/routes-constants";
import { useHistory } from "react-router-dom";
import PasswordCounter from "components/password-counter/password-counter";
import { getBrowser, getDeviceType } from 'services/helpers';
import ReactCodeComponent from "components/common/react-code-component";
import { setPermissions } from "reduxToolkit/appReducer.redux";

export default function TwoFactor({
  userId,
  verif_req_id,
  setVerif_req_id,
  userType
}) {
  const history = useHistory();
  const browser = getBrowser();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();
  const [form2] = Form.useForm();

  const [counter, setCounter] = useState(true);
  const [callBackCounter, setCallBackCounter] = useState(false);

  const verify = (value) => {
    setErrorMessage("");
    setIsLoading(true);
    apiPost("/verify_login", {
      user_id: userId,
      code: value,
      verif_req_id: verif_req_id ?? "",
      user_type: userType,
      device_type: getDeviceType(),
      browser_name: browser.name,
      browser_version: browser.version,
      hostname: window.location.origin
    })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setAuthToken(res.data.payload.auth_token);
          dispatch(getCredentials());
          dispatch(getUser(res.data.payload.user));
          dispatch(setPermissions(res?.data?.payload?.user_type?.toLowerCase()));
          history.push(HOME);
        } else {
          setErrorMessage(errorResponseMapper(res.data));
          form2.resetFields(["code"]);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const identify = () => {
    setErrorMessage("");
    setIsLoading(true);
    setCounter(!counter);
    setCallBackCounter(true);
    apiPost("/identify", {
      user_id: userId,
      verification_method: "EMAIL",
      verification_type: "LOGIN",
      user_type: userType
    })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setVerif_req_id(res.data.payload.verif_req_id);
        } else {
          setErrorMessage(errorResponseMapper(res.data));
        }
      })
      .finally(() => setIsLoading(false));
  };


  return (
    <div>
      <Form
        form={form2}
        onFinish={(value) => verify(value.code)}
        layout="vertical"
      >
        <center>
          <Form.Item
            className="mt-3"
            name="code"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <ReactCodeComponent />
          </Form.Item>
        </center>

        {errorMessage && (
          <center>
            <span style={{ color: "red" }}>{errorMessage}</span>
          </center>
        )}

        <center>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="signup-button w-25"
          >
            Submit
          </Button>
          <br />
          <Button
            disabled={counter}
            className="mt-4"
            style={{ color: "#565f82" }}
            type="link"
            onClick={() => {
              setCallBackCounter(true);
              identify();
            }}
          >
            Resend code
          </Button>
          {callBackCounter && (
            <PasswordCounter
              setCounter={setCounter}
              setCallBackCounter={setCallBackCounter}

            />
          )}
        </center>
      </Form>
    </div>
  );
}
