import { Col, Form, Row } from "antd";
import React from "react";
import ReactCodeInput from "react-code-input";
import { useMediaQuery } from "react-responsive";

export default function VerificationCode() {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center" lg={24}>
          <span className="headline-5 text-center">
            Enter verification code received by email
          </span>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          className="d-flex justify-content-center"
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
        >
          <Form.Item
            className="mt-5"
            name="code"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <ReactCodeInput
              inputStyle={{
                width: isMobile ? "40px" : "60px",
                height: isMobile ? "40px" : "60px",
                fontSize: "23px",
                margin: "21px",
                background: " #FFFFFF",
                borderRadius: "4px",
              }}
              fields={4}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
