import { createSlice } from "@reduxjs/toolkit";
import { USER_TYPE_VENDOR } from "constants/app-constants";

const initialState = {
  cart: [],
  categories: [],
  isCategoriesLoaded: false,
  brands: [],
  isBrandsLoaded: false,
  landingSelectedCategory: "",
  mainFilter: { item: "", min_price: "", max_price: "", category: [], brand: [], page: 1 },
  favoriteFilter: { item: "", min_price: "", max_price: "", category: [], brand: [], page: 1 },
  notificationCount: 0,
  userType: "",
  permissions: {
    view_cart: false,
    view_products: true, //true, it will be shown on landing page
    view_catalog: false,
    view_home: true, //true, it will be shown as a landing page
    view_my_orders: false,
    view_new_order: false,
    view_rma: false,
    view_mobile_checks: false,
    view_manage_inventories: false,
    view_favorites: false,
    view_recent_activities: false
  },
  switchValid: false
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setIsCategoriesLoaded: (state, action) => {
      state.isCategoriesLoaded = action.payload;
    },
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setIsBrandsLoaded: (state, action) => {
      state.isBrandsLoaded = action.payload;
    },
    setSelectedLandingCategory: (state, action) => {
      state.landingSelectedCategory = action.payload;
    },
    setSelectedFilteringCirteria: (state, action) => {
      let is_favorite = action.payload?.is_favorite
      if (is_favorite) {
        state.favoriteFilter = {
          ...state.favoriteFilter,
          ...action.payload
        };
      } else {
        state.mainFilter = {
          ...state.mainFilter,
          ...action.payload
        };
      }
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setPermissions: (state, action) => {
      if (action.payload === USER_TYPE_VENDOR) {
        state.permissions = {
          view_manage_inventories: true,
          view_products: false,
          view_cart: false,
          view_catalog: false,
          view_home: true,
          view_my_orders: false,
          view_new_order: false,
          view_rma: false,
          view_mobile_checks: false,
          view_favorites: false,
          view_recent_activities: false
        };
      } else {
        state.permissions = {
          view_cart: true,
          view_products: true, //true, it will be shown on landing page
          view_catalog: true,
          view_home: true, //true, it will be shown as a landing page
          view_my_orders: true,
          view_new_order: true,
          view_rma: true,
          view_mobile_checks: true,
          view_manage_inventories: false,
          view_favorites: true,
          view_recent_activities: true,
        }
      }
    },
    setSwitchValid: (state, action) => {
      state.switchValid = action.payload;
    },
  },
});

export const {
  setCategories,
  setIsCategoriesLoaded,
  setBrands,
  setIsBrandsLoaded,
  setSelectedLandingCategory,
  setSelectedFilteringCirteria,
  setNotificationCount,
  setUserType,
  setPermissions,
  setSwitchValid
} = appSlice.actions;

export default appSlice.reducer;
