import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-input-2";
import PasswordChecklist from "react-password-checklist";
import { useMediaQuery } from 'react-responsive';
import { StyledButton, StyledInput, StyledPasswordInput } from 'styles/globalStyling.styled';


export default function ContactInformation({ hiddenInput, setHiddenInput, password, setPassword, confirm, setConfirm, setIsValid, validatePersonalInfo }) {

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    return (
        <>
            <div className='bold-20 primary-color'>Contact Information</div>
            <div className='mt-3'>
                <Form.Item name="hiddenInput" style={{ display: "none" }}>
                    <Input
                        value={hiddenInput}
                        onChange={(e) => setHiddenInput(e.target.value)}
                    />
                </Form.Item>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Contact Name<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="contact_name"
                            rules={[{ required: true, message: "This field is required!" }]}
                        >
                            <StyledInput placeholder="Enter Contact Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Company Name</label>
                        <Form.Item name="company_name">
                            <StyledInput placeholder="Enter Company Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <label className="regular-14">Email<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: "This field is required!" },
                                { type: "email", message: "Invalid email format" }
                            ]}
                        >
                            <StyledInput placeholder="Enter Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Phone Number<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="msisdn"
                            rules={[
                                { required: true, message: "This field is required!" },
                                () => ({
                                    validator(_, value) {
                                        if (value && isValidPhoneNumber("+" + value)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error("Invalid Phone Number!"));
                                    },
                                }),
                            ]}
                        >
                            <PhoneInput
                                country={"us"}
                                inputStyle={{ width: "100%", height: "36px" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Work Number</label>
                        <Form.Item
                            name="work_msisdn"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value || isValidPhoneNumber("+" + value)) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error("Invalid Work Number!"));
                                    },
                                }),
                            ]}
                        >
                            <PhoneInput
                                country={"us"}
                                inputStyle={{ width: "100%", height: "36px" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Password<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <StyledPasswordInput
                                value={password}
                                style={{ width: "100%" }}
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Confirm Password<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="confirm_password"
                            rules={[
                                {
                                    required: true,
                                    message: "This field is required!",
                                },
                            ]}
                        >
                            <StyledPasswordInput
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                value={confirm}
                                style={{ width: "100%" }}
                                placeholder="Confirm Password"
                                onChange={(e) => setConfirm(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={6}
                    value={password}
                    valueAgain={confirm}
                    onChange={(isValid) => { isValid ? setIsValid(true) : setIsValid(false); }}
                    style={{ display: "none" }}
                />
                <div className='w-100 d-flex justify-content-end'>
                    <StyledButton
                        type='primary'
                        onClick={() => validatePersonalInfo()}
                        style={{ width: isMobile ? "100%" : "200px" }}
                    >
                        Next
                    </StyledButton>
                </div>
            </div>
        </>
    );
}
