import { Button, Dropdown, Input, Select } from "antd";
import styled from "styled-components";

export const MyAccountMainContainer = styled.div`
  padding: ${(props) => props?.is_mobile ? "10px 10px" : "30px 180px"};
`;

export const MyAccountFormMainContainer = styled.div`
  padding: ${(props) => props?.is_mobile ? "10px 10px" : "25px 35px"};
`;

export const MyAccountChangePassMainContainer = styled.div`
  padding: 45px 265px;
`;
export const EditDropdown = styled(Dropdown)`
  width: ${(props = null) => props?.width ?? "140px"};
  height: ${(props = null) => props?.height ?? "50px"};
  background: #00B2FF;
  border-radius: 62px;
  color: #fff;
  cursor: pointer;
  padding: 0px 25px;
`;
export const MyAccountFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: ${(props = null) => props?.padding ?? "50px 75px"};
  margin-top: 10px;
`;
export const MyAccountChangePassFormContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: ${(props = null) => props?.padding ?? "50px 265px"};
  margin-top: 10px;
`;

export const UploadButtonPosition = styled.div`
  position: absolute;
  left: ${(props = null) => props?.left ?? "95px"};
  bottom: ${(props = null) => props?.bottom ?? "30px"};
`;
export const UploadAttachmentDiv = styled.div`
  background: #D9D9D929;
  border: 1px solid #26323829;
  border-radius: 8px;
  text-align: center;
  padding: 10px 0px;
`;
export const UploadButton = styled(Button)`
  width: 35px;
  height: 35px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
export const MyAccountCurrenciesContainer = styled.div`
  border: 1px solid rgb(38, 50, 56, 0.16);
  border-radius: 4px;
  width: ${(props = null) => props?.width ?? "fit-content"};
  height: ${(props = null) => props?.height ?? "55px"};
  display: flex;
  align-items: center;
  padding: 0px 10px;
`;
export const MyAccountSelect = styled(Select)`
  .ant-select-selection-placeholder {
    margin-top: 7.5px !important;
    margin-bottom: 6.1px !important;
  }
  .ant-select-selection-item {
    margin-top: 7.5px !important;
    margin-bottom: 6.1px !important;
  }
  .ant-select-selector {
    height: 45px !important;
    border: 1px solid rgb(38, 50, 56, 0.16) !important;
    border-radius: 8px !important;
  }
`;
export const FormButtons = styled(Button)`
  width: 180px;
  height: 50px;
  border-radius: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MyAccountInputs = styled(Input)`
  border: 1px solid rgba(38, 50, 56, 0.16);
  border-radius: 8px;
  height: 45px !important ;
`;
export const MyAccountInputsPass = styled(Input.Password)`
  border: 1px solid rgba(38, 50, 56, 0.16);
  border-radius: 8px;
  height: 56px !important ;
`;

export const FormListButton = styled(Button)`
  border: 1px solid #00B2FF;
  border-radius: 25px;
  color: #00B2FF;
  padding: 6px 8px;
  width: 121px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RemoveFormListButton = styled(Button)`
  border: 1px solid #333333;
  border-radius: 25px;
  padding: 6px 8px;
  width: 121px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    border: 1px solid #333333;
    border-radius: 25px;
    background-clip: text;
  }
  :active {
    border: 1px solid #333333;
    border-radius: 25px;
    background-clip: text;
    color: black;
  }
  :focus {
    border: 1px solid #333333;
    border-radius: 25px;
    background-clip: text;
    color: black;
  }
`;
