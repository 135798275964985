import { Alert, Avatar, Button, Dropdown, Menu, Rate, Space, message } from 'antd';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import DeleteReviewModal from './modals/delete-review-modal';
import { apiPost } from 'services/apis';
import { errorResponseMapper } from 'services/errorResponseMapper';
import { PRODUCT_DETAIL } from 'constants/routes-constants';
import { Link } from "react-router-dom";
import { getItemURI, modifyFileKey } from 'services/helpers';
import { useMediaQuery } from 'react-responsive';
import NoUser from 'assets/icons/no-profile-pic.png';
import DeleteReviewMenu from 'assets/icons/products/delete-review-menu.svg';

export default function ReviewComponent({ reviewKey, itemKey = null, review, getReviews }) {

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const link = PRODUCT_DETAIL.replace(":id", getItemURI(review?.item));

    const [isDeleteReviewLoading, setIsDeleteReviewLoading] = useState(false);
    const [isDeleteReviewModalOpen, setIsDeleteReviewModalOpen] = useState(false);

    const deleteReview = () => {
        setIsDeleteReviewLoading(true);
        apiPost("/store_order/delete_review", {
            review_id: reviewKey,
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    getReviews(itemKey);
                    setIsDeleteReviewModalOpen(false);
                    message.success("Your review was deleted successfully!");
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => setIsDeleteReviewLoading(false));
    };

    const ReviewItemInfo = ({ item }) => {
        return (<div className='d-flex align-items-center gap-2'>
            <img src={item?.[modifyFileKey("image")]} alt="item" style={{ width: "50px", maxHeight: "50px" }} />
            <div className='grids-text'>{item?.title}</div><b>{` (${item?.upc})`}</b>
        </div>);
    };

    const DeleteMenu = () => {
        return (
            <Menu style={{ borderRadius: "4px" }}>
                <Menu.Item
                    key={"delete-review"}
                    onClick={() => setIsDeleteReviewModalOpen(true)}
                >
                    <Button
                        danger
                        type='link'
                        className='p-0 m-0'
                    >
                        <FontAwesomeIcon icon={faTrashAlt} className='me-2' />
                        <span style={{ color: "#000" }} className='regular-14'>Delete</span>
                    </Button>
                </Menu.Item>
            </Menu>
        );
    };

    return (
        <div className='w-100'>
            <div className='w-100 d-flex align-items-center gap-2'>
                <div className='w-100 d-flex justify-content-between align-items-start'>
                    <div className='d-flex align-items-center gap-2'>
                        {itemKey && <div>
                            <Avatar src={NoUser} alt="profile-pic" size={64} />
                        </div>}
                        <div>
                            {itemKey && <div className='bold-16'>{`${review?.customer?.initials}`}</div>}
                            <div className='w-100 d-flex justify-content-between align-items-center'>
                                <div>
                                    <Rate disabled value={review?.rating} />
                                    {isMobile && <br />}
                                    <span className='ms-2 regular-14'>{review?.created_dt?.split(",")[0]}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(review?.belongs_to_customer || !itemKey) && <div>
                        <Dropdown
                            trigger={["click"]}
                            placement="bottomRight"
                            overlay={<DeleteMenu />}
                        >
                            <Space className="d-flex cursor-pointer">
                                <img src={DeleteReviewMenu} alt='delete-review' />
                            </Space>
                        </Dropdown>
                    </div>}
                </div>
            </div>
            {!itemKey && <Link to={{ pathname: link }}>
                <div className='mt-1 me-3'>
                    <Alert message={<ReviewItemInfo item={review?.item} />} type="info" />
                </div>
            </Link>}
            <div className='regular-14 mt-3'>
                {review?.comment}
            </div>

            {isDeleteReviewModalOpen && (review?.belongs_to_customer || !itemKey) && <DeleteReviewModal
                deleteReview={deleteReview}
                isDeleteReviewLoading={isDeleteReviewLoading}
                isModalOpen={isDeleteReviewModalOpen}
                setIsModalOpen={setIsDeleteReviewModalOpen}
            />}

        </div>
    );
}
