import React from "react";

import NewsLetterSection from "components/footer/news-letter-section";
import MiddleFooterSection from "components/footer/middle-footer-section";
import CopyrightSection from "components/footer/copyright-section";

const AppFooter = () => {

  return (
    <div className="mt-5">
      <NewsLetterSection />
      <div className="padding-main">
        <MiddleFooterSection />
        <CopyrightSection />
      </div>
    </div>
  );
};

export default React.memo(AppFooter);
