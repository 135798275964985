import { Checkbox, Col, Form, Row, Select, message } from 'antd';
import React, { useState } from 'react';
import { countries } from "constants/countries";
import { states } from "constants/states";
import { useMediaQuery } from 'react-responsive';
import { StyledButton, StyledGhostButton, StyledInput, StyledSelect } from 'styles/globalStyling.styled';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from "react-phone-number-input";

export default function BillingInformation({ setActiveKey, form, identify, isLoading, stateDisable, setStateDisable, stateShippingDisable, setStateShippingDisable }) {

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const [useSameAsBilling, setUseSameAsBilling] = useState(true);

    const isZipCode = (str) => {
        return /^\d{5}(-\d{4})?$/.test(str);
    };

    const use_billing = (value) => {
        if (value) {
            setUseSameAsBilling(true);
            const localBillingInfo = form.getFieldsValue();
            form.setFieldsValue({
                primary_address_shipping: localBillingInfo.primary_address,
                secondary_address_shipping: localBillingInfo.secondary_address,
                country_shipping: localBillingInfo.country,
                city_shipping: localBillingInfo.city,
                state_shipping: localBillingInfo.state,
                zip_code_shipping: localBillingInfo.zip_code,
                contact_name_shipping: localBillingInfo.contact_name,
                company_name_shipping: localBillingInfo.company_name,
                msisdn_shipping: localBillingInfo.msisdn,
                msisdn_work_shipping: localBillingInfo.msisdn_work,
            });
            setStateShippingDisable(stateDisable);
        } else {
            setUseSameAsBilling(false);
            form.resetFields([
                "primary_address_shipping",
                "secondary_address_shipping",
                "country_shipping",
                "state_shipping",
                "zip_code_shipping",
                "city_shipping",
                "contact_name_shipping",
                "company_name_shipping",
                "msisdn_shipping",
                "msisdn_work_shipping",
            ]);
            setStateShippingDisable(false);
        }
    };

    return (
        <>
            <div className='bold-20 primary-color'>Billing Information</div>
            <div className='mt-3'>
                <Row gutter={[20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <label className="regular-14">Address 1<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="primary_address"
                            rules={[{ required: true, message: "This field is required!" }]}
                        >
                            <StyledInput placeholder="Enter Address 1" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <label className="regular-14">Address 2</label>
                        <Form.Item name="secondary_address">
                            <StyledInput placeholder="Enter Address 2" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[20]}>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">City<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="city"
                            rules={[{ required: true, message: "This field is required!" }]}
                        >
                            <StyledInput placeholder="Enter City" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">State<span className='ms-1 secondary-red-color'>{!stateDisable && "*"}</span></label>
                        <Form.Item
                            name="state"
                            rules={[{ required: !stateDisable ? true : false, message: stateDisable ? "" : "This field is required!", },]}
                        >
                            <StyledSelect
                                disabled={stateDisable}
                                placeholder="Select state"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {states.map((state, index) => {
                                    return (
                                        <Select.Option value={state.name} key={"states-" + index}>
                                            {state.name}
                                        </Select.Option>
                                    );
                                })}
                            </StyledSelect>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Zip Code<span className='ms-1 secondary-red-color'>{!stateDisable && "*"}</span></label>
                        <Form.Item
                            name="zip_code"
                            rules={[
                                {
                                    required: stateDisable ? false : true,
                                    message: "This field is required!",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || (stateDisable ? true : isZipCode(value))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                "Please enter a valid postal code!"
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <StyledInput placeholder="Enter Zip Code" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <label className="regular-14">Country<span className='ms-1 secondary-red-color'>*</span></label>
                        <Form.Item
                            name="country"
                            rules={[{ required: true, message: "This field is required!" }]}
                            initialValue="us"
                        >
                            <StyledSelect
                                onSelect={(value) => {
                                    if (value === "us") {
                                        setStateDisable(false);
                                    } else {
                                        form.resetFields(["state"]);
                                        setStateDisable(true);
                                    }
                                }}
                                style={{ width: "100%" }}
                                placeholder="Select country"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children
                                        .toLowerCase()
                                        .localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {countries.map((country, index) => {
                                    return (
                                        <Select.Option
                                            value={country.iso_2}
                                            key={"countries-" + index}
                                        >
                                            {country.Name}
                                        </Select.Option>
                                    );
                                })}
                            </StyledSelect>
                        </Form.Item>
                    </Col>
                </Row>

                <Row className={`mt-2 ${useSameAsBilling && "mb-4"}`}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Checkbox
                            defaultChecked={useSameAsBilling}
                            onChange={(e) => {
                                use_billing(e.target.checked);
                                setUseSameAsBilling(e.target.checked);
                            }}
                        >
                            Shipping Address same as Billing Address
                        </Checkbox>
                    </Col>
                </Row>
                {!useSameAsBilling && <>
                    <div className='bold-20 primary-color mt-4'>Shipping Information</div>
                    <div>
                        <Row gutter={[20]} className='mt-3'>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Contact Name<span className='ms-1 secondary-red-color'>*</span></label>
                                <Form.Item
                                    name="contact_name_shipping"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                >
                                    <StyledInput placeholder="Enter Contact Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Company Name</label>
                                <Form.Item name="company_name_shipping">
                                    <StyledInput placeholder="Enter Company Name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[20]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Phone Number<span className='ms-1 secondary-red-color'>*</span></label>
                                <Form.Item
                                    name="msisdn_shipping"
                                    rules={[
                                        { required: true, message: "This field is required!" },
                                        () => ({
                                            validator(_, value) {
                                                if (value && isValidPhoneNumber("+" + value)) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("Invalid Phone Number!"));
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput
                                        country={"us"}
                                        inputStyle={{ width: "100%", height: "36px" }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Work Number</label>
                                <Form.Item
                                    name="work_msisdn_shipping"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (!value || isValidPhoneNumber("+" + value)) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("Invalid Work Number!"));
                                            },
                                        }),
                                    ]}
                                >
                                    <PhoneInput
                                        country={"us"}
                                        inputStyle={{ width: "100%", height: "36px" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[20]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <label className="regular-14">Address 1<span className='ms-1 secondary-red-color'>*</span></label>
                                <Form.Item
                                    name="primary_address_shipping"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                >
                                    <StyledInput placeholder="Enter Address 1" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[20]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <label className="regular-14">Address 2</label>
                                <Form.Item name="secondary_address_shipping">
                                    <StyledInput placeholder="Enter Address 2" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[20]}>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">City<span className='ms-1 secondary-red-color'>*</span></label>
                                <Form.Item
                                    name="city_shipping"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                >
                                    <StyledInput placeholder="Enter City" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">State<span className='ms-1 secondary-red-color'>{!stateShippingDisable && "*"}</span></label>
                                <Form.Item
                                    name="state_shipping"
                                    rules={[{ required: !stateShippingDisable ? true : false, message: stateShippingDisable ? "" : "This field is required!", },]}
                                >
                                    <StyledSelect
                                        disabled={stateShippingDisable}
                                        placeholder="Select state"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) === 0}
                                        filterSort={(optionA, optionB) => optionA.children
                                            .toLowerCase()
                                            .localeCompare(optionB.children.toLowerCase())}
                                    >
                                        {states.map((state, index) => {
                                            return (
                                                <Select.Option value={state.name} key={"states-" + index}>
                                                    {state.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </StyledSelect>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Zip Code<span className='ms-1 secondary-red-color'>{!stateShippingDisable && "*"}</span></label>
                                <Form.Item
                                    name="zip_code_shipping"
                                    rules={[
                                        {
                                            required: stateShippingDisable ? false : true,
                                            message: "This field is required!",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (value && (stateShippingDisable ? true : isZipCode(value))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Please enter a valid postal code!"
                                                    )
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <StyledInput placeholder="Enter Zip Code" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <label className="regular-14">Country<span className='ms-1 secondary-red-color'>*</span></label>
                                <Form.Item
                                    name="country_shipping"
                                    rules={[{ required: true, message: "This field is required!" }]}
                                    initialValue="us"
                                >
                                    <StyledSelect
                                        onSelect={(value) => {
                                            if (value === "us") {
                                                setStateShippingDisable(false);
                                            } else {
                                                form.resetFields(["state_shipping"]);
                                                setStateShippingDisable(true);
                                            }
                                        }}
                                        style={{ width: "100%" }}
                                        placeholder="Select country"
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) === 0}
                                        filterSort={(optionA, optionB) => optionA.children
                                            .toLowerCase()
                                            .localeCompare(optionB.children.toLowerCase())}
                                    >
                                        {countries.map((country, index) => {
                                            return (
                                                <Select.Option
                                                    value={country.iso_2}
                                                    key={"countries-" + index}
                                                >
                                                    {country.Name}
                                                </Select.Option>
                                            );
                                        })}
                                    </StyledSelect>
                                </Form.Item>
                            </Col>
                        </Row>

                    </div>
                </>}
                <div className='w-100 d-flex justify-content-between'>
                    <StyledGhostButton
                        type='default'
                        style={{ width: isMobile ? "100px" : "200px" }}
                        onClick={() => setActiveKey("contact-info")}
                    >
                        Back
                    </StyledGhostButton>
                    <StyledButton
                        type='primary'
                        loading={isLoading}
                        onClick={() => {
                            if (useSameAsBilling) {
                                use_billing(true);
                            }
                            form.validateFields().then(() => {
                                identify();
                            }).catch(() => {
                                message.error("Some required fields are still not filled!");
                            });
                        }}
                        style={{ width: isMobile ? "100px" : "200px" }}
                    >
                        Next
                    </StyledButton>
                </div>
            </div>
        </>
    );
}
