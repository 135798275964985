export const countries = [
  {
    Name: "Afghanistan",
    iso_2: "af",
  },
  {
    Name: "Aland Islands",
    iso_2: "ax",
  },
  {
    Name: "Albania",
    iso_2: "al",
  },
  {
    Name: "Algeria",
    iso_2: "dz",
  },
  {
    Name: "American Samoa",
    iso_2: "as",
  },
  {
    Name: "Andorra",
    iso_2: "ad",
  },
  {
    Name: "Angola",
    iso_2: "ao",
  },
  {
    Name: "Anguilla",
    iso_2: "ai",
  },
  {
    Name: "Antarctica",
    iso_2: "aq",
  },
  {
    Name: "Antigua and Barbuda",
    iso_2: "ag",
  },
  {
    Name: "Argentina",
    iso_2: "ar",
  },
  {
    Name: "Armenia",
    iso_2: "am",
  },
  {
    Name: "Aruba",
    iso_2: "aw",
  },
  {
    Name: "Australia",
    iso_2: "au",
  },
  {
    Name: "Austria",
    iso_2: "at",
  },
  {
    Name: "Azerbaijan",
    iso_2: "az",
  },
  {
    Name: "Bahamas",
    iso_2: "bs",
  },
  {
    Name: "Bahrain",
    iso_2: "bh",
  },
  {
    Name: "Bangladesh",
    iso_2: "bd",
  },
  {
    Name: "Barbados",
    iso_2: "bb",
  },
  {
    Name: "Belarus",
    iso_2: "by",
  },
  {
    Name: "Belgium",
    iso_2: "be",
  },
  {
    Name: "Belize",
    iso_2: "bz",
  },
  {
    Name: "Benin",
    iso_2: "bj",
  },
  {
    Name: "Bermuda",
    iso_2: "bm",
  },
  {
    Name: "Bhutan",
    iso_2: "bt",
  },
  {
    Name: "Bolivia",
    iso_2: "bo",
  },
  {
    Name: "Bonaire",
    iso_2: "bq",
  },
  {
    Name: "Bosnia",
    iso_2: "ba",
  },
  {
    Name: "Botswana",
    iso_2: "bw",
  },
  {
    Name: "Bouvet Island",
    iso_2: "bv",
  },
  {
    Name: "Brazil",
    iso_2: "br",
  },
  {
    Name: "British Indian Ocean Territory",
    iso_2: "io",
  },
  {
    Name: "Brunei Darussalam",
    iso_2: "bn",
  },
  {
    Name: "Bulgaria",
    iso_2: "bg",
  },
  {
    Name: "Burkina Faso",
    iso_2: "bf",
  },
  {
    Name: "Burundi",
    iso_2: "bi",
  },
  {
    Name: "Cambodia",
    iso_2: "kh",
  },
  {
    Name: "Cameroon",
    iso_2: "cm",
  },
  {
    Name: "Canada",
    iso_2: "ca",
  },
  {
    Name: "Cape Verde",
    iso_2: "cv",
  },
  {
    Name: "Cayman Islands",
    iso_2: "ky",
  },
  {
    Name: "Central African Republic",
    iso_2: "cf",
  },
  {
    Name: "Chad",
    iso_2: "td",
  },
  {
    Name: "Chile",
    iso_2: "cl",
  },
  {
    Name: "China",
    iso_2: "cn",
  },
  {
    Name: "Christmas Island",
    iso_2: "cx",
  },
  {
    Name: "Cocos",
    iso_2: "cc",
  },
  {
    Name: "Colombia",
    iso_2: "co",
  },
  {
    Name: "Comoros",
    iso_2: "km",
  },
  {
    Name: "Congo",
    iso_2: "cg",
  },
  {
    Name: "Congo",
    iso_2: "cd",
  },
  {
    Name: "Cook Islands",
    iso_2: "ck",
  },
  {
    Name: "Costa Rica",
    iso_2: "cr",
  },
  {
    Name: "Cote d",
    undefined: "ci",
  },
  {
    Name: "Croatia",
    iso_2: "hr",
  },
  {
    Name: "Cuba",
    iso_2: "cu",
  },
  {
    Name: "Curacao",
    iso_2: "cw",
  },
  {
    Name: "Cyprus",
    iso_2: "cy",
  },
  {
    Name: "Czech",
    iso_2: "cz",
  },
  {
    Name: "Denmark",
    iso_2: "dk",
  },
  {
    Name: "Djibouti",
    iso_2: "dj",
  },
  {
    Name: "Dominica",
    iso_2: "dm",
  },
  {
    Name: "Dominican",
    iso_2: "do",
  },
  {
    Name: "Ecuador",
    iso_2: "ec",
  },
  {
    Name: "Egypt",
    iso_2: "eg",
  },
  {
    Name: "El Salvador",
    iso_2: "sv",
  },
  {
    Name: "Equatorial Guinea",
    iso_2: "gq",
  },
  {
    Name: "Eritrea",
    iso_2: "er",
  },
  {
    Name: "Estonia",
    iso_2: "ee",
  },
  {
    Name: "Ethiopia",
    iso_2: "et",
  },
  {
    Name: "Faroe Islands",
    iso_2: "fo",
  },
  {
    Name: "Fiji",
    iso_2: "fj",
  },
  {
    Name: "Finland",
    iso_2: "fi",
  },
  {
    Name: "France",
    iso_2: "fr",
  },
  {
    Name: "French Guiana",
    iso_2: "gf",
  },
  {
    Name: "French Polynesia",
    iso_2: "pf",
  },
  {
    Name: "French Southern Territories",
    iso_2: "tf",
  },
  {
    Name: "Gabon",
    iso_2: "ga",
  },
  {
    Name: "Gambia",
    iso_2: "gm",
  },
  {
    Name: "Georgia",
    iso_2: "ge",
  },
  {
    Name: "Germany",
    iso_2: "de",
  },
  {
    Name: "Ghana",
    iso_2: "gh",
  },
  {
    Name: "Gibraltar",
    iso_2: "gi",
  },
  {
    Name: "Greece",
    iso_2: "gr",
  },
  {
    Name: "Greenland",
    iso_2: "gl",
  },
  {
    Name: "Grenada",
    iso_2: "gd",
  },
  {
    Name: "Guadeloupe",
    iso_2: "gp",
  },
  {
    Name: "Guam",
    iso_2: "gu",
  },
  {
    Name: "Guatemala",
    iso_2: "gt",
  },
  {
    Name: "Guernsey",
    iso_2: "gg",
  },
  {
    Name: "Guinea",
    iso_2: "gn",
  },
  {
    Name: "Guinea-Bissau",
    iso_2: "gw",
  },
  {
    Name: "Guyana",
    iso_2: "gy",
  },
  {
    Name: "Haiti",
    iso_2: "ht",
  },
  {
    Name: "Vatican City",
    iso_2: "va",
  },
  {
    Name: "Honduras",
    iso_2: "hn",
  },
  {
    Name: "Hong Kong",
    iso_2: "hk",
  },
  {
    Name: "Hungary",
    iso_2: "hu",
  },
  {
    Name: "Iceland",
    iso_2: "is",
  },
  {
    Name: "India",
    iso_2: "in",
  },
  {
    Name: "Indonesia",
    iso_2: "id",
  },
  {
    Name: "Iran",
    iso_2: "ir",
  },
  {
    Name: "Iraq",
    iso_2: "iq",
  },
  {
    Name: "Ireland",
    iso_2: "ie",
  },
  {
    Name: "Isle of Man",
    iso_2: "im",
  },
  {
    Name: "Israel",
    iso_2: "il",
  },
  {
    Name: "Italy",
    iso_2: "it",
  },
  {
    Name: "Jamaica",
    iso_2: "jm",
  },
  {
    Name: "Japan",
    iso_2: "jp",
  },
  {
    Name: "Jersey",
    iso_2: "je",
  },
  {
    Name: "Jordan",
    iso_2: "jo",
  },
  {
    Name: "Kazakhstan",
    iso_2: "kz",
  },
  {
    Name: "Kenya",
    iso_2: "ke",
  },
  {
    Name: "Kiribati",
    iso_2: "ki",
  },
  {
    Name: "North Korea",
    iso_2: "kp",
  },
  {
    Name: "South Korea",
    iso_2: "kr",
  },
  {
    Name: "Kuwait",
    iso_2: "kw",
  },
  {
    Name: "Kyrgyzstan",
    iso_2: "kg",
  },
  {
    Name: "Laos",
    iso_2: "la",
  },
  {
    Name: "Latvia",
    iso_2: "lv",
  },
  {
    Name: "Lebanon",
    iso_2: "lb",
  },
  {
    Name: "Lesotho",
    iso_2: "ls",
  },
  {
    Name: "Liberia",
    iso_2: "lr",
  },
  {
    Name: "Libya",
    iso_2: "ly",
  },
  {
    Name: "Liechtenstein",
    iso_2: "li",
  },
  {
    Name: "Lithuania",
    iso_2: "lt",
  },
  {
    Name: "Luxembourg",
    iso_2: "lu",
  },
  {
    Name: "Macao",
    iso_2: "mo",
  },
  {
    Name: "Macedonia",
    iso_2: "mk",
  },
  {
    Name: "Madagascar",
    iso_2: "mg",
  },
  {
    Name: "Malawi",
    iso_2: "mw",
  },
  {
    Name: "Malaysia",
    iso_2: "my",
  },
  {
    Name: "Maldives",
    iso_2: "mv",
  },
  {
    Name: "Mali",
    iso_2: "ml",
  },
  {
    Name: "Malta",
    iso_2: "mt",
  },
  {
    Name: "Marshall Islands",
    iso_2: "mh",
  },
  {
    Name: "Martinique",
    iso_2: "mq",
  },
  {
    Name: "Mauritania",
    iso_2: "mr",
  },
  {
    Name: "Mauritius",
    iso_2: "mu",
  },
  {
    Name: "Mayotte",
    iso_2: "yt",
  },
  {
    Name: "Mexico",
    iso_2: "mx",
  },
  {
    Name: "Micronesia",
    iso_2: "fm",
  },
  {
    Name: "Moldova",
    iso_2: "md",
  },
  {
    Name: "Monaco",
    iso_2: "mc",
  },
  {
    Name: "Mongolia",
    iso_2: "mn",
  },
  {
    Name: "Montenegro",
    iso_2: "me",
  },
  {
    Name: "Montserrat",
    iso_2: "ms",
  },
  {
    Name: "Morocco",
    iso_2: "ma",
  },
  {
    Name: "Mozambique",
    iso_2: "mz",
  },
  {
    Name: "Myanmar",
    iso_2: "mm",
  },
  {
    Name: "Namibia",
    iso_2: "na",
  },
  {
    Name: "Nauru",
    iso_2: "nr",
  },
  {
    Name: "Nepal",
    iso_2: "np",
  },
  {
    Name: "Netherlands",
    iso_2: "nl",
  },
  {
    Name: "New Caledonia",
    iso_2: "nc",
  },
  {
    Name: "New Zealand",
    iso_2: "nz",
  },
  {
    Name: "Nicaragua",
    iso_2: "ni",
  },
  {
    Name: "Niger",
    iso_2: "ne",
  },
  {
    Name: "Nigeria",
    iso_2: "ng",
  },
  {
    Name: "Niue",
    iso_2: "nu",
  },
  {
    Name: "Norfolk Island",
    iso_2: "nf",
  },
  {
    Name: "Northern Mariana",
    iso_2: "mp",
  },
  {
    Name: "Norway",
    iso_2: "no",
  },
  {
    Name: "Oman",
    iso_2: "om",
  },
  {
    Name: "Pakistan",
    iso_2: "pk",
  },
  {
    Name: "Palau",
    iso_2: "pw",
  },
  {
    Name: "Palestine",
    iso_2: "ps",
  },
  {
    Name: "Panama",
    iso_2: "pa",
  },
  {
    Name: "Papua New Guinea",
    iso_2: "pg",
  },
  {
    Name: "Paraguay",
    iso_2: "py",
  },
  {
    Name: "Peru",
    iso_2: "pe",
  },
  {
    Name: "Philippines",
    iso_2: "ph",
  },
  {
    Name: "Pitcairn",
    iso_2: "pn",
  },
  {
    Name: "Poland",
    iso_2: "pl",
  },
  {
    Name: "Portugal",
    iso_2: "pt",
  },
  {
    Name: "Puerto Rico",
    iso_2: "pr",
  },
  {
    Name: "Qatar",
    iso_2: "qa",
  },
  {
    Name: "Reunion",
    iso_2: "re",
  },
  {
    Name: "Romania",
    iso_2: "ro",
  },
  {
    Name: "Russian Federation",
    iso_2: "ru",
  },
  {
    Name: "Rwanda",
    iso_2: "rw",
  },
  {
    Name: "Saint Lucia",
    iso_2: "lc",
  },
  {
    Name: "Saint Martin",
    iso_2: "mf",
  },
  {
    Name: "Samoa",
    iso_2: "ws",
  },
  {
    Name: "San Marino",
    iso_2: "sm",
  },
  {
    Name: "Saudi Arabia",
    iso_2: "sa",
  },
  {
    Name: "Senegal",
    iso_2: "sn",
  },
  {
    Name: "Serbia",
    iso_2: "rs",
  },
  {
    Name: "Seychelles",
    iso_2: "sc",
  },
  {
    Name: "Sierra Leone",
    iso_2: "sl",
  },
  {
    Name: "Singapore",
    iso_2: "sg",
  },
  {
    Name: "Sint Maarten",
    iso_2: "sx",
  },
  {
    Name: "Slovakia",
    iso_2: "sk",
  },
  {
    Name: "Slovenia",
    iso_2: "si",
  },
  {
    Name: "Solomon Islands",
    iso_2: "sb",
  },
  {
    Name: "Somalia",
    iso_2: "so",
  },
  {
    Name: "South Africa",
    iso_2: "za",
  },
  {
    Name: "South Georgia",
    iso_2: "gs",
  },
  {
    Name: "South Sudan",
    iso_2: "ss",
  },
  {
    Name: "Spain",
    iso_2: "es",
  },
  {
    Name: "Sri Lanka",
    iso_2: "lk",
  },
  {
    Name: "Sudan",
    iso_2: "sd",
  },
  {
    Name: "Suriname",
    iso_2: "sr",
  },
  {
    Name: "Swaziland",
    iso_2: "sz",
  },
  {
    Name: "Sweden",
    iso_2: "se",
  },
  {
    Name: "Switzerland",
    iso_2: "ch",
  },
  {
    Name: "Syria",
    iso_2: "sy",
  },
  {
    Name: "Taiwan",
    iso_2: "tw",
  },
  {
    Name: "Tajikistan",
    iso_2: "tj",
  },
  {
    Name: "Tanzania",
    iso_2: "tz",
  },
  {
    Name: "Thailand",
    iso_2: "th",
  },
  {
    Name: "Timor-Leste",
    iso_2: "tl",
  },
  {
    Name: "Togo",
    iso_2: "tg",
  },
  {
    Name: "Tokelau",
    iso_2: "tk",
  },
  {
    Name: "Tonga",
    iso_2: "to",
  },
  {
    Name: "Trinidad and Tobago",
    iso_2: "tt",
  },
  {
    Name: "Tunisia",
    iso_2: "tn",
  },
  {
    Name: "Turkey",
    iso_2: "tr",
  },
  {
    Name: "Turkmenistan",
    iso_2: "tm",
  },
  {
    Name: "Turks and Caicos Islands",
    iso_2: "tc",
  },
  {
    Name: "Tuvalu",
    iso_2: "tv",
  },
  {
    Name: "Uganda",
    iso_2: "ug",
  },
  {
    Name: "Ukraine",
    iso_2: "ua",
  },
  {
    Name: "United Arab Emirates",
    iso_2: "ae",
  },
  {
    Name: "United Kingdom",
    iso_2: "gb",
  },
  {
    Name: "United States",
    iso_2: "us",
  },
  {
    Name: "Uruguay",
    iso_2: "uy",
  },
  {
    Name: "Uzbekistan",
    iso_2: "uz",
  },
  {
    Name: "Vanuatu",
    iso_2: "vu",
  },
  {
    Name: "Venezuela",
    iso_2: "ve",
  },
  {
    Name: "Viet Nam",
    iso_2: "vn",
  },
  {
    Name: "Virgin Islands British",
    iso_2: "vg",
  },
  {
    Name: "Virgin Islands US",
    iso_2: "vi",
  },
  {
    Name: "Wallis and Futuna",
    iso_2: "wf",
  },
  {
    Name: "Western Sahara",
    iso_2: "eh",
  },
  {
    Name: "Yemen",
    iso_2: "ye",
  },
  {
    Name: "Zambia",
    iso_2: "zm",
  },
  {
    Name: "Zimbabwe",
    iso_2: "zw",
  },
];
