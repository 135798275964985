import axios from "axios";
import { responseErrorInterceptor, responseSuccessInterceptor } from "./interceptors/response-interceptor";
import { requestInterceptor } from "./interceptors/request-interceptor";


let axiosInstances = {};

export const getInstance = (pathname) => {
    if (axiosInstances[pathname] && !axiosInstances[pathname].controller.signal.aborted) {
        return axiosInstances[pathname].instance;
    }
    else {
        const customAxiosInstance = createInstance();
        axiosInstances[pathname] = customAxiosInstance;
        return axiosInstances[pathname].instance;
    }
};

const createInstance = () => {
    const controller = new AbortController();
    const { REACT_APP_BASE_API_URL: BASE_URL } = process.env;

    const axiosInstance = axios.create({
        baseURL: BASE_URL,
        signal: controller.signal
    });

    axiosInstance.interceptors.response.use(
        responseSuccessInterceptor,
        responseErrorInterceptor
    );
    axiosInstance.interceptors.request.use(requestInterceptor);

    axiosInstance.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const customAxiosInstance = {
        controller,
        instance: axiosInstance
    };
    return customAxiosInstance;
};

export const cancelPreviousPageRequests = (previousPath) => {
    axiosInstances[previousPath]?.controller.abort();
};
