import { useState } from "react";
import { SearchHeaderInput, SearchHeaderSelect, SearchHeaderContainer } from "styles/globalStyling.styled";
import { AutoComplete, Select, message } from "antd";

import { PRODUCTS, PRODUCT_DETAIL } from "constants/routes-constants";
import { cancelPreviousPageRequests } from "services/axios-instance";
import { apiPost, getImageUrlSrc } from "services/apis";
import { errorResponseMapper } from "services/errorResponseMapper";
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useQueryParams from "hooks/useQueryParams";
import { modifyFileKey } from "services/helpers";

export default function HeaderSearch() {
    const { Option } = Select;
    const history = useHistory();
    const categories = useSelector((state) => state?.app?.categories);
    const credentials = useSelector((state) => state?.credentials);

    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [results, setResults] = useState([]);
    const { queryParams, setQueryParams } = useQueryParams({ item: "", category: [] });

    const [selectedCategory, setSelectedCategory] = useState("");
    const [searchText, setSearchedText] = useState(queryParams?.item ?? "");

    const navigateToProduct = (value, isSearch = false, item_id) => {
        let urlLink = PRODUCT_DETAIL.replace(":id", encodeURIComponent(item_id));
        if (window.event.ctrlKey) {
            window.open(urlLink, "_blank", "noopener noreferrer");
        } else {
            if (isSearch) {
                const categoryLocal = selectedCategory ? [selectedCategory] : [];
                setQueryParams({ item: value, category: categoryLocal }, true);
            } else {
                history.push(urlLink);
            }
        }
    }

    const handleKeyDown = (event, isSearch = true) => {
        if (event.key === 'Enter') {
            navigateToProduct(event.target.value, isSearch);
        }
    };

    const clearFilter = () => {
        setSearchedText("")
        if (history.location.pathname === PRODUCTS) {
            setQueryParams({}, true)
        }
    }

    const onSearch = (value) => {
        setSearchedText(value);
        if (isSearchLoading) {
            setIsSearchLoading(false);
        }
        if (value?.length === 0) {
            setResults([]);
            clearFilter();
        } else if (value?.length > 2) {
            cancelPreviousPageRequests(window.location.pathname);
            if (!isSearchLoading) {
                setIsSearchLoading(true);
            }
            setResults([]);
            const url = credentials?.isLoggedIn ? `/item/get_store_items` : `/item/get_landing_items`;
            const categoryList = selectedCategory ? JSON.stringify([selectedCategory]) : "";
            apiPost(url, { item: value?.trim(), category: categoryList })
                .then((res) => {
                    if (res?.data?.status === "SUCCESS") {
                        setResults(res?.data.payload.items);
                    } else {
                        message.error(errorResponseMapper(res?.data));
                    }
                    setIsSearchLoading(false);
                })
                .catch((thrown) => {
                    if (!axios.isCancel(thrown)) {
                        setIsSearchLoading(false);
                    }
                });
        }
    };


    return (
        <SearchHeaderContainer className="d-flex w-100" >
            <SearchHeaderSelect
                defaultValue="all"
                onChange={(value) => {
                    let valueLocal = value;
                    if (value === "all") {
                        valueLocal = "";
                    }
                    setSelectedCategory(valueLocal)
                }}
            >
                <Option value="all">All categories</Option>
                {
                    categories.map((item) => {
                        return (
                            <Option key={item.key} value={item.slug}>{item.name}</Option>
                        )
                    })
                }
            </SearchHeaderSelect>
            <SearchHeaderInput

                placeholder="Search for products"
                allowClear={!isSearchLoading}
                onSearch={onSearch}
                loading={isSearchLoading}
                value={searchText}
                onSelect={(value) => {
                    let itemId = results?.filter((e) => e.title === value)[0].item_id
                    navigateToProduct(value, false, itemId);
                }}
                onKeyDown={handleKeyDown}
                onClear={() => { clearFilter(); }}
            >
                {results?.map((el, index) => {
                    return (
                        <AutoComplete.Option
                            key={`results-${index}`}
                            value={el.title}
                            onKeyDown={(value) => { handleKeyDown(value, false); }}
                        >
                            <div className="d-flex my-2">
                                <img
                                    style={{ width: "25px", maxHeight: "25px" }}
                                    alt={el.title}
                                    src={getImageUrlSrc(el?.[modifyFileKey("image")])}
                                />
                                <div className="ms-2">
                                    <div className="fw-bold">
                                        {el.title}
                                    </div>
                                </div>
                            </div>
                        </AutoComplete.Option>
                    );
                })}
            </SearchHeaderInput>
        </SearchHeaderContainer>
    )
}