import { HOME } from "constants/routes-constants";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "services/authentication";

function PublicRoute({ children, restricted, isPreventRoute = false, ...rest }) {
  let isAuthenticatedLocal = isAuthenticated();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if ((isAuthenticatedLocal && restricted) || isPreventRoute) {
          return (
            <Redirect
              to={{
                pathname: HOME,
                state: { from: location },
              }}
            />
          )
        } else {
          return (
            children
          )
        }
      }}

    />
  );
}

export default PublicRoute;
