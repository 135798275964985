import React from "react";

import { Col, Divider, Row } from "antd";

import { useHistory } from "react-router-dom";
import { CONTACT_US, LOG_IN, MY_ACCOUNT, MY_ORDERS_MENU, PRODUCTS, TERMS_AND_CONDITIONS } from "constants/routes-constants";
import { useMediaQuery } from "react-responsive";

import FooterEcommerceLogo from "assets/icons/footer/footer-ecommerce-logo.svg";
/* import LocationIcon from "assets/icons/location-icon.svg"; */
/* import PhoneIcon from "assets/icons/phone-icon.svg";
 */import EmailIcon from "assets/icons/email-icon.svg";
import { EMAIL /*, PHONE_NUMBER */ } from "constants/app-constants";
import { useSelector } from "react-redux";
/* import { phoneNumberFormatter } from "services/helpers"; */

const MiddleFooterSection = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const history = useHistory();
    const credentials = useSelector((state) => state?.credentials);
    const isRetail = credentials?.user?.type === "RETAIL";

    const DividerLocal = () => {
        return !isMobile && (
            <Col lg={1} className="text-center px-0">
                <Divider
                    type="vertical"
                    className="h-100"
                    style={{ borderColor: "#EDEDED", borderWidth: "2px" }}
                />
            </Col>
        )
    }

    return (
        <div>
            <Row className="justify-content-center py-5" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={7}>
                    <img
                        src={FooterEcommerceLogo}
                        alt="Emolo Logo"
                        className="mx-auto"
                    />
                    <div className="mt-3">
                        <div className="primary-color bold-20">
                            About The Store
                        </div>
                        <div className="black-text-color regular-14 mt-2">
                            Our mission statement is to provide the absolute best customer experience available in the Electronic industry without exception.
                        </div>
                        {/* <div className="d-flex mt-3">
                            <img
                                src={LocationIcon}
                                alt="location"
                            />
                            <div className="ms-1 primary-color bold-14 ">
                                11670 Tuxford St, Sun Valley, CA 91352, USA
                            </div>
                        </div> */}
                    </div>
                </Col>

                <DividerLocal />

                <Col xs={12} sm={12} md={12} lg={3} className={isMobile && "mt-4"}>
                    <div className="bold-20 primary-color mb-2">PRODUCTS</div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRODUCTS)
                            window.scroll(0, 0);
                        }}
                    >
                        Brands
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRODUCTS);
                            window.scroll(0, 0);
                        }}
                    >
                        Category
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRODUCTS);
                            window.scroll(0, 0);
                        }}
                    >
                        Prices Drop
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRODUCTS + "?order_by=date&direction=desc");
                            window.scroll(0, 0);
                        }}
                    >
                        New Products
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRODUCTS);
                            window.scroll(0, 0);
                        }}
                    >
                        Best Selling
                    </div>
                </Col>

                <DividerLocal />

                <Col xs={12} sm={12} md={12} lg={3} className={isMobile && "mt-4"}>
                    <div className="bold-20 primary-color mb-2">OUR COMPANY</div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(CONTACT_US);
                            window.scroll(0, 0);
                        }}
                    >
                        Contact Us
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(TERMS_AND_CONDITIONS);
                            window.scroll(0, 0);
                        }}
                    >
                        Terms &amp; Conditions
                    </div>
                    {/* <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            history.push(PRIVACY_NOTICE)
                        }}
                    >
                        Privacy Notice
                    </div> */}
                </Col>

                <DividerLocal />

                <Col xs={12} sm={12} md={12} lg={3} className={isMobile && "mt-4"}>
                    <div className="bold-20 primary-color mb-2">YOUR ACCOUNT</div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            credentials?.isLoggedIn
                                ? history.push(MY_ACCOUNT)
                                : history.push(LOG_IN)
                            window.scroll(0, 0);
                        }}
                    >
                        Your Profile
                    </div>
                    <div
                        className="mb-2 cursor-pointer regular-16"
                        style={{ width: "max-content" }}
                        onClick={() => {
                            credentials?.isLoggedIn
                                ? isRetail
                                    ? history.push(MY_ORDERS_MENU.replace(":menu", "invoices"))
                                    : history.push(MY_ORDERS_MENU.replace(":menu", "orders"))
                                : history.push(LOG_IN);
                            window.scroll(0, 0);
                        }}
                    >
                        Orders
                    </div>
                </Col>

                <DividerLocal />

                <Col xs={12} sm={12} md={12} lg={4} className={isMobile && "mt-4"}>
                    <div className="bold-20 primary-color mb-2">GOT QUESTIONS?</div>
                    <div className="cursor-pointer" onClick={() => (window.location = `mailto:${EMAIL}`)}>
                        <div className="d-flex">
                            <img src={EmailIcon} alt="PhoneIcon" />
                            <div className="ms-2 bold-16">
                                Email us
                            </div>
                        </div>
                        <div className="regular-16">
                            {EMAIL}
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    );
};

export default MiddleFooterSection;
