
import { KEY_DEVICE_ID } from "constants/app-constants";
import Fingerprint2 from "fingerprintjs2";


class FingerprintAPI {

    constructor() {
        this.deviceID = null;
        this.setBrowserFingerprint();
    }


    setBrowserFingerprint() {
        var that = this;
        var deviceID = localStorage.getItem(KEY_DEVICE_ID);

        if (!deviceID) {
            Fingerprint2.get({ excludes: { webgl: true, audio: true, fonts: true, fontsFlash: true, canvas: true } }, function (components) {

                var values = components.map(function (component) {
                    return component.value
                });

                var murmur = Fingerprint2.x64hash128(values.join(''), 31)

                that.deviceID = murmur;
                localStorage.setItem(KEY_DEVICE_ID, murmur);
            });
        } else {
            this.deviceID = deviceID
        }
    }

    getDeviceID() {
        return this.deviceID ? this.deviceID : null;
    }

}
export default FingerprintAPI;