import { Button, Form, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItemsCount } from "reduxToolkit/cartReducer.redux";
import { apiPost } from "services/apis";
import { useHistory } from "react-router-dom";
import { CartInput } from "styles/globalStyling.styled";
import MinusIcon from "assets/icons/products/quantity-minus-icon.svg";
import PlusIcon from "assets/icons/products/quantity-plus-icon.svg";
import CartWhiteIcon from "assets/icons/products/cart-white-icon.svg";
import { LOG_IN } from "constants/routes-constants";
import { useMediaQuery } from "react-responsive";

const AddToCart = ({ data, nextToAddCart, isFromDetails = false }) => {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const credentials = useSelector((state) => state.credentials);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const addToCart = (qty = 1) => {
        setIsLoading(true);
        apiPost("/cart/add_item", { item: data?.key, qty: qty })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    message.success("Item Added to Cart!");
                    dispatch(setItemsCount(res?.data?.payload?.cart_count));
                    form.resetFields();
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const buttonClasses = isFromDetails
        ? isMobile ? "mx-2 px-2" : "mx-3 px-4"
        : isMobile ? "ms-2 px-2" : "ms-2 px-4"

    const isDisabled = !data?.in_stock;

    return (
        <Form
            form={form}
            onFinish={(value) => {
                addToCart(value?.qty);
            }}
        >
            <label className="bold-16 mb-2">Quantity</label>

            <div className={`d-flex`}>
                <Form.Item
                    className="m-0"
                    name="qty"
                    rules={[
                        {
                            required: true,
                            message: "Qty required!",
                        },
                        {
                            type: "number",
                            min: 1,
                            message: "Quantity cannot be less than 1.",
                        },
                    ]}
                    initialValue={1}
                >
                    <CartInput
                        defaultValue={1}
                        placeholder="QTY"
                        type="number"
                        min={1}
                        addonBefore={
                            <img
                                style={{ opacity: isDisabled && "0.6" }}
                                className={isDisabled ? "" : "cursor-pointer"}
                                src={MinusIcon}
                                alt="-"
                                onClick={() => {
                                    if (!isDisabled) {
                                        let localQty = form.getFieldValue("qty") ?? 1;
                                        form.setFieldsValue({ qty: localQty - 1 });
                                    }
                                }}
                            />
                        }
                        addonAfter={
                            <img
                                style={{ opacity: isDisabled && "0.6" }}
                                className={isDisabled ? "" : "cursor-pointer"}
                                src={PlusIcon}
                                alt="+"
                                onClick={() => {
                                    if (!isDisabled) {
                                        let localQty = form.getFieldValue("qty") ?? 1;
                                        form.setFieldsValue({ qty: localQty + 1 });
                                    }
                                }}
                            />
                        }
                        controls={false}
                        disabled={!data?.in_stock}
                    />
                </Form.Item>
                <Button
                    disabled={isLoading || !data?.in_stock}
                    isLoading={isLoading}
                    className={`${buttonClasses} py-0 fw-bold ${!data?.in_stock && isFromDetails ? "disabled-add-to-cart" : ""}`}
                    style={{ borderRadius: "5px", height: "42px" }}
                    type="primary"
                    onClick={() => {
                        if (credentials?.isLoggedIn) {
                            form.submit()
                        } else {
                            history.push(LOG_IN);
                        }
                    }}
                >
                    <div className="d-flex justify-content-center">
                        {(data?.in_stock || !isFromDetails) && <img className={isFromDetails ? "me-2" : ""} src={CartWhiteIcon} alt="" />}
                        {isFromDetails ? data?.in_stock ? "ADD TO CART" : "OUT OF STOCK" : ""}
                    </div>
                </Button>

                {nextToAddCart && nextToAddCart}
            </div>
        </Form>
    );
}

export default AddToCart;