import React from 'react'
import { Layout } from 'antd'
import { useHistory } from "react-router-dom";
import { HOME } from "constants/routes-constants";

const AppContent = ({ children }) => {
  const history = useHistory();
  let withPadding = history.location.pathname !== HOME;
  return (
    <Layout className={withPadding && 'padding-main'}>
      {children}
    </Layout>
  )
}

export default AppContent;
