import { Button, Modal } from 'antd';
import React from 'react';

export default function DeleteReviewModal({ deleteReview, isDeleteReviewLoading, isModalOpen, setIsModalOpen }) {
    return (
        <div>
            <Modal
                centered
                destroyOnClose
                title={<span className='bold-20'>Deleting Review</span>}
                open={isModalOpen}
                onCancel={() => { setIsModalOpen(false); }}
                footer={[
                    <Button key={"cancel"} onClick={() => { setIsModalOpen(false); }}>
                        Cancel
                    </Button>,
                    <Button
                        type="primary"
                        danger
                        key="proceed"
                        loading={isDeleteReviewLoading}
                        onClick={() => { deleteReview(); }}
                    >
                        Delete
                    </Button>
                ]}
            >
                <div className='w-100 text-center regular-14'>Are you sure you want to delete your review?</div>
            </Modal>
        </div>
    );
}
