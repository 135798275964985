import React, { useEffect, useState } from "react";
import ProductItem from "components/common/product-item";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import { TabsCustom } from "styles/globalStyling.styled";
import { useSelector } from "react-redux";
import { apiPost } from "services/apis";
import LoadingSpinner from "../loading-spinner";
import { LIMIT } from "constants/app-constants";

export default function RelatedProducts({ itemId }) {

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const credentials = useSelector((state) => state.credentials);

    const [isRelatedItemsLoading, setIsRelatedItemsLoading] = useState(false);
    const [relatedItems, setRelatedItems] = useState([]);

    useEffect(() => {
        getRelatedItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId])

    const getRelatedItems = () => {
        setIsRelatedItemsLoading(true);
        const url = credentials?.isLoggedIn
            ? `/item/get_store_items`
            : `/item/get_item_similar_items`;
        apiPost(url, { page_size: LIMIT, page: 1, item: itemId })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    let itemsLocal = res?.data?.payload?.items ?? [];
                    setRelatedItems([...itemsLocal]);
                }
            })
            .finally(() => {
                setIsRelatedItemsLoading(false);
            });
    };

    const settings = {
        dots: false,
        slidesToShow: isMobile ? 1.3 : 5,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true,
        draggable: true,
        speed: 300,
        nextArrow: null,
        prevArrow: null
    };

    if (isRelatedItemsLoading) {
        <LoadingSpinner />
    } else {
        if (relatedItems && relatedItems.length > 0) {
            return (
                <section id="related-products-section">
                    <TabsCustom >
                        <TabsCustom.TabPane
                            tab="YOU MAY ALSO LIKE"
                            key="related-products"
                        >
                            <Slider {...settings} className="without-slick-arrows" >
                                {
                                    relatedItems.map((item, index) => {
                                        return (
                                            <div key={`item-${index}`} className="px-2">
                                                <ProductItem
                                                    item={item}
                                                    allItems={relatedItems}
                                                    setItems={setRelatedItems}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </TabsCustom.TabPane>
                    </TabsCustom>
                </section>
            );
        } else {
            return null;
        }
    }
}
