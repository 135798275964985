import React from "react";
import { Button, Col, Input, Row, Space } from "antd";
import { useMediaQuery } from "react-responsive";

const NewsLetterSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div>
      <Row className="w-100 bg-light-blue py-4 justify-content-between padding-main" gutter={[16, 16]}>
        <Col className={isMobile && "px-0"}>
          <div className="primary-color bold-20">
            Join our newsletter.
          </div>
          <div className="regular-16 " style={{ color: "#9C9C9C" }}>
            We&apos;ll keep you updated with the latest deals and products.
          </div>
        </Col>
        <Col lg={8} sm={24} xs={24} className={`${isMobile && "px-0"} text-end`}>
          <Space.Compact className="w-100" style={{ border: "2px solid #034DCA", borderRadius: "30px" }}>
            <Input disabled className="my-2 mx-3" placeholder="Enter your email" style={{ border: "unset", background: "transparent" }} />
            <Button disabled type="primary" className="px-4 white-text-color" style={{ borderRadius: "30px" }} >Subscribe</Button>
          </Space.Compact>
        </Col>
      </Row>

    </div>
  );
};

export default NewsLetterSection;
