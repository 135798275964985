import { Form, message } from 'antd';
import Breadcrumb from 'components/common/breadcrumb';
import { HOME, LOG_IN } from 'constants/routes-constants';
import React, { useState } from 'react';
import { ParentDiv } from './signup.styled';
import { TabsCustom } from 'styles/globalStyling.styled';
import ContactInformation from './tabs/contact-information';
import BillingInformation from './tabs/billing-information';
import { apiPost } from 'services/apis';
import { errorResponseMapper } from 'services/errorResponseMapper';
import { USER_TYPE_CUSTOMER } from 'constants/app-constants';
import Verification from './tabs/verification';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

export default function SignUpComponent() {

    const history = useHistory();
    const [form] = Form.useForm();
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const [hiddenInput, setHiddenInput] = useState("");
    const [password, setPassword] = useState("");
    const [confirm, setConfirm] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [activeKey, setActiveKey] = useState("contact-info");
    const [stateDisable, setStateDisable] = useState(false);
    const [stateShippingDisable, setStateShippingDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [verifReqId, setVerifReqId] = useState();
    const [counter, setCounter] = useState(true);
    const [callBackCounter, setCallBackCounter] = useState(false);

    const validatePersonalInfo = () => {
        form.validateFields(["contact_name", "email", "msisdn", "password", "confirm_password"]).then(() => {
            if (validatePassword(password, confirm)) {
                if (hiddenInput === "") {
                    setActiveKey("billing-info");
                }
            }
        }).catch(() => {
            message.error("Some required fields are still not filled!");
        });
    };

    const validateBillingInfo = () => {
        form.validateFields()
            .then(() => { identify(); })
            .catch(() => { message.error("Some required fields are still not filled!"); });
    };

    const validatePassword = (password, confirm) => {
        if (isValid) {
            if (password === confirm) {
                return true;
            } else {
                message.error("Password not matching!");
                return false;
            }
        } else {
            message.error("Please use strong password (small letter, capital letter, number & special character)");
            return false;
        }
    };

    const identify = (resend) => {
        setIsLoading(true);
        resend && form.resetFields(["code"]);
        setCounter(!counter);
        setCallBackCounter(true);
        apiPost("/identify", {
            company_name: form.getFieldValue(["company_name"]),
            user_id: form.getFieldValue(["email"]),
            password: password,
            confirm: confirm,
            verification_method: "EMAIL",
            verification_type: "REGISTRATION",
            user_type: USER_TYPE_CUSTOMER
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    setVerifReqId(res?.data.payload.verif_req_id);
                    !resend && setActiveKey("verification");
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => { setIsLoading(false); });
    };

    const verify = (values) => {
        setIsLoading(true);
        apiPost("/customer/register", {
            ...values,
            primary_address_shipping: form.getFieldValue(["primary_address_shipping"]),
            secondary_address_shipping: form.getFieldValue(["secondary_address_shipping"]),
            country_shipping: form.getFieldValue(["country_shipping"]),
            city_shipping: form.getFieldValue(["city_shipping"]),
            state_shipping: form.getFieldValue(["state_shipping"]),
            zip_code_shipping: form.getFieldValue(["zip_code_shipping"]),
            contact_name_shipping: form.getFieldValue(["contact_name_shipping"]),
            company_name_shipping: form.getFieldValue(["company_name_shipping"]),
            msisdn_shipping: form.getFieldValue(["msisdn_shipping"]),
            msisdn_work_shipping: form.getFieldValue(["msisdn_work_shipping"]),
            type: "retail",
            verif_req_id: verifReqId,
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    message.success("Registration completed successfully !");
                    history.push(LOG_IN);
                } else {
                    message.error(errorResponseMapper(res.data));
                    form.resetFields(["code"]);
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <div className="mt-3">
                <Breadcrumb data={[{ path: HOME, title: "Home" }]} title="Register" />
            </div>
            <Form form={form} onFinish={verify} preserve>
                <div className='w-100 h-100 d-flex justify-content-center align-items-center flex-column gap-5'>
                    <div className='text-center mt-5'>
                        <div className='bold-30'>Hello, {activeKey === "contact-info" ? "Retailer" : form.getFieldValue(["contact_name"])}</div>
                        <div className='regular-16 gray-color'>Please fill in the details below!</div>
                    </div>
                    <div>
                        <TabsCustom
                            fontSize={"18px"}
                            fontWeight={700}
                            inactiveColor={"#9A9999"}
                            barWidth={"100%"}
                            activeKey={activeKey}
                            onChange={(e) => {
                                if (activeKey === "contact-info" && e === "billing-info") {
                                    validatePersonalInfo();
                                } else if (activeKey === "contact-info" && e === "verification") {
                                    setActiveKey("contact-info");
                                } else if (activeKey === "billing-info" && e === "verification") {
                                    validateBillingInfo();
                                } else {
                                    setActiveKey(e);
                                }
                            }}
                        >
                            <TabsCustom.TabPane tab={isMobile ? "Contact Info" : "1. Contact Information"} key="contact-info" className="pt-0">
                                <ParentDiv isMobile={isMobile}>
                                    <ContactInformation
                                        setIsValid={setIsValid}
                                        confirm={confirm}
                                        setConfirm={setConfirm}
                                        password={password}
                                        setPassword={setPassword}
                                        hiddenInput={hiddenInput}
                                        setHiddenInput={setHiddenInput}
                                        validatePersonalInfo={validatePersonalInfo}
                                    />
                                </ParentDiv>
                            </TabsCustom.TabPane>
                            <TabsCustom.TabPane tab={isMobile ? "Billing Info" : "2. Billing Information"} key="billing-info" className="pt-0">
                                <ParentDiv isMobile={isMobile}>
                                    <BillingInformation
                                        form={form}
                                        identify={identify}
                                        setActiveKey={setActiveKey}
                                        isLoading={isLoading}
                                        stateDisable={stateDisable}
                                        setStateDisable={setStateDisable}
                                        stateShippingDisable={stateShippingDisable}
                                        setStateShippingDisable={setStateShippingDisable}
                                    />
                                </ParentDiv>
                            </TabsCustom.TabPane>
                            <TabsCustom.TabPane tab={isMobile ? "Verification" : "3. Verification"} key="verification" className="pt-0">
                                <ParentDiv isMobile={isMobile}>
                                    <Verification
                                        form={form}
                                        identify={identify}
                                        isLoading={isLoading}
                                        counter={counter}
                                        setCounter={setCounter}
                                        activeKey={activeKey}
                                        setActiveKey={setActiveKey}
                                        callBackCounter={callBackCounter}
                                        setCallBackCounter={setCallBackCounter}
                                    />
                                </ParentDiv>
                            </TabsCustom.TabPane>
                        </TabsCustom>
                    </div>
                </div>
            </Form>
        </>
    );
}
