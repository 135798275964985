import { StyledDrawer } from "styles/globalStyling.styled";
import MobileMenuCloseIcon from "assets/icons/header/mobile-menu-close-icon.svg";
import ProductFilters from "../categories-menu/product-filters";

export default function FilterMobileMenu({ setIsMenuOpen, isMenuOpen }) {
    return (
        <StyledDrawer
            className="product-mobile-menu"
            closable
            placement="right"
            onClose={() => { setIsMenuOpen(false) }}
            open={isMenuOpen}
            closeIcon={<img className="ms-auto" src={MobileMenuCloseIcon} style={{ height: "22px" }} alt="" />}
            title="Filter"
            bodyStyle={{ paddingTop: "25px" }}
        >
            <ProductFilters />
        </StyledDrawer>
    )
}