import { Button, Form, Input, Modal, Rate, message } from 'antd';
import React, { useState } from 'react';
import { apiPost } from 'services/apis';
import { errorResponseMapper } from 'services/errorResponseMapper';

export default function AddReviewModal({ getReviews, itemKey, isModalOpen, setIsModalOpen }) {

    const [form] = Form.useForm();

    const [isAddReviewLoading, setIsAddReviewLoading] = useState(false);

    const addReview = (values) => {
        setIsAddReviewLoading(true);
        apiPost("/store_order/add_review", {
            item_id: itemKey,
            rating: values?.rating,
            comment: values?.comment
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    handleClose();
                    getReviews(itemKey);
                    message.success("Review Added Successfully!");
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => setIsAddReviewLoading(false));
    };

    const handleClose = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    return (
        <div>
            <Modal
                centered
                destroyOnClose
                title={<span className='bold-20'>Add Your Review</span>}
                open={isModalOpen}
                onCancel={() => { handleClose(); }}
                footer={[
                    <div key="buy" className='w-100 d-flex justify-content-between align-items-center'>
                        <Button onClick={() => { handleClose(); }}>
                            Cancel
                        </Button>
                        <Button
                            loading={isAddReviewLoading}
                            type="primary"
                            key="proceed"
                            onClick={() => { form.submit(); }}
                        >
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <Form form={form} onFinish={(values) => { addReview(values); }}>
                    <Form.Item className='p-0 m-0' name="rating" rules={[{ required: true, message: "This field is required!" }]} >
                        <Rate style={{ height: "38px" }} />
                    </Form.Item>
                    <Form.Item className='p-0 m-0' name="comment">
                        <Input.TextArea
                            placeholder='Write your review'
                            className='mt-3'
                            rows={4}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    );
}
