import { Col, Form, Row, Button, Divider, message } from "antd";
import React, { useState } from "react";
import EmoloLogo from "assets/icons/deal-buyer-logo.svg";
import styles from "../../pages/login/login.module.css";
import { Link, useHistory } from "react-router-dom";
import { HOME, LOG_IN } from "constants/routes-constants";
import { apiPost } from "services/apis";
import { errorResponseMapper } from "services/errorResponseMapper";
import BGBottom from "assets/icons/login/login-bg-bottom.svg";
import BGLeft from "assets/icons/login/login-bg-left.svg";
import BGTop from "assets/icons/login/login-bg-top.svg";
import { ResendButton } from "styles/globalStyling.styled";
import { useMediaQuery } from "react-responsive";
import VerificationCode from "components/sign-up-old/verification-code";
import VendorSignupInfo from "./vendor-signup-info";
import VendorSignupInfo2 from "./vendor-signup-info-2";
import ErrorMessage from "components/common/error-message";
import { USER_TYPE_VENDOR } from "constants/app-constants";

import PasswordCounter from "components/password-counter/password-counter";

export default function SignUpVendorsComponent() {
    const [currentStep, setCurentStep] = useState(0);
    const [hiddenInput, setHiddenInput] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [contactName, setContactName] = useState("");
    const [confirm, setConfirm] = useState("");
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [verif_req_id, setVerif_req_id] = useState("");
    const [signupLoading, setSignupLoading] = useState(false);
    const [isValid, setisValid] = useState(false);
    // const [resendDisabled, setResendDisabled] = useState(false);
    const [stateDisable, setStateDisable] = useState(false);
    const [vendorSignupForm] = Form.useForm();
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const [imageName, setImageName] = useState("");
    const [imageName_New, setImageName_New] = useState("");

    const [counter, setCounter] = useState(true);
    const [callBackCounter, setCallBackCounter] = useState(false);
    const validateNext = () => {
        if (currentStep === 0)
            vendorSignupForm
                .validateFields([
                    "contact_name",
                    "email",
                    "msisdn",
                    "password",
                    "confirm_password"
                ])
                .then(() => validatePersonalInfo(email, password, confirm));
        else if (currentStep === 1)
            vendorSignupForm
                .validateFields([
                    "city",
                    "country",
                    "zip_code",
                    "state",
                    "primary_address",
                ])
                .then(() => {
                    identify();
                });
        else {
            vendorSignupForm.submit();
        }
    };

    const validatePersonalInfo = (email, password, confirm) => {
        if (validateEmail(email) && validatePassword(password, confirm)) {
            if (hiddenInput === "") {
                setCurentStep((step) => step + 1);
            } else {
                history.push(HOME);
            }
        }
    };
    const validateEmail = (email) => {
        let re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var valid_email = re.test(email);
        if (valid_email) {
            return true;
        } else {
            setErrorMessage("Invalid email format");
            return false;
        }
    };
    const validatePassword = (password, confirm) => {
        if (isValid) {
            if (password === confirm) {
                setErrorMessage("");
                return true;
            } else {
                setErrorMessage("Password not matching!");
                return false;
            }
        } else {
            setErrorMessage(
                "Please use strong password (small letter, capital letter, number & special character)"
            );
            return false;
        }
    };


    const identify = (resend) => {
        /* if (imageName === null || imageName?.length === 0 || imageName === undefined) {
            setErrorMessage("Seller's Permit is required");
            return
        } */
        setIsLoading(true);
        resend && vendorSignupForm.resetFields(["code"]);
        setErrorMessage("");
        setCounter(!counter);
        setCallBackCounter(true);
        apiPost("/identify", {
            contact_name: contactName,
            user_id: email,
            password: password,
            confirm_password: confirm,
            verification_method: "EMAIL",
            verification_type: "REGISTRATION",
            user_type: USER_TYPE_VENDOR
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    setVerif_req_id(res?.data.payload.verif_req_id);
                    setCurentStep((step) => step + 1);
                    // if (resend) {
                    //     setResendDisabled(true);
                    //     setTimeout(() => setResendDisabled(false), 300000);
                    //     setCurentStep((step) => step);
                    // } else {
                    //     setCurentStep((step) => step + 1);
                    // }
                } else {
                    setErrorMessage(errorResponseMapper(res.data));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const verify = (value) => {
        setSignupLoading(true);
        setErrorMessage("");
        apiPost("/vendor/register", {
            ...value,
            verif_req_id: verif_req_id,
            seller_permit: imageName !== "" && imageName
                ? JSON.stringify(imageName?.map(img => { return img?.replaceAll(" ", "_"); }))
                : "",
            seller_permit_new: imageName_New !== "" && imageName_New
                ? JSON.stringify(imageName_New)
                : ""
        })
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    message.success("Registration completed successfully !");
                    history.push(LOG_IN);
                } else {
                    message.error(errorResponseMapper(res.data));
                    vendorSignupForm.resetFields(["code"]);
                }
            })
            .finally(() => setSignupLoading(false));
    };

    return (
        <div className="position-relative pb-5 pt-5">
            {!isMobile && (
                <>
                    <div
                        className="position-absolute"
                        style={{ zIndex: "0", bottom: "0px", right: "0px" }}
                    >
                        <img src={BGBottom} alt="1" />
                    </div>
                    <div
                        className="position-absolute"
                        style={{ zIndex: "0", top: "0px", right: "340px" }}
                    >
                        <img src={BGTop} alt="2" />
                    </div>
                    <div
                        className="position-absolute"
                        style={{ zIndex: "0", top: "90px", left: "0px" }}
                    >
                        <img src={BGLeft} alt="2" />
                    </div>
                </>
            )}

            <Row
                className={
                    isMobile
                        ? "d-flex justify-content-start"
                        : "d-flex justify-content-center"
                }
            >
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                    <Link style={{ width: "fit-content" }} to={HOME}>
                        <div className="d-flex w-100 justify-content-center">
                            <img src={EmoloLogo} alt="emolo logo" />
                            <div className={`ms-2 mt-2 ${styles?.emolo_title}`}>
                                <div className={styles?.emolo_title_1}>Emolo</div>
                                <div className={styles?.emolo_title_2}>Ecommerce</div>
                            </div>
                        </div>
                    </Link>
                    <div
                        className={
                            isMobile ? "headline-5 text-center mt-3" : "headline text-center"
                        }
                    >
                        {currentStep === 0
                            ? "Sign up as vendor"
                            : "Welcome, " + vendorSignupForm?.getFieldValue(["contact_name"])}
                    </div>

                    {!isMobile && <Divider />}

                </Col>
            </Row>
            <Row
                className={
                    isMobile
                        ? "d-flex justify-content-start mx-0"
                        : "d-flex justify-content-center mx-0"
                }
                gutter={[55]}
            >
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                    <Form
                        id="signup-vendor-form"
                        form={vendorSignupForm}
                        layout="vertical"
                        onFinish={(value) => {
                            verify(value);
                        }}
                    >
                        <div
                            style={{
                                display: currentStep === 0 ? "block" : "none",
                            }}
                        >
                            <VendorSignupInfo
                                hiddenInput={hiddenInput}
                                setHiddenInput={setHiddenInput}
                                email={email}
                                setEmail={setEmail}
                                password={password}
                                setPassword={setPassword}
                                confirm={confirm}
                                setConfirm={setConfirm}
                                setisValid={setisValid}
                                setContactName={setContactName}
                            />
                            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} alertClass="mb-4" />

                            <Row className="d-flex justify-content-center">
                                <Col lg={8} className="d-flex justify-content-center">
                                    <Button
                                        style={{ width: "182px" }}
                                        type="primary"
                                        loading={isLoading}
                                        onClick={() => {
                                            validateNext();
                                        }}
                                    >
                                        {"\xa0Next"}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    className="d-flex justify-content-center mt-3"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                >
                                    <span className="subtitle-2">Already have an account?</span>
                                    <span
                                        onClick={() => history.push(LOG_IN)}
                                        className="cursor-pointer subtitle-3"
                                    >
                                        {"\xa0Sign in"}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <div
                            style={{
                                display: currentStep === 1 ? "block" : "none",
                            }}
                        >
                            <VendorSignupInfo2
                                stateDisable={stateDisable}
                                setStateDisable={setStateDisable}
                                signupform={vendorSignupForm}
                                imageName={imageName}
                                setImageName={setImageName}
                                imageName_New={imageName_New}
                                setImageName_New={setImageName_New}
                            />
                            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} alertClass="w-100 mb-4" />

                            <Row gutter={20} className="d-flex justify-content-center">
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={8}
                                    xl={8}
                                    className={
                                        isMobile
                                            ? "d-flex justify-content-center align-items-center flex-column-reverse gap-2"
                                            : "d-flex justify-content-center"
                                    }
                                >
                                    <Button
                                        style={{ width: "182px", backgroundColor: "#FBFBFB" }}
                                        type="default"
                                        onClick={() => setCurentStep((step) => step - 1)}
                                    >
                                        {"\xa0Back"}
                                    </Button>
                                    {isMobile && (
                                        <Button
                                            style={{ width: "182px" }}
                                            type="primary"
                                            htmlType="submit"
                                            loading={signupLoading}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                validateNext();
                                            }}
                                        >
                                            {"\xa0Next"}
                                        </Button>
                                    )}
                                </Col>
                                {!isMobile && (
                                    <Col lg={8} className="d-flex justify-content-center">
                                        <Button
                                            style={{ width: "182px" }}
                                            type="primary"
                                            htmlType="submit"
                                            loading={signupLoading}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                validateNext();
                                            }}
                                        >
                                            {"\xa0Next"}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                <Col
                                    className="d-flex justify-content-center mt-3"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                >
                                    <span className="subtitle-2">Already have an account?</span>
                                    <span
                                        onClick={() => history.push(LOG_IN)}
                                        className="cursor-pointer subtitle-3"
                                    >
                                        {"\xa0Sign in"}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <div
                            style={{
                                display: currentStep === 2 ? "block" : "none",
                            }}
                        >
                            <VerificationCode />
                            <Row gutter={[20]} className="d-flex justify-content-center">
                                <Col
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={8}
                                    xl={8}
                                    className={
                                        isMobile
                                            ? "d-flex justify-content-center align-items-center flex-column-reverse gap-2"
                                            : "d-flex justify-content-center"
                                    }
                                >
                                    <Button
                                        style={{ width: "182px", backgroundColor: "#FBFBFB" }}
                                        type="default"
                                        loading={signupLoading}
                                        onClick={() => setCurentStep((step) => step - 1)}
                                    >
                                        {"\xa0Back"}
                                    </Button>
                                    {isMobile && (
                                        <Button
                                            style={{ width: "182px" }}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {"\xa0Submit"}
                                        </Button>
                                    )}
                                </Col>
                                {!isMobile && (
                                    <Col lg={8} className="d-flex justify-content-center">
                                        <Button
                                            style={{ width: "182px" }}
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {"\xa0Submit"}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                            <Row className="d-flex justify-content-center mt-2">
                                <Col>
                                    <ResendButton
                                        disabled={counter}
                                        type="link"
                                        onClick={() => {
                                            identify(true);
                                            setCallBackCounter(true);
                                        }}
                                    >
                                        Resend Code
                                    </ResendButton>
                                    {callBackCounter && (
                                        <PasswordCounter
                                            setCounter={setCounter}
                                            setCallBackCounter={setCallBackCounter}

                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={9} xl={9}>
                    <div className="p-4 white-text-color" style={{ backgroundColor: "#0DA2E2" }}>
                        <div className="headline-5">
                            Note for being a vendor at our website
                        </div>
                        <div className="subtitle-2 mt-3">
                            <div >
                                After you submit your application the ecommerce admin will review it and we will contact you soon
                            </div>
                            <div>
                                After you create your account you can :
                            </div>
                            <div className="mt-3">
                                Start selling Products
                            </div>
                        </div>
                    </div>
                </Col>
            </Row >
        </div >
    );
}
