import React from 'react'
import { AppContent, AppFooter, AppHeader } from 'components/app-components/index'

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1">
          <AppContent >
            {children}
          </AppContent>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
