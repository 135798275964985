import React from 'react'
import ReactCodeInputStyled from './styles'

export default function ReactCodeComponent({ ...rest }) {
    return (
        <ReactCodeInputStyled
            {...rest}
            fields={6}
            type="number"
            inputMode="numeric"
        />
    )
}
