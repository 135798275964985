import React, { useState } from "react";
import { Divider, Rate } from "antd";
import LoadingSpinner from "components/common/loading-spinner";
import { useSelector } from "react-redux";
import styles from "./CategoriesMenu.module.scss";
import { CheckboxGroup, CollapseCustom } from "styles/globalStyling.styled";
import CurrencyInput from "components/common/currencyInput/currencyInput";
import { useMediaQuery } from "react-responsive";
import useQueryParams from "hooks/useQueryParams";

export default function ProductFilters() {

    const categories = useSelector((state) => state?.app?.categories);
    const brands = useSelector((state) => state?.app?.brands);
    const isCategoriesLoaded = useSelector((state) => state?.app?.isCategoriesLoaded);
    const isBrandsLoaded = useSelector((state) => state?.app?.isBrandsLoaded);
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const { queryParams, setQueryParams } = useQueryParams({ item: "", category: [], brand: [], min_price: null, max_price: null, order_by: "", direction: "", page: 1, min_rating: null });
    const { min_price, max_price, category, brand, min_rating } = queryParams;

    const [minPriceTimer, setMinPriceTimer] = useState(null);
    const [maxPriceTimer, setMaxPriceTimer] = useState(null);

    const minPriceInputChanged = value => {
        clearTimeout(minPriceTimer);
        const newTimer = setTimeout(() => {
            setQueryParams({ min_price: value });
        }, 500);
        setMinPriceTimer(newTimer);
    };

    const maxPriceInputChanged = value => {
        clearTimeout(maxPriceTimer);
        const newTimer = setTimeout(() => {
            setQueryParams({ max_price: value });
        }, 500);
        setMaxPriceTimer(newTimer);
    };

    if (!isCategoriesLoaded && !isBrandsLoaded) {
        return <LoadingSpinner />;
    } else {
        let brandsCheckboxes = brands?.map((item, index) => {
            return (
                { label: item.name, value: item.slug }
            );
        });
        let categoriesCheckboxes = categories?.map((item, index) => {
            return (
                { label: item.name, value: item.slug }
            );
        });

        const isPricePanelOpen = min_price || max_price;
        const isCategoryPanelOpen = category;
        const isBrandPanelOpen = brand;
        const isMinRatingPanelOpen = min_rating;

        return (
            <div>
                <CollapseCustom
                    className={`${isMobile ? "ps-3" : "ps-0"} mb-3`}
                    bordered={false}
                    expandIconPosition="end"
                    style={{ background: "transparent" }}
                    defaultActiveKey={isPricePanelOpen && "price-panel"}
                >
                    <CollapseCustom.Panel
                        key="price-panel"
                        style={{ background: "transparent" }}
                        header={<div className='bold-16'>PRICE ($)</div>}
                    >
                        <div className={`d-flex ${!isMobile && "pt-3"}`}>
                            <CurrencyInput
                                bordered
                                className="me-2"
                                selectAllOnFocus
                                value={min_price}
                                onChange={(value) => {
                                    minPriceInputChanged(value);
                                }}
                            />
                            <CurrencyInput
                                bordered
                                className="ms-2"
                                selectAllOnFocus
                                value={max_price}
                                onChange={(value) => {
                                    maxPriceInputChanged(value);
                                }}
                            />
                        </div>
                    </CollapseCustom.Panel>
                </CollapseCustom>

                <div className={`${!isMobile && "pe-3"}`}>
                    <Divider />
                </div>

                <CollapseCustom
                    className={`${isMobile ? "ps-3" : "ps-0"} mb-3`}
                    bordered={false}
                    expandIconPosition="end"
                    style={{ background: "transparent" }}
                    defaultActiveKey={isMinRatingPanelOpen && "rating-panel"}
                >
                    <CollapseCustom.Panel
                        key="price-panel"
                        style={{ background: "transparent" }}
                        header={<div className='bold-16'>REVIEWS</div>}
                    >
                        <Rate
                            className="mt-2"
                            allowClear
                            style={{ height: "38px", fontSize: "25px" }}
                            onChange={(e) => { setQueryParams({ min_rating: e }); }}
                        />
                    </CollapseCustom.Panel>
                </CollapseCustom>

                <div className={`${!isMobile && "pe-3"}`}>
                    <Divider />
                </div>

                <CollapseCustom
                    className={`${isMobile ? "ps-3" : "ps-0"} mb-3`}
                    bordered={false}
                    expandIconPosition="end"
                    style={{ background: "transparent" }}
                    defaultActiveKey={isCategoryPanelOpen && "category-panel"}
                >
                    <CollapseCustom.Panel
                        key="category-panel"
                        style={{ background: "transparent" }}
                        header={<div className='bold-16'>CATEGORIES</div>}
                    >
                        <CheckboxGroup
                            className="regular-16"
                            options={categoriesCheckboxes}
                            value={category}
                            onChange={(checkedValues) => {
                                setQueryParams({ category: checkedValues });
                            }}
                        />
                    </CollapseCustom.Panel>
                </CollapseCustom>

                <div className="pe-3">
                    <Divider />
                </div>

                <CollapseCustom
                    className={`${isMobile ? "ps-3" : "ps-0"} mb-3`}
                    bordered={false}
                    expandIconPosition="end"
                    style={{ background: "transparent" }}
                    defaultActiveKey={isBrandPanelOpen && "brands-panel"}
                >
                    <CollapseCustom.Panel
                        key="brands-panel"
                        style={{ background: "transparent" }}
                        header={<div className='bold-16'>BRANDS</div>}
                    >
                        <CheckboxGroup
                            className={styles.checkboxContainer}
                            options={brandsCheckboxes}
                            value={brand}
                            onChange={(checkedValues) => {
                                setQueryParams({ brand: checkedValues });
                            }}
                        />
                    </CollapseCustom.Panel>

                </CollapseCustom>
            </div>
        );
    }
}
