import React, { useState } from "react";
import ProductReviewRate from "../product-item/product-review-rate";
import { Button, Col, Divider, Row, message } from "antd";
import { priceFormatter } from "services/helpers";
import TruckIcon from "assets/icons/truck-icon.svg";
import { useMediaQuery } from "react-responsive";
import StockStatus from "../stock-status";
import useQueryParams from "hooks/useQueryParams";
import { apiPost } from "services/apis";
import { errorResponseMapper } from "services/errorResponseMapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { ITEM_TYPE_VENDOR_PRODUCT } from "constants/app-constants";
import AddToCart from "../add-to-cart";

export default function ProductInfo({ data, updateAfterWishlist }) {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const { setQueryParams } = useQueryParams({ brand: [] });

    const [isWishlistLoading, setIsWishlistLoading] = useState(false);

    const updateWishlist = () => {
        setIsWishlistLoading(true);
        const itemType = data?.type;
        apiPost("/customer/update_favorites", {
            type: itemType,
            [itemType === ITEM_TYPE_VENDOR_PRODUCT ? "product" : "item"]: data?.key
        })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    message.success(`${data?.is_favorite ? "Removed from Whishlist!" : "Added to Wishlist!"}`);
                    updateAfterWishlist(data?.key);
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => {
                setIsWishlistLoading(false);
            });
    };

    const ItemInfo = ({ title, value, widthProportion = 12 }) => {
        let titleLg = 8;
        let valueLg = 16;
        let titleSmXs = 8;
        let valueSmXs = 16;
        if (widthProportion === 24) {
            titleLg = 4;
            valueLg = 20;
            titleSmXs = 8;
            valueSmXs = 16;
        }
        if (value) {
            return (
                <Col className={widthProportion === 24 ? "mt-3" : "mt-2"} xl={widthProportion} lg={widthProportion} md={widthProportion} sm={24} xs={24} >
                    <Row>
                        <Col lg={titleLg} sm={titleSmXs} xs={titleSmXs} className="bold-16">
                            {title}
                        </Col>
                        <Col lg={valueLg} sm={valueSmXs} xs={valueSmXs} className="regular-16">
                            {value}
                        </Col>
                    </Row>
                </Col>
            );
        } else {
            return null;
        }
    };

    return (
        <div>
            <div
                className={`primary-color regular-20 mb-2 text-decoration-underline cursor-pointer`}
                style={{ width: "fit-content" }}
                onClick={() => {
                    setQueryParams({ brand: [data?.brand] }, true);
                }}>
                {data?.brand}
            </div>
            <h1 className="bold-30">
                {data?.title}
            </h1>
            <div>
                <ProductReviewRate
                    average_review_rating={data?.average_review_rating}
                    total_rating={data?.total_review_count}
                />
            </div>
            <div>
                <div className="bold-20 mt-3">
                    Price
                </div>
                <div className="primary-color bold-30">
                    {priceFormatter(data?.price)}
                </div>
            </div>

            <div className="d-flex mt-3 regular-18 orange-text-color">
                <div>
                    <img className="me-2" src={TruckIcon} alt="Free Delivery" />
                </div>
                <div>
                    FREE Delivery
                </div>
            </div>
            <div className="mt-2">
                <StockStatus data={data} />
            </div>
            {
                data?.condition && (
                    <div className="bold-20 mt-2">
                        {data?.condition}
                    </div>
                )
            }
            <Divider className={isMobile && "mt-3 mb-2"} />

            <div className="d-flex">
                <AddToCart
                    isFromDetails={true}
                    data={data}
                    nextToAddCart={
                        (<Button
                            disabled={isWishlistLoading}
                            className={`p-2`}
                            style={{
                                width: isMobile ? "38px" : "42px",
                                height: "42px",
                                fontSize: isMobile ? "20px" : "22px",
                            }}
                            onClick={() => {
                                updateWishlist();
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faHeart}
                                style={{
                                    marginBottom: "4px",
                                    color: data?.is_favorite
                                        ? "var(--primary-color)"
                                        : "#BCBCBC"
                                }}
                            />
                        </Button>)
                    }
                />
            </div>
            <Divider className={isMobile && "mt-3 mb-2"} />
            <Row>
                <ItemInfo title={"UPC"} value={data?.upc} />
                <ItemInfo title={"Model"} value={data?.model} />
                <ItemInfo title={"Size"} value={data?.size} />
                <ItemInfo title={"Color"} value={data?.color} />
                <ItemInfo title={"ASIN"} value={data?.asin} />
                <ItemInfo title={"Weight"} value={data?.weight} />
                <ItemInfo title={"Manufacturer"} value={data?.manufacturer} />
                <ItemInfo title={"Part Number"} value={data?.part_number} />
                <ItemInfo title={"Features"} value={data?.features} widthProportion={24} />
            </Row>

        </div>
    );
}
