import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HOME, MANAGE_INVENTORIES, CONTACT_US, PRODUCTS } from "constants/routes-constants";

import { useMediaQuery } from "react-responsive";
import { HeaderMenuItems, StyledLink, StyledMenu } from "styles/globalStyling.styled";
import CategoriesDrawer from "components/home/home-catalog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const NavigationHeader = () => {
  const history = useHistory();
  // const dispatch = useDispatch();

  // const browser = getBrowser();
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const credentials = useSelector((state) => state?.credentials);
  const categories = useSelector((state) => state?.app?.categories);
  //const isCategoriesLoaded = useSelector((state) => state?.app?.isCategoriesLoaded);


  const permissions = useSelector((state) => state?.app?.permissions);
  const userType = useSelector((state) => state?.app?.userType);
  // const switchValid = useSelector((state) => state?.app?.switchValid);

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const selectedKeysLocal = [history.location.pathname];

  /*   const switchAccount = () => {
      setIsLoading(true);
      apiPost('/switch_account', {
        device_type: getDeviceType(),
        browser_name: browser.name,
        browser_version: browser.version,
        hostname: window.location.origin
      })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            setAuthToken(res.data.payload.auth_token);
            dispatch(getUser(res.data.payload.user));
            dispatch(setUserType(res?.data?.payload?.user_type?.toLowerCase()));
            dispatch(setPermissions(res?.data?.payload?.user_type?.toLowerCase()));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }; */


  return (
    <div className="d-flex regular-16 bg-primary padding-main">

      {(userType?.toLowerCase() === "vendor" && (!credentials?.user?.seller_permit || !credentials?.user?.verified)) ?
        null :
        <StyledMenu
          style={{ maxWidth: "100%", overflowX: "auto", overflowY: "hidden", borderBottom: "unset" }}
          mode="horizontal"
          className={`justify-content-start me-0 py-0 text-uppercase`}
          selectedKeys={selectedKeysLocal}
          overflowedIndicator={
            <FontAwesomeIcon
              icon={faAngleRight}
            />
          }
        >
          <HeaderMenuItems key={HOME} >
            <StyledLink className="regular-16" to={HOME}>Home</StyledLink>
          </HeaderMenuItems>
          <HeaderMenuItems key={PRODUCTS} >
            <StyledLink className="regular-16" to={PRODUCTS}>Products</StyledLink>
          </HeaderMenuItems>
          <HeaderMenuItems key={CONTACT_US} >
            <StyledLink className="regular-16" to={CONTACT_US}>Contact Us</StyledLink>
          </HeaderMenuItems>
          <>
            {
              permissions.view_manage_inventories &&
              <HeaderMenuItems
                key={MANAGE_INVENTORIES + "/product-listings/active"}
                padding_top={isMobile ? "0px" : "15px"}
              >
                <StyledLink to={MANAGE_INVENTORIES + "/product-listings/active"}>
                  Manage Inventories
                </StyledLink>
              </HeaderMenuItems>
            }
          </>
        </StyledMenu>
      }

      <CategoriesDrawer
        categories={categories}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
    </div >
  );
};

export default NavigationHeader;
