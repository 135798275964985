import { Button, Form, Modal, message } from 'antd';
import ImageUpload from 'components/common/image-upload/image-upload';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { apiPost } from 'services/apis';
import { errorResponseMapper } from 'services/errorResponseMapper';
import { StyledInput } from 'styles/globalStyling.styled';

export default function RequestWholesaleAccount({ isModalOpen, setIsModalOpen }) {

    const [isLoading, setIsLoading] = useState(false);
    const [resaleCertificate, setResaleCertificate] = useState("");
    const [resaleCertificate_New, setResaleCertificate_New] = useState();

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const [form] = Form.useForm();

    const submitRequest = (value) => {
        if (!resaleCertificate || !resaleCertificate_New) {
            message.error("Resale Certificate Required!")
            return
        }
        setIsLoading(true);
        apiPost(`/customer/request_wholesale`, {
            ...value,
            resale_certificate: resaleCertificate,
            resale_certificate_new: resaleCertificate_New
        })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    form.resetFields();
                    message.success("Thank you! Your submission has been sent.")
                    setIsModalOpen(false);
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Modal
            title="Request Wholesale Account"
            bodyStyle={{ overflowY: "auto", padding: isMobile && "10px" }}
            open={isModalOpen}
            onCancel={() => { setIsModalOpen(false); }}
            centered
            footer={null}
            destroyOnClose={true}
        >
            <Form id="request-wholesale-account-form" onFinish={submitRequest}>
                <div>
                    <label className="regular-16 mb-1">Tax ID</label>
                    <Form.Item name="tax_id">
                        <StyledInput style={{ width: "300px" }} placeholder="Tax ID" />
                    </Form.Item>
                </div>
                <div style={{ width: "300px" }}>
                    <label className="regular-16 mb-2">Resale Certificate<span className="mx-1 mt-3 secondary-color">*</span></label>
                    <ImageUpload
                        attachment={true}
                        attachmentTitle="Add Resale Certificate"
                        attachmentClass="px-4 py-1 fs-6"
                        border={"1px solid #f8931d"}
                        setImageName={setResaleCertificate}
                        setImageName_New={setResaleCertificate_New}
                    />
                </div>
                <div className='d-flex justify-content-center mt-5'>
                    <Button
                        className="px-5 me-2"
                        disabled={isLoading}
                        onClick={() => {
                            form.resetFields();
                            setIsModalOpen(false);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        className="px-5"
                        type="primary"
                        htmlType="submit"
                        id="request-wholesale-account-form"
                        loading={isLoading}
                    >
                        Submit
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}
