import { Button, Divider, Image, message, } from "antd";
import styles from "./ProductItem.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { apiPost, getImageUrlSrc } from "services/apis";
import { LOG_IN, PRODUCT_DETAIL } from "constants/routes-constants";
import { useState } from "react";
import { setItemsCount } from "reduxToolkit/cartReducer.redux";
import { useMediaQuery } from "react-responsive";
import { getItemURI, modifyFileKey, priceFormatter } from "services/helpers";
import no_image from "assets/icons/no_image.jpg";
import ProductReviewRate from "./product-review-rate";
import StockStatus from "../stock-status";
import QuickViewIcon from "assets/icons/products/quick-view-icon.svg";
import CartIcon from "assets/icons/products/cart-icon.svg";
import ProductQuickView from "../product-details/product-quick-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { ITEM_TYPE_VENDOR_PRODUCT } from "constants/app-constants";
import { errorResponseMapper } from "services/errorResponseMapper";
import AddToCart from "../add-to-cart";

export default function ProductItem({ item = null, allItems, setItems }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const credentials = useSelector((state) => state.credentials);
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const link = PRODUCT_DETAIL.replace(":id", getItemURI(item));

    const [isLoading, setIsLoading] = useState(false);
    const [isWishlistLoading, setIsWishlistLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const addToCart = (qty = 1) => {
        setIsLoading(true);
        apiPost("/cart/add_item", { item: item?.key, qty: qty })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    message.success("Item Added to Cart!");
                    dispatch(setItemsCount(res?.data?.payload?.cart_count));
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const ItemImage = () => {
        return (
            <Link to={{ pathname: link, state: { from: history.location.pathname?.concat(history.location.search ?? "") } }} className="w-100 text-center" >
                <div className="my-3">
                    <Image
                        className="mx-auto"
                        src={getImageUrlSrc(item?.[modifyFileKey("image")], 400)}
                        alt={item?.title}
                        style={{ height: "159px", maxWidth: "159px", borderRadius: "2px", objectFit: "contain" }}
                        onError={(ev) => {
                            ev.target.src = no_image;
                        }}
                        preview={false}
                    />
                </div>
            </Link>

        );
    };

    const Title = () => {
        return (
            <Link to={{ pathname: link, state: { from: history.location.pathname?.concat(history.location.search ?? "") } }}  >
                <div style={{ minHeight: isMobile ? "60px" : "85px" }}>
                    <div className={`bold-16 black-text-color ${isMobile ? "text-clamp-3" : "text-clamp-4"} `}>{item?.title}</div>
                </div>
            </Link>
        );
    };

    const Model = () => {
        return (
            <div style={{ minHeight: isMobile ? "18px" : "21px" }} >
                {
                    item?.model &&
                    <div className="d-flex">
                        <span className="bold-14 me-2">
                            Model:
                        </span>
                        <span className={`regular-14 text-clamp-1`}>
                            {item?.model}
                        </span>
                    </div>
                }
            </div>
        );
    };

    const Price = () => {
        return <div className="bold-20 primary-color">{priceFormatter(item?.price)}</div>;
    };

    const ActionButtons = () => {
        let right = "20px";
        if (isMobile) {
            right = "8px";
        }
        return (
            <div className={styles.action_buttons_container}>
                <Button
                    disabled={isLoading || !item?.in_stock}
                    isLoading={isLoading}
                    className={`${styles?.action_button} p-2`}
                    style={{ top: "20px", right: right }}
                    onClick={() => {
                        if (credentials?.isLoggedIn) {
                            addToCart();
                        } else {
                            history.push(LOG_IN);
                        }
                    }}
                >
                    <img src={CartIcon} alt="Add to Cart" />
                </Button>
                <Button
                    disabled={isWishlistLoading}
                    className={`${styles?.action_button} p-2`}
                    style={{
                        top: "70px",
                        right: right,
                        width: isMobile ? "38px" : "42px",
                        height: "42px",
                        fontSize: isMobile ? "20px" : "22px"
                    }}
                    onClick={() => {
                        updateWishlist();
                    }}
                >
                    <FontAwesomeIcon icon={faHeart} style={{ marginBottom: "4px", color: item?.is_favorite ? "var(--primary-color)" : "#BCBCBC" }} />
                </Button>
                <Button
                    className={`${styles?.action_button} p-2`}
                    style={{ top: "120px", right: right }}
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    <img src={QuickViewIcon} alt="Quick View" />
                </Button>
            </div>
        );
    };

    const updateWishlist = () => {
        setIsWishlistLoading(true);
        apiPost("/customer/update_favorites", {
            type: item?.type,
            [item?.type === ITEM_TYPE_VENDOR_PRODUCT ? "product" : "item"]: item?.key
        })
            .then((res) => {
                if (res?.data?.status === "SUCCESS") {
                    message.success(`${item?.is_favorite ? "Removed from Whishlist!" : "Added to Wishlist!"}`);
                    let newItems = [...allItems];
                    newItems.forEach((el) => {
                        if (el.key === item.key) {
                            el.is_favorite = !el.is_favorite;
                        }
                    });
                    setItems([...newItems]);
                } else {
                    message.error(errorResponseMapper(res.data));
                }
            })
            .finally(() => {
                setIsWishlistLoading(false);
            });
    };

    return (
        <div className={styles.container}>
            <ItemImage />
            <ProductReviewRate
                average_review_rating={item?.average_review_rating}
                total_rating={item?.total_review_count}
            />
            <Divider className="my-3" />
            <Price />
            <StockStatus data={item} />
            <Model />
            <Title />
            <ActionButtons />
            <ProductQuickView data={item} allItems={allItems} setItems={setItems} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
            <div className="mb-2">
                <AddToCart data={item} />
            </div>
        </div>
    );
}
