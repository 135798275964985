import { Button, Select } from "antd";
import { SortBySelect } from "styles/globalStyling.styled";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import Breadcrumb from "components/common/breadcrumb";
import { HOME } from "constants/routes-constants";
import FilterMobileMenu from "../filter-mobile-menu.js";
import useQueryParams from "hooks/useQueryParams.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

export default function ProductsHeader() {
    const { Option } = Select;
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const { queryParams, setQueryParams } = useQueryParams({ item: "", category: [], brand: [], min_price: null, max_price: null, order_by: "", direction: "", page: 1 });
    const { order_by, direction } = queryParams;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isFiltering = queryParams?.min_price || queryParams?.max_price || queryParams?.category || queryParams?.brand

    return (
        <div
            style={{
                position: "sticky",
                top: isMobile ? "140px" : "123px",
                backgroundColor: "white",
                zIndex: "3"
            }}
        >
            <div className={`${isMobile ? "d-block" : "d-flex justify-content-between"} `}>
                <div className="mt-3">
                    <Breadcrumb data={[{ path: HOME, title: "Home" }]} title="Products" />
                </div>
                <div className={`${isMobile ? "d-block" : "d-flex"}  mt-2`}>
                    <div className={`${isMobile && "mt-2"} ms-auto d-flex`}>
                        <div
                            className="me-2 ms-3"
                            style={{
                                fontSize: "13px",
                                position: "absolute",
                                top: isMobile ? "38px" : "19px",
                                color: "#959595",
                            }}
                        >
                            Sort by |
                        </div>
                        <SortBySelect
                            defaultValue="alphabetical_asc"
                            className="regular-14"
                            onSelect={(value) => {
                                let order_by = "";
                                let direction = "";
                                switch (value) {
                                    case "alphabetical_asc":
                                        order_by = "alphabetical";
                                        direction = "asc";
                                        break;
                                    case "alphabetical_desc":
                                        order_by = "alphabetical";
                                        direction = "desc";
                                        break;
                                    case "date_asc":
                                        order_by = "date";
                                        direction = "asc";
                                        break;
                                    case "date_desc":
                                        order_by = "date";
                                        direction = "desc";
                                        break;
                                    case "price_asc":
                                        order_by = "price";
                                        direction = "asc";
                                        break;
                                    case "price_desc":
                                        order_by = "price";
                                        direction = "desc";
                                        break;
                                    default:
                                        order_by = "";
                                        direction = "";
                                        break;
                                }
                                setQueryParams({ order_by: order_by, direction: direction })
                            }}
                            value={`${order_by ?? "alphabetical"}_${direction ?? "asc"}`}
                        >
                            <Option value="alphabetical_asc" className="regular-14">
                                Alphabetical Asc. (A-Z)
                            </Option>
                            <Option value="alphabetical_desc" className="regular-14">
                                Alphabetical Desc. (Z-A)
                            </Option>
                            <Option value="date_desc" className="regular-14">
                                Newly Listed
                            </Option>
                            <Option value="date_asc" className="regular-14">
                                Oldest Listed
                            </Option>
                            <Option value="price_asc" className="regular-14">
                                Lowest Price
                            </Option>
                            <Option value="price_desc" className="regular-14">
                                Highest Price
                            </Option>
                        </SortBySelect>
                        {
                            isMobile && (
                                <div className="ms-auto">
                                    <Button
                                        type="ghost"
                                        className={`d-flex px-3 ${isFiltering && "active-style"}`}
                                        onClick={() => {
                                            setIsMenuOpen(true);
                                        }}
                                    >
                                        <span className="regular-16 me-2">
                                            Filter
                                        </span>
                                        <FontAwesomeIcon icon={faFilter} className="mt-1" />
                                    </Button>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    isMobile && (
                        <FilterMobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
                    )
                }
            </div>

        </div>
    );
}
