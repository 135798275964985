import { KEY_AUTH_TOKEN } from "constants/app-constants";
import { LOG_IN } from "constants/routes-constants";


export function isAuthenticated() {
    let authToken = getAuthToken();
    return authToken !== undefined && authToken !== null;
}

export const invalidAuthTokenFunc = () => {
    localStorage.clear();
    window.location = LOG_IN;
}

export const setAuthToken = (auth_token) => {
    localStorage.setItem(KEY_AUTH_TOKEN, auth_token);
};

export const resetAuthToken = () => {
    localStorage.removeItem(KEY_AUTH_TOKEN);
};

export const getAuthToken = () => {
    var authToken = localStorage.getItem(KEY_AUTH_TOKEN);
    return authToken ? authToken : null;
};

