import { RightOutlined } from "@ant-design/icons";
import CarouselHomeBack from "assets/icons/carousel-items/arrow-left-circle.svg";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

export default function Breadcrumb({ data = [], title, showBack = false, metaDetails = null }) {
    const { state } = useLocation();
    const backURL = state?.from ? state.from : data[data.length - 1].path

    if (data && data?.length > 0) {
        const metaTitle = `${metaDetails?.title ? metaDetails?.title + " - " : ""}Emolo`;
        return (
            <>
                {
                    metaDetails && <Helmet>
                        <title>{metaTitle}</title>
                        <meta name="description" content={metaDetails?.description} />
                        <meta name="keywords" content={metaDetails?.keywords} />
                        <meta property="og:title" content={metaTitle} />
                        <meta property="og:description" content={metaDetails?.description} />
                        <meta property="og:image" content={metaDetails?.imageUrl} />
                        <meta property="og:url" content={metaDetails?.url} />
                        <meta name="twitter:title" content={metaTitle} />
                        <meta name="twitter:description" content={metaDetails?.description} />
                    </Helmet>
                }
                <div>
                    <div className={`d-flex align-items-center regular-14 black-text-color text-capitalize`}>
                        {
                            data.map((item, index) => {
                                return (
                                    <div key={`breadcrumb-${index}`} className="d-flex align-items-center">
                                        <Link to={item.path} className="black-text-color">{item.title}</Link>
                                        <RightOutlined style={{ fontSize: "10px" }} className="mx-2" />
                                    </div>
                                )
                            })
                        }
                        <span className={`bold-14 text-clamp-1`}>{title}</span>
                    </div>
                    {
                        showBack && <div className="mt-3">
                            <Link className="bold-20 black-text-color align-items-center" to={backURL} >
                                <img
                                    src={CarouselHomeBack}
                                    alt="carousel back"
                                    className="cursor-pointer mb-1"
                                    style={{ maxWidth: "20px", height: "20px" }}
                                />
                                <span className="ms-2">Back To {data[data.length - 1].title}</span>
                            </Link>
                        </div>
                    }
                </div>
            </>
        );
    }
}