
import { apiPost } from "services/apis";
import { signOut } from "reduxToolkit/checkCredentials.redux";
import LogoutIcon from "assets/icons/header/logout-icon.svg";
/* import MobileChecksIcon from "assets/icons/header/mobile-checks-icon.svg"; */
import RMAIcon from "assets/icons/header/rma-icon.svg";
import MyOrdersIcon from "assets/icons/header/my-orders-icon.svg";
import MyProfileIcon from "assets/icons/header/my-profile-icon.svg";
/* import MyReviewsIcon from "assets/icons/header/my-reviews-icon.svg"; */
import PaymentMethodsIcon from "assets/icons/header/payment-method-icon.svg";
import WishlistIcon from "assets/icons/header/wishlist-icon.svg";
import MyReviews from "assets/icons/header/my-reviews-icon.svg";

import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MY_ACCOUNT, WISHLIST, /* MOBILE_CHECKS, */ RMA_ROUTE, MY_ORDERS, PAYMENT_METHODS, MY_REVIEWS, MY_ORDERS_MENU } from "constants/routes-constants";
import { useHistory } from "react-router-dom";
import RequestWholesaleAccount from "./request-wholesale-account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {/*  faBoxOpen, */ faStore } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
/* import RequestVendorAccount from "./request-vendor-account";
import { USER_TYPE_CUSTOMER, USER_TYPE_VENDOR } from "constants/app-constants"; */

const AccountMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const permissions = useSelector((state) => state?.app?.permissions);
    const credentials = useSelector((state) => state?.credentials);
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isRetail = credentials?.user?.type === "RETAIL";
    /* const userType = useSelector((state) => state?.app?.userType); */

    const [requestWholesaleOpen, setRequestWholesaleOpen] = useState(false);
    /* const [requesVendorOpen, setRequestVendorOpen] = useState(false); */

    const callLogout = () => {
        apiPost("/store_logout", {}).finally(() => {
            localStorage.clear();
            dispatch(signOut());
            window.location.reload(false);
        });
    };
    const selectedKey = history?.location?.pathname?.includes(MY_ORDERS) ? MY_ORDERS : history?.location?.pathname;
    return (
        <div>

            <Menu
                style={{
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "6px",
                }}
                selectedKeys={[selectedKey]}
            >
                <Menu.Item
                    key={MY_ACCOUNT}
                    onClick={() => history.push(MY_ACCOUNT)}
                >
                    <img src={MyProfileIcon} alt="My Profile" className={isMobile ? "me-2" : "me-3"} />
                    <span className="regular-14">My Profile</span>
                </Menu.Item>
                {
                    permissions?.view_favorites &&
                    <>
                        <Menu.Item
                            key={WISHLIST}
                            onClick={() => history.push(WISHLIST)}
                        >
                            <img src={WishlistIcon} alt="Wishlist" className={isMobile ? "me-2" : "me-3"} style={{ width: "14px", height: "14px" }} />
                            <span className="regular-14">Wishlist</span>
                        </Menu.Item>
                    </>
                }
                {
                    isRetail && (

                        <Menu.Item
                            key={PAYMENT_METHODS}
                            onClick={() => history.push(PAYMENT_METHODS)}
                        >
                            <img src={PaymentMethodsIcon} alt="Payment Methods" className={isMobile ? "me-2" : "me-3"} style={{ width: "14px", height: "14px" }} />
                            <span className="regular-14">Payment Methods</span>
                        </Menu.Item>

                    )
                }
                {
                    /*  permissions?.view_recent_activities &&
                     <>
                         <Menu.Item
                             key="order-history-menu-item"
                             onClick={() => history.push(NEW_UPDATES)}
                         >
                             <img
                                 src={RecentActivities}
                                 alt="recent activities icon"
                                 className="mx-2"
                             />
                             <span className="caption">Recent activities</span>
                         </Menu.Item>
                     </> */
                }
                {
                    permissions.view_my_orders &&
                    <Menu.Item
                        key={MY_ORDERS}
                        onClick={() => {
                            isRetail
                                ? history.push(MY_ORDERS_MENU.replace(":menu", "invoices"))
                                : history.push(MY_ORDERS_MENU.replace(":menu", "orders"))
                        }}
                    >
                        <img src={MyOrdersIcon} alt="RMA" className={isMobile ? "me-2" : "me-3"} />
                        <span className="regular-14">My Orders</span>
                    </Menu.Item>
                }
                <Menu.Item
                    key={MY_REVIEWS}
                    onClick={() => history.push(MY_REVIEWS)}
                >
                    <img src={MyReviews} alt="My Reviews" className={isMobile ? "me-2" : "me-3"} />
                    <span className="regular-14">My Reviews</span>
                </Menu.Item>
                {
                    credentials?.user?.type?.toLowerCase() === "wholesale" && permissions.view_rma &&
                    <Menu.Item
                        key={RMA_ROUTE}
                        onClick={() => history.push(RMA_ROUTE)}
                    >
                        <img src={RMAIcon} alt="RMA" className={isMobile ? "me-2" : "me-3"} />
                        <span className="regular-14">RMA</span>
                    </Menu.Item>
                }
                {
                    /*  permissions.view_mobile_checks &&
                     <Menu.Item
                         key={MOBILE_CHECKS}
                         onClick={() => history.push(MOBILE_CHECKS)}
                     >
                         <img src={MobileChecksIcon} alt="Mobile Checks" className={isMobile ? "me-2" : "me-3"} />
                         <span className="regular-14">Mobile Checks</span>
                     </Menu.Item> */
                }
                {
                    credentials?.user?.type?.toLowerCase() === "retail" &&
                    <Menu.Item onClick={() => { setRequestWholesaleOpen(true); }} key="request-wholesale-menu-item">
                        <FontAwesomeIcon
                            className={`primary-color ${isMobile ? "me-2" : "me-3"}`}
                            icon={faStore}
                            style={{ width: "14px", height: "14px" }}
                        />
                        <span className="regular-14">Request Wholesale Account</span>
                    </Menu.Item>
                }

                {/* {
                    userType?.toLowerCase() === USER_TYPE_CUSTOMER &&
                    credentials?.user?.type?.toLowerCase() === "wholesale" &&
                    userType?.toLowerCase() !== USER_TYPE_VENDOR &&
                    <Menu.Item onClick={() => { setRequestVendorOpen(true); }} key="request-vendor-menu-item">
                        <FontAwesomeIcon className="primary-color me-3" icon={faBoxOpen} style={{ width: "14px", height: "14px" }} />
                        <span className="regular-14">Request Vendor Account</span>
                    </Menu.Item>
                } */}

                <Menu.Item onClick={callLogout} key="logout-menu-item">
                    <img src={LogoutIcon} alt="logout" className={isMobile ? "me-2" : "me-3"} />
                    <span className="regular-14">Logout</span>
                </Menu.Item>
            </Menu>
            {/* Request Wholesale Account Modal */}
            <RequestWholesaleAccount
                isModalOpen={requestWholesaleOpen}
                setIsModalOpen={setRequestWholesaleOpen}
            />
            {/* Request Vendor Account Modal */}
            {/* <RequestVendorAccount
                isModalOpen={requesVendorOpen}
                setIsModalOpen={setRequestVendorOpen}
            /> */}
        </div>
    );
};

export default AccountMenu;