import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input,/*  Radio, */ Row } from "antd";
import { Link, useHistory } from "react-router-dom";
import { setAuthToken } from "services/authentication";
import { FORGET_PASSWORD, HOME, MY_ACCOUNT, SIGNUP } from "constants/routes-constants";
import { apiPost } from "services/apis";
import { useDispatch } from "react-redux";
import { getCredentials, getUser } from "reduxToolkit/checkCredentials.redux";
import { errorResponseMapper } from "services/errorResponseMapper";
import TwoFactor from "./two-factor";
import { setPermissions, setSwitchValid, setUserType } from "reduxToolkit/appReducer.redux";
import { USER_TYPE_CUSTOMER, /* USER_TYPE_VENDOR */ } from "constants/app-constants";
import { getBrowser, getDeviceType } from 'services/helpers';
import Breadcrumb from "components/common/breadcrumb";
import { TabsCustom } from "styles/globalStyling.styled";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const browser = getBrowser();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [twoFactorVisible, setTwoFactorVisible] = useState(false);
  const [verif_req_id, setVerif_req_id] = useState(false);
  const [userId, setUserId] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const storeLogin = (credentials) => {
    setErrorMessage("");
    setIsLoading(true);
    apiPost("/store_login", {
      a_password: credentials?.a_password,
      contact_me_by_fax: credentials?.contact_me_by_fax,
      user_id: credentials?.username,
      password: credentials?.password,
      user_type: USER_TYPE_CUSTOMER/* credentials?.user_type */,
      device_type: getDeviceType(),
      browser_name: browser.name,
      browser_version: browser.version,
      hostname: window.location.origin
    })
      .then((res) => {
        const data = res.data;
        if (data.status === "SUCCESS") {
          const payload = data?.payload;
          if (payload?.user && payload?.auth_token) {

            setAuthToken(payload.auth_token);
            dispatch(getCredentials());
            dispatch(getUser(payload.user));

            const userType = payload?.user_type?.toLowerCase();
            // const type = userType === USER_TYPE_CUSTOMER ? payload?.user?.type?.toLowerCase() : null;
            dispatch(setPermissions(userType));

            if (payload?.user_type === "VENDOR" && !payload?.user?.verified) {
              history?.push(MY_ACCOUNT);
            }
            else {
              history.push(HOME);
            }
          } else {
            setUserId(payload?.user_id);
            setVerif_req_id(payload?.verif_req_id);
            setTwoFactorVisible(true);
          }

          dispatch(setUserType(payload?.user_type?.toLowerCase()));
          dispatch(setSwitchValid(payload?.switch_valid));
        } else {
          setErrorMessage(errorResponseMapper(data));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFinish = (value) => {
    storeLogin(value);
  };

  return (
    <div>
      <div className="mt-3">
        <Breadcrumb data={[{ path: HOME, title: "Home" }]} title="Login" />
      </div>
      <div className="pt-lg-5 pt-4 w-100">
        <div className="mx-0 mx-lg-0">
          <Row className="justify-content-center">
            <Col
              className="p-lg-5 pt-lg-4 p-4 pt-3"
              style={{ border: "1px solid #F1F1F1" }}
              xl={twoFactorVisible ? 12 : 9}
              lg={twoFactorVisible ? 12 : 9}
              md={12}
              sm={24}
              xs={24}
            >
              {twoFactorVisible ? (
                <TwoFactor
                  userId={userId}
                  verif_req_id={verif_req_id}
                  setVerif_req_id={setVerif_req_id}
                  userType={USER_TYPE_CUSTOMER}/* form.getFieldValue('user_type') */
                />
              ) : (
                <TabsCustom>
                  <TabsCustom.TabPane tab="Login" key="login" className="pt-0">
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                      <fieldset disabled={isLoading}>
                        <Form.Item
                          className="mb-4 remove-asterisk"
                          name="username"
                          rules={[
                            { required: true, message: "Username required!" },
                          ]}
                          label={<label className="regular-14">Email</label>}
                        >
                          <Input className="py-2" placeholder="Enter Username" />
                        </Form.Item>

                        <Form.Item
                          className="mb-0 remove-asterisk"
                          name="password"
                          rules={[
                            { required: true, message: "Password required!" },
                          ]}
                          label={<label className="regular-14">Password</label>}
                        >
                          <Input.Password placeholder="Enter Password" />
                        </Form.Item>
                        <Form.Item
                          className="mb-0"
                          name="contact_me_by_fax"
                          initialValue={false}
                          style={{ display: "none" }}
                        >
                          <Checkbox style={{ display: "none" }} tabIndex={-1} />
                        </Form.Item>
                        <Form.Item
                          name="a_password"
                          initialValue={""}
                          style={{ display: "none" }}
                        >
                          <Input type={"hidden"} tabIndex={-1} />
                        </Form.Item>
                        <div className="mt-1 text-end">
                          <Link className="regular-14 primary-color text-decoration-underline" to={FORGET_PASSWORD}>
                            Forgot your password?
                          </Link>
                        </div>
                        <div style={{ color: "#FF4D4F", textAlign: "center" }}>
                          {errorMessage}
                        </div>

                        <Button
                          className={`bold-16 py-2 mt-4 w-100`}
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                        >
                          Log in
                        </Button>

                        <div className="mt-4 w-100">
                          <span className="regular-16">
                            Don&apos;t have an account?&nbsp;
                          </span>
                          <Link className="orange-text-color bold-16" to={SIGNUP}>Register</Link>
                        </div>
                      </fieldset>
                    </Form>
                  </TabsCustom.TabPane>
                </TabsCustom>

              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
