import ReactCodeComponent from 'components/common/react-code-component';
import PasswordCounter from 'components/password-counter/password-counter';
import React from 'react';
import { SignUpResendCodeButton } from '../signup.styled';
import { useMediaQuery } from 'react-responsive';
import { Form } from 'antd';
import { StyledButton, StyledGhostButton } from 'styles/globalStyling.styled';

export default function Verification({ activeKey, setActiveKey, identify, isLoading, form, counter, setCounter, callBackCounter, setCallBackCounter }) {

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    return (
        <div>
            <div className='bold-20 primary-color'>Verification Code</div>
            <div className='regular-14 my-4'>Enter verification code received by email</div>
            <Form.Item name={"code"} rules={[{ required: activeKey === "verification" ? true : false, message: "This field is required!" }]}>
                <ReactCodeComponent />
            </Form.Item>
            <div className="d-flex justify-content-start align-items-center mt-4 gap-2">
                <SignUpResendCodeButton
                    disabled={counter}
                    type="link"
                    onClick={() => {
                        identify(true);
                        setCallBackCounter(true);
                    }}
                >
                    Resend Code
                </SignUpResendCodeButton>
                {callBackCounter && (
                    <PasswordCounter
                        setCounter={setCounter}
                        setCallBackCounter={setCallBackCounter}

                    />
                )}
            </div>
            <div className='w-100 d-flex justify-content-between mt-4'>
                <StyledGhostButton
                    type='default'
                    style={{ width: isMobile ? "100px" : "200px" }}
                    onClick={() => setActiveKey("billing-info")}
                >
                    Back
                </StyledGhostButton>
                <StyledButton
                    type='primary'
                    loading={isLoading}
                    onClick={() => { form.submit(); }}
                    style={{ width: isMobile ? "100px" : "200px" }}
                >
                    Submit
                </StyledButton>
            </div>
        </div>
    );
}
