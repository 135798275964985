import { Alert } from "antd"


export default function ErrorMessage({ errorMessage, setErrorMessage, containerClass = "", alertClass = "w-50" }) {
    if (errorMessage) {
        return (
            <div className={`d-flex justify-content-center align-items-center ${containerClass}`}>
                <Alert
                    className={`${alertClass}`}
                    message={errorMessage}
                    closable
                    showIcon
                    type="error"
                    onClose={() => setErrorMessage()}
                />
            </div>
        )
    } else {
        return null
    }

}