import React, { useEffect, useState } from "react";
import { Upload, Modal, message } from "antd";
import uploadPlaceholder from "assets/icons/upload-placeholder.svg";
import ImageIcon from "assets/icons/image-icon.svg";
import ExportIcon from "assets/icons/export-icon.svg";
import { UploadAttachmentDiv, UploadButton, UploadButtonPosition } from "pages/my-account/my-account.styled";
import { useMediaQuery } from "react-responsive";
import { convertBase64 } from "services/helpers";
import { isValidUrl } from "services/apis";

const { REACT_APP_BUCKET_ENV: BUCKET_ENV, REACT_APP_IMAGE_API_URL: BASE_IMAGE_API_URL } = process.env;

const ImageUpload = ({
  imagePreview,
  imageName,
  setImageName,
  imageName_New,
  setImageName_New,
  disabled = false,
  profile,
  attachment,
  isFullHeight = true,
  multiple = false,
  isRounded = true,
  attachmentTitle = "Attachment",
  attachmentClass = "",
  centered = false
}) => {

  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const isBase64 = multiple;

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    async function initImagePreview() {
      const isValidUrlLocal = isValidUrl(imagePreview) || typeof imagePreview === "string"
      if (isValidUrlLocal) {
        setFileList([
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: imagePreview,
            opposite_url: imagePreview,
          },
        ]);
      } else {
        const imagePreviewLocal = await convertBase64(imagePreview);
        setFileList([
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: imagePreviewLocal,
            opposite_url: imagePreview,
          },
        ]);
      }
    }
    initImagePreview();


  }, [imagePreview]);

  const handleCancel = () => {
    setIsPreviewOpen(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await convertBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setIsPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = async (response) => {
    setFileList(response.fileList);
    if (response.file.status === 'done') {
      const imgBase64 = await convertBase64(response?.file.originFileObj);
      const image_new = isBase64 ? { name: response.file.name, content: imgBase64 } : response?.file.originFileObj;
      if (multiple) {
        setImageName_New && setImageName_New((prev) => [...prev, image_new]);
        setImageName((prev) => [...prev ?? "", response?.file?.response?.name ?? ""]);
      } else {
        setImageName_New && isBase64 ? setImageName_New({ ...image_new }) : setImageName_New(image_new);
        setImageName(response?.file?.response?.name ?? "");
      }
      message.success(`${response.file.name} uploaded`);
    } else if (response.file.status === 'error') {
      message.error(`${response.file.name} file upload failed.`);
      let fileListLocal = [...fileList];
      fileListLocal.pop();
      setFileList([...fileListLocal]);
    }
  };

  const uploadButton =
    attachment
      ? (
        <UploadAttachmentDiv className={`w-100 text-center px-3 ${attachmentClass}`}>
          <img alt="" src={ExportIcon} />
          <span className="body-1 primary-color ms-2">{attachmentTitle}</span>
        </UploadAttachmentDiv>
      )
      : profile
        ? (
          <>
            <UploadButtonPosition
              left={isMobile ? "205px" : "130px"}
              bottom={"5px"}
            >
              <UploadButton type="primary">
                <img src={ImageIcon} alt="upload img" />
              </UploadButton>
            </UploadButtonPosition>
          </>
        )
        : (
          <div>
            <img src={uploadPlaceholder} alt="" />
            <div style={{ marginTop: 8, color: "#979BA1", fontWeight: "400" }}>
              Upload Image
            </div>
          </div>
        );

  const props = {
    beforeUpload: (file) => {
      if (!attachment) {
        const isWEBP = file.type === "image/webp";
        const isPNG = file.type === "image/png";
        const isJPEG = file.type === "image/jpeg";
        if (!isPNG && !isWEBP && !isJPEG) {
          message.error(`${file.name} is not an image file`);
        }
        return isPNG || isWEBP || isJPEG || Upload.LIST_IGNORE;
      } else {
        const isPDF = file.type === "application/pdf";
        const isDOC = file.type === "application/msword";
        const isDOCX = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        if (!isPDF && !isDOC && !isDOCX) {
          message.error(`${file.name} is not acceptable file format`);
        }
        return isPDF || isDOC || isDOCX || Upload.LIST_IGNORE;
      }
    },
    headers: {
      "Bucket-Env": BUCKET_ENV
    },
    action: BASE_IMAGE_API_URL,
    name: 'file',
  };
  return (
    <div className={`${isFullHeight && `h-100`} w-100`} >
      <Upload
        {...props}
        className={`${isRounded && "pic"} ${centered && "centered-pic"} `}
        listType={attachment ? "text" : !profile && "picture-card"}
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        disabled={disabled}
        onRemove={(value) => {
          if (multiple) {
            if (value.url) {
              setImageName(imageName.filter((name) => !value.url.includes(name)));
              setImageName_New && setImageName_New(imageName_New?.filter((name) => !value.url.includes(name)));
            } else {
              setImageName(imageName.filter((name) => !name.includes(value.name)));
              setImageName_New && setImageName_New(imageName_New?.filter((name) => !name.includes(value.name)));
            }
          } else {
            setImageName(null);
            setImageName_New && setImageName_New(null);
          }
        }}
      >
        {
          multiple
            ? fileList?.length >= 3
              ? null
              : uploadButton
            : fileList?.length >= 1
              ? null
              : uploadButton
        }
      </Upload>
      <Modal
        open={isPreviewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div >
  );
};

export default ImageUpload;
