import { PRODUCTS } from 'constants/routes-constants';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { createQueryString, queryStringToObject } from 'services/helpers';

export default function useQueryParams(options) {
    const { search, pathname } = useLocation();
    const history = useHistory();

    // get query params
    const queryParams = React.useMemo(() => queryStringToObject(search, options), [options, search]);

    // updates the query params
    function setQueryParams(queryObj, isClear = false) {
        const qs = isClear ? createQueryString(queryObj) : createQueryString({ ...queryParams, ...queryObj });

        if (pathname === PRODUCTS) {
            history.push({ search: qs });
        } else {
            history.push({ pathname: PRODUCTS, search: qs });
        }
    }

    return { queryParams, setQueryParams };
}
