import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function LoadingSpinner({ height = "50vh", spinning = true, children }) {

    const loadingIcon = <LoadingOutlined style={{ fontSize: 75 }} spin />;

    return (
        <>
            {
                children
                    ? <Spin indicator={(loadingIcon)} spinning={spinning} >
                        {children}
                    </Spin>
                    : <div style={{ height: height }} className="d-flex justify-content-center align-items-center" >
                        <Spin indicator={(loadingIcon)} spinning={spinning} />
                    </div >
            }
        </>
    );
}
