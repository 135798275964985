import { Col, Form, Row, Select } from "antd";
import ImageUpload from "components/common/image-upload/image-upload";
import { countries } from "constants/countries";
import { states } from "constants/states";
import {
    MyAccountInputs,
    MyAccountSelect,
} from "pages/my-account/my-account.styled";
import React from "react";
import { useMediaQuery } from "react-responsive";

export default function VendorSignupInfo2({
    signupform,
    stateDisable,
    setStateDisable,
    setImageName,
    imageName,
    setImageName_New,
    imageName_New
}) {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    return (
        <>
            <Row
                gutter={!isMobile && [30]}
                className={isMobile && "d-flex justify-content-center"}
            >
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Address 1 <span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                        name="primary_address"
                    >
                        <MyAccountInputs
                            style={{ width: "100%" }}
                            placeholder="Enter Address 1"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={isMobile && "d-flex justify-content-center"}>
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">Address 2</label>
                    <Form.Item name="secondary_address">
                        <MyAccountInputs
                            style={{ width: "100%" }}
                            placeholder="Enter Address 2"
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={30}>
                <Col lg={12}>
                    <label className="body-2 mb-2">
                        Country<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        name="country"
                        rules={[{ required: true, message: "This field is required!" }]}
                        initialValue="us"
                    >
                        <MyAccountSelect
                            onSelect={(value) => {
                                if (value === "us") {
                                    setStateDisable(false);
                                } else {
                                    signupform.resetFields(["state"]);
                                    setStateDisable(true);
                                }
                            }}
                            style={{ width: "100%" }}
                            placeholder="Select country"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {
                                countries.map((country, index) => {
                                    return (
                                        <Select.Option
                                            value={country.iso_2}
                                            key={"countries-" + index}
                                        >
                                            {country.Name}
                                        </Select.Option>
                                    );
                                })
                            }
                        </MyAccountSelect>
                    </Form.Item>
                </Col>
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                    <label className="body-2 mb-2">
                        City<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                        name="city"
                    >
                        <MyAccountInputs style={{ width: "100%" }} placeholder="City" />
                    </Form.Item>
                </Col>
            </Row>
            <Row
                gutter={!isMobile && [30]}
                className={isMobile && "d-flex justify-content-center"}
            >
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                    <label className="body-2 mb-2">
                        State
                        {!stateDisable ? (
                            <span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                        ) : null}
                    </label>
                    <Form.Item
                        rules={[
                            {
                                required: !stateDisable ? true : false,
                                message: stateDisable ? "" : "This field is required!",
                            },
                        ]}
                        name="state"
                    >
                        <MyAccountSelect
                            disabled={stateDisable}
                            style={{ width: "100%" }}
                            placeholder="Select state"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA.children
                                    .toLowerCase()
                                    .localeCompare(optionB.children.toLowerCase())
                            }
                        >
                            {states.map((state, index) => {
                                return (
                                    <Select.Option value={state.name} key={"states-" + index}>
                                        {state.name}
                                    </Select.Option>
                                );
                            })}
                        </MyAccountSelect>
                    </Form.Item>
                </Col>
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                    <label className="body-2 mb-2">
                        Zip Code<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                        name="zip_code"
                    >
                        <MyAccountInputs style={{ width: "100%" }} placeholder="Zip Code" />
                    </Form.Item>
                </Col>
            </Row>

            <Row className={isMobile && "d-flex justify-content-center"}>
                <Col className="mb-4" xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        {
                            stateDisable
                                ? "Business Documents" //non USA
                                : "Seller's permit" //USA
                        }
                    </label>
                    <ImageUpload
                        imageName={imageName}
                        setImageName={setImageName}
                        imageName_New={imageName_New}
                        setImageName_New={setImageName_New}
                        imagePreview={null}
                        attachment={true}
                        multiple={true}
                    />
                </Col>
            </Row>
        </>
    );
}
