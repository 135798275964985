import React from "react";
import styles from "./CategoriesMenu.module.scss";
import ProductFilters from "./product-filters";

export default function CategoriesMenu() {
  return (
    <div className={`pb-4 rounded-4 bg-white d-block menu_container ${styles.container}`}>
      <ProductFilters />
    </div >
  );

}
