import { Rate } from 'antd';
import React from 'react';

export default function ProductReviewRate({ total_rating, average_review_rating }) {
    return (
        <div className="d-flex mt-2">
            <Rate count={5} value={average_review_rating} allowHalf={true} className="regular-16" disabled={true} />
            <span className="regular-14 ms-2" style={{ paddingTop: "3px" }} >
                {total_rating ?? 0}
            </span>
        </div>
    );
}
