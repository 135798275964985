import { Button } from 'antd';
import styled from 'styled-components';

export const ParentDiv = styled.div`
    padding: ${(prop) => prop?.isMobile ? "20px" : "30px"};
    border: 1px solid #f1f1f1;
    width: 100%;
`;

export const SignUpResendCodeButton = styled(Button)`
    height: 32px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color:#00b2ff;
    padding: 0;
    &:hover {
        background: #fff;
        color:#00b2ff;
    }
    &:active {
        background: #fff;
        color:#00b2ff;
    }
    &:focus {
        background: #fff;
        color:#00b2ff;
    }
`;