import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: 0,
};

export const appSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItemsCount: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setItemsCount } = appSlice.actions;

export default appSlice.reducer;
