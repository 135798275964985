import React, { useState } from 'react';
import RatingStar from 'assets/icons/products/rating-star.svg';
import SmallRatingStar from 'assets/icons/products/small-rating-star.svg';
import NoReviews from 'assets/icons/products/no-reviews.png';
import { Button, Col, Pagination, Progress, Row, Select, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ReviewComponent from './review-component';
import AddReviewModal from './modals/add-review-modal';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

export default function ProductReviews({ sortValue, setSortValue, pages, currentPage, setCurrentPage, getReviews, itemKey, reviewsList, isGetReviewsLoading }) {

    const customFormat = percent => `${percent}%`;
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const credentials = useSelector((state) => state?.credentials);

    const [isAddReviewModalOpen, setIsAddReviewModalOpen] = useState(false);

    return (
        <div>
            <Spin spinning={isGetReviewsLoading}>
                <div className={`d-flex ${isMobile && "flex-column"} ${isMobile ? "align-items-start" : "align-items-center"} justify-content-between py-2`}>
                    <div className={`d-flex ${isMobile && "flex-column justify-content-center"} align-items-center gap-4`}>
                        <div>
                            <div className='d-flex align-items-center gap-2'>
                                <span className='product-rating'>{reviewsList?.average_review_rating}</span>
                                <span><img src={RatingStar} alt="rating-star" /></span>
                            </div>
                            <div>
                                <div className='product-reviews-nb bold-12'>{reviewsList?.reviews_count} review{reviewsList?.reviews_count > 1 ? "s" : ""}</div>
                            </div>
                        </div>
                        <div style={{ width: 200 }}>
                            <div className='d-flex align-items-center gap-1'>
                                <span style={{ width: "15px" }}>5</span>
                                <img src={SmallRatingStar} alt='small-rating-star' />
                                <Progress
                                    percent={reviewsList?.reviews_breakdown?.five_star?.percentage}
                                    size="small"
                                    format={customFormat}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <span style={{ width: "15px" }}>4</span>
                                <img src={SmallRatingStar} alt='small-rating-star' />
                                <Progress
                                    percent={reviewsList?.reviews_breakdown?.four_star?.percentage}
                                    size="small"
                                    format={customFormat}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <span style={{ width: "15px" }}>3</span>
                                <img src={SmallRatingStar} alt='small-rating-star' />
                                <Progress
                                    percent={reviewsList?.reviews_breakdown?.three_star?.percentage}
                                    size="small"
                                    format={customFormat}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <span style={{ width: "15px" }}>2</span>
                                <img src={SmallRatingStar} alt='small-rating-star' />
                                <Progress
                                    percent={reviewsList?.reviews_breakdown?.two_star?.percentage}
                                    size="small"
                                    format={customFormat}
                                />
                            </div>
                            <div className='d-flex align-items-center gap-1'>
                                <span style={{ width: "15px" }}>1</span>
                                <img src={SmallRatingStar} alt='small-rating-star' />
                                <Progress
                                    percent={reviewsList?.reviews_breakdown?.one_star?.percentage}
                                    size="small"
                                    format={customFormat}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${isMobile && "mt-4"}`}>
                        {credentials?.isLoggedIn && <Button style={{ color: "#FFA620" }} type='link' onClick={() => { setIsAddReviewModalOpen(true); }}>
                            <FontAwesomeIcon icon={faPlusCircle} className='me-2' /> Add a Review
                        </Button>}
                        <Select
                            placeholder="Sort by..."
                            defaultValue={sortValue}
                            style={{ width: "200px" }}
                            onSelect={(e) => { setSortValue(e); }}
                        >
                            <Select.Option value={"date_asc"}>Oldest To Newest</Select.Option>
                            <Select.Option value={"date_desc"}>Newest To Oldest</Select.Option>
                            <Select.Option value={"rating_desc"}>Highest Rated</Select.Option>
                            <Select.Option value={"rating_asc"}>Lowest Rated</Select.Option>
                        </Select>
                    </div>
                </div>
                <div className={`w-100 d-flex justify-content-center ${isMobile ? "py-3" : "py-5"}`}>
                    {reviewsList?.reviews_list?.length > 0 ?
                        <Row gutter={[10]} className="equal-height-row w-100">
                            {reviewsList?.reviews_list?.map((review, index) => {
                                return <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className='mt-3 w-100'>
                                    <ReviewComponent
                                        review={review}
                                        itemKey={itemKey}
                                        reviewKey={review?.key}
                                        getReviews={getReviews}
                                    />
                                </Col>;
                            })}
                        </Row> :
                        <img src={NoReviews} alt='no-reviews' />}
                </div>
                {
                    reviewsList?.reviews_list?.length > 0 && <Row className="justify-content-center my-2">
                        <Pagination
                            defaultCurrent={1}
                            total={parseInt(pages) * 10}
                            current={parseInt(currentPage) ?? 1}
                            onChange={(value) => {
                                setCurrentPage(value);
                            }}
                            showSizeChanger={false} />
                    </Row>
                }
                <AddReviewModal
                    itemKey={itemKey}
                    getReviews={getReviews}
                    isModalOpen={isAddReviewModalOpen}
                    setIsModalOpen={setIsAddReviewModalOpen}
                />
            </Spin >
        </div >
    );
}
