import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getImageUrlSrc } from "services/apis";

import Slider from "react-slick";
import { Image } from "antd";
import { modifyFileKey } from "services/helpers";

export default function ProductImgs({ data }) {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const defaultImage = data?.[modifyFileKey("images")].length > 0 ? data?.[modifyFileKey("images")][0] : "";
    const [mainImage, setMainImage] = useState(defaultImage);

    const settings = {
        dots: false,
        slidesToShow: isMobile ? 3.5 : 4.5,
        slidesToScroll: 1,
        infinite: false,
        swipeToSlide: true,
        draggable: true,
        speed: 300
    };

    return (
        <Image.PreviewGroup >
            <div className="bordered w-100 text-center p-3">
                <Image
                    style={{ height: "400px", maxWidth: "100%", objectFit: "contain" }}
                    src={getImageUrlSrc(mainImage, null)}
                    alt={data?.title}
                />
            </div>
            <div className="mt-3">
                <Slider {...settings} className="gx-1 w-100">
                    {
                        data?.[modifyFileKey("images")].map((item, index) => {
                            return (
                                <div key={`item-${index}`} className="pe-2" >
                                    <Image
                                        className={`p-2 cursor-pointer ${mainImage === item ? "image-selected-bordered" : "bordered"}`}
                                        onClick={() => setMainImage(item)}
                                        style={{ width: "100px", height: "100px", objectFit: "contain" }}
                                        src={getImageUrlSrc(item, 200)}
                                        alt={data?.title}
                                        preview={false}
                                    />
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </Image.PreviewGroup>
    );
}