import { Col, Row } from "antd";
import LoadingSpinner from "components/common/loading-spinner";
import ProductItem from "components/common/product-item";
import { useMediaQuery } from "react-responsive";
import { StyledPagination } from "styles/globalStyling.styled";
import useQueryParams from "hooks/useQueryParams";

export default function Products({ is_favorite, items, pages, isLoading, setItems, getStoreItems }) {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const { queryParams, setQueryParams } = useQueryParams({ item: "", category: [], brand: [], min_price: null, max_price: null, order_by: "", direction: "", page: 1 });

  let productsGutter;
  if (isMobile) {
    productsGutter = [5, 5];
  } else {
    productsGutter = [20, 20];
  }

  return (
    <div>
      <Row gutter={[...productsGutter]} className="justify-content-center mt-3">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          items?.map((item, index) => {
            return (
              <Col lg={6} md={8} sm={12} xs={12} key={`product-item-${index}`}>
                <ProductItem
                  item={item}
                  allItems={items}
                  setItems={setItems}
                  is_from_favorite={is_favorite}
                  getStoreItems={getStoreItems}
                />
              </Col>
            );
          })
        )}
      </Row>
      {
        items?.length > 0 && (
          <Row className="justify-content-center my-5">
            <StyledPagination
              defaultCurrent={1}
              total={pages * 10}
              current={queryParams?.page ?? 1}
              onChange={(value) => {
                window.scrollTo(0, 0);
                setQueryParams({ page: value })
              }}
              showSizeChanger={false}
            />
          </Row>
        )
      }
    </div >
  );
}
