export default function StockStatus({ data }) {
    return (
        data
            ? <div className="d-flex bold-16">
                {
                    data?.in_stock
                        ? (
                            <div className="green-text-color">
                                In Stock
                            </div>
                        )
                        : (
                            <div className="secondary-color">
                                Out of Stock
                            </div>
                        )
                }
            </div>
            : null
    )
}