import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { apiGet } from 'services/apis';

export default function PasswordCounter({ setCounter, setCallBackCounter }) {
    const [duration, setDuration] = useState(60000);
    useEffect(() => {
        apiGet("/get_resend_code_limit")
            .then((res) => {
                if (res.data.status === "SUCCESS") {
                    setDuration(res?.data.payload?.recurrent_limit * 60000);

                }
            });

    }, []);

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return null;
        } else {
            // Render a countdown
            return (
                <span>
                    {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
                </span>
            );
        }
    };
    return (
        <>
            <Countdown
                onStart={() => setCounter(true)}
                onComplete={() => {
                    setCounter(false);
                    setCallBackCounter(false);
                }}
                date={Date.now() + duration}
                renderer={renderer}
            />
        </>
    );
}
