//LOCAL STORAGE KEYS
export const KEY_AUTH_TOKEN = "auth_token";
export const KEY_DEVICE_ID = "device_id";
export const NEW_STORAGE_ENABLED_KEY = 'NEW_STORAGE_ENABLED_KEY';

//ERROR RESPONSE KEYS
export const INVALID_AUTH_TOKEN = "INVALID_AUTH_TOKEN";

export const DATE_FORMAT = "YYYY-MM-DD";

export const USER_TYPE_CUSTOMER = "customer";
export const USER_TYPE_VENDOR = "vendor";
export const USER_TYPE_ALL = "all";

export const ITEM_TYPE_VENDOR_PRODUCT = "VENDOR_PRODUCT";
export const ITEM_TYPE_LOCAL_PRODUCT = "LOCAL_PRODUCT";

export const EMAIL = "sales@emolo.com";
export const PHONE_NUMBER = "+18185611034";

export const UNKNOWN_ERROR_MESSAGE = "This action cannot be completed at the moment, please try again later or contact support if you continue to see this message.";

export const LIMIT = 60;

export const REVIEWS_PAGE_SIZE = 4;

const { REACT_APP_GOOGLE_RECAPTCHA_KEY: GOOGLE_RECAPTCHA_KEY_ENV } = process.env;
export const GOOGLE_RECAPTCHA_KEY = GOOGLE_RECAPTCHA_KEY_ENV;

export const { REACT_APP_STRIPE_PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY } = process.env;