import { HOME } from 'constants/routes-constants';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'services/authentication';

function PrivateRoute({ children, isPreventRoute = false, ...rest }) {
    let isAuthenticatedLocal = isAuthenticated()

    return (
        <Route
            {...rest}
            render={
                ({ location }) => (
                    isAuthenticatedLocal && !isPreventRoute
                        ? location.pathname === HOME
                            ? <Redirect to={HOME} />
                            : (children)
                        : (
                            <Redirect
                                to={{
                                    pathname: HOME,
                                    state: { from: location }
                                }}
                            />
                        ))
            }
        />
    );
}

export default PrivateRoute;