import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';

export default function CurrencyInput({ id = "", onChange, value, disabled, size = "default", allowNegative = false, className, ...rest }) {
    const [InputValue, setInputValue] = useState(value);

    useEffect(() => {
        if (value || value === 0) {
            if ((String(value).includes("$"))) {
                let localValue = value.replace("$", "");
                setInputValue(localValue);
            } else {
                setInputValue(value);
            }
        } else {
            setInputValue(null);
        }
    }, [value]);

    return (
        <>
            <InputNumber
                bordered
                {...rest}
                id={id}
                size={size}
                className={`w-100 ${className}`}
                defaultValue={null}
                disabled={disabled}
                formatter={(value) => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                onChange={onChange}
                precision={2}
                value={InputValue}
                controls={false}
                min={allowNegative ? undefined : 0}
                onKeyDown={(e) => {
                    const re = allowNegative ? /[0-9A-F:h.t-]+/g : /[0-9A-F:h.t]+/g;
                    if (!((e.ctrlKey && e.key === 'c') || (e.ctrlKey && e.key === 'v') || (e.ctrlKey && e.key === 'x') || re.test(e.key) || e.key === 'Tab')) {
                        e.preventDefault();
                    }
                }}
            />
        </>
    );
}
