import { Col, Drawer, Row } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilteringCirteria } from "reduxToolkit/appReducer.redux";
import { SubCategoryMainMenu } from "./homeCatalog.styled";
import { useHistory } from "react-router-dom";
import { LOG_IN, NEW_ORDER } from "constants/routes-constants";

export default function CategoriesDrawer({
  categories,
  drawerOpen,
  setDrawerOpen,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const credentials = useSelector((state) => state?.credentials);
  const selectedSubcategory = useSelector((state) => state?.app?.mainFilter?.subCategory);

  const selectCategory = (category) => {
    dispatch(
      setSelectedFilteringCirteria({
        category: category?.slug,
        categoryName: category?.name,
        subCategory: "",
        subCategoryName: "",
        is_favorite: false
      })
    );
    reRouteToNewOrder();
  };

  const selectSubCategory = (category, subCategory) => {
    dispatch(
      setSelectedFilteringCirteria({
        category: category?.slug,
        categoryName: category?.name,
        subCategory: subCategory?.slug,
        subCategoryName: subCategory?.name,
        is_favorite: false
      })
    );
    reRouteToNewOrder();
  };

  const viewAllItems = () => {
    dispatch(
      setSelectedFilteringCirteria({
        category: "",
        categoryName: "",
        subCategory: "",
        subCategoryName: "",
        is_favorite: false
      })
    );
    reRouteToNewOrder();
  };

  const reRouteToNewOrder = () => {
    setDrawerOpen(false);
    if (credentials?.isLoggedIn) {
      history.push(NEW_ORDER);
    } else {
      history.push(LOG_IN);
    }
  };

  return (
    <Drawer
      title={null}
      footer={
        <div
          className="subtitle-2 primary-color cursor-pointer"
          onClick={viewAllItems}
        >
          <span className="me-2">View all Items</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      }
      style={{
        top: 122,
      }}
      bodyStyle={{ padding: "20px 40px" }}
      height={550}
      placement={"top"}
      closable={false}
      onClose={() => setDrawerOpen(false)}
      open={drawerOpen}
    >
      <Row gutter={[20]} className="justify-content-center">
        <Col xs={24} sm={24} md={24} lg={24} xl={18}>
          <Row>
            {categories?.map((category, index) => {
              return (
                <Col
                  key={"category" + index} xs={12} sm={12} md={8} lg={6} xl={6}>
                  <div
                    className="subtitle-2 primary-color mt-1 mb-1 cursor-pointer"
                    onClick={() => {
                      selectCategory(category);
                    }}
                  >
                    {category?.name}
                  </div>
                  <div>
                    {category?.subcategories?.map((subCat, index) => {
                      return (
                        <SubCategoryMainMenu
                          key={`subCat-${index}`}
                          className="subtitle cursor-pointer"
                          onClick={() => {
                            selectSubCategory(category, subCat);
                          }}
                          is_active={selectedSubcategory === subCat?.slug}
                        >
                          {subCat?.name}
                        </SubCategoryMainMenu>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
        {/* <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={6}
          className={
            level5
              ? "d-flex justify-content-start align-items-center flex-column mt-5"
              : "d-flex justify-content-start align-items-start flex-column"
          }
        >
          <img src={CatalogDrawerImage} alt="catalog_drawer_img" />
          <span className="headline-6 mt-4 mb-3">Top deals</span>
          <TopDealsComponent />
          <TopDealsComponent />
          <TopDealsComponent />
        </Col> */}
      </Row>
    </Drawer>
  );
}
