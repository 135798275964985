import SignUpComponent from "components/signup";
import React from "react";

export default function SignUp() {
  return (
    <div>
      <SignUpComponent />
    </div>
  );
}
