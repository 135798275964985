import {
  Button,
  Col,
  Input,
  InputNumber,
  Menu,
  PageHeader,
  Select,
  Tabs,
  AutoComplete,
  Checkbox,
  Collapse,
  Drawer,
  Pagination
} from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  .img-contentStyle {
    text-align: center;
    @media screen and (max-width: 499px) {
      height: 30px;
      line-height: 30px;
    }
    @media screen and (min-width: 1025px) {
      height: 90px;
      line-height: 90px;
      max-width: 80%;
    }
    @media screen and (min-width: 500px) and (max-width: 1023px) {
      height: 80px;
      line-height: 80px;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  flex: auto;
  background: rgba(82, 203, 255, 0.03);
  justify-content: flex-end;
  color: var(--color-black);
  border-right: 0;
  margin-right: 40px;
  .ant-menu-item.ant-menu-item {
    margin: 5px 1px;
    border-radius: 30px;
    &:hover {
      color: var(--color-black) !important;
    }
  }
  .ant-menu-item-selected {
    background-color: var(--orange-pimary-color) !important;
    border-radius: 20px;
  }
`;

export const HeaderMenuItems = styled(Menu.Item)`
  top: 0px !important;
  border-bottom: 0px solid transparent !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  &::after {
    border-bottom: unset !important;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  line-height: 18px;
  color: white !important;
`;

export const CategoryStyledMenu = styled(Menu)`
  background: white;
  border-right: unset;
  .ant-menu-sub.ant-menu-inline {
    background: rgba(82, 203, 255, 0.1);
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    color: #c5161e;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected::after {
    display: none;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent;
  }
  .ant-menu-submenu-open .ant-menu-submenu-title {
    border-left: 7px solid var(--secondary-color);
  }
  .ant-menu-submenu-title {
    height: 21px !important;
    line-height: 21px !important;
    margin-top: 9px !important;
    margin-bottom: 9px !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }
`;

export const SearchHeaderSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 30px !important;
    background-color: #F6F6F6 !important;
    border: unset !important;
    height: 100% !important;
  }
  .ant-select-selection-item {
    padding-top: 5px !important;
    padding-left: 5px !important;
  }
`;

export const SearchHeaderContainer = styled.div`
  border-radius: 30px;
  border: 2px solid #F5F5F5;
  background-color: white;
`;

export const SearchHeaderInput = styled(AutoComplete)`
  background-color: transparent;
  height: 40px;
  width: 99%;

  .ant-select-selector {
    height: 40px !important;
    border: unset !important;
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }

  input {
    height: 40px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
  .anticon-search {
    width: 15.66px !important;
    height: 14.79px !important;
  }

  .ant-input-search-button {
    height: 40px !important;
    border: unset !important;
  }

  .ant-input-group-addon {
    direction: rtl !important;
    background-color: transparent !important;
    padding: 9px 20px;
    .ant-input-search-button {
      color: var(--black-text) !important;
      border-radius: 30px !important;
    } 
  }

  .ant-input-affix-wrapper {
    border: unset !important;
    background-color: transparent;
    .ant-input {
      background-color: transparent !important;
    }
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;
export const SearchHeaderInputMobile = styled(Input.Search)`
  background-color: #f3f3f3;
  .anticon-search {
    width: 15.66px !important;
    height: 14.79px !important;
  }

  .ant-input-search-button {
    border: unset !important;
  }

  .ant-input-group-addon {
    direction: rtl !important;
    background-color: #f3f3f3;
    padding: 9px 20px;
  }

  .ant-input-affix-wrapper {
    border: unset !important;
    background-color: #f3f3f3;
  }

  .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .ant-input-search-button {
    background-color: #f3f3f3;
  }

  .ant-input {
    background-color: #f3f3f3;
  }
`;

export const SortBySelect = styled(Select)`
  min-width: 150px;

  .ant-select-selector {
    background-color: transparent !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 4px !important;
    height: 100% !important;
  }

  .ant-select-selection-item {
    line-height: 41px !important;
    padding-left: 60px !important;
  }

  .ant-select-arrow {
    color: black;
    font-size: 8px;
  }
`;
export const StyledPageHeader = styled(PageHeader)`
  .ant-page-header-back {
    font-size: ${(props = null) => props?.font ?? "40px"};
  }
  .ant-page-header-heading-title {
    font-size: ${(props = null) => props?.font ?? "40px"};
  }
`;

export const StyledDiv = styled.div`
  width: 28px;
  height: 28px;

  background: ${(props = null) => props?.color};
  border: 1px solid #00B2FF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
`;

export const StyledInput = styled(Input)`
  height: 36px;
  border-radius: 4px;
`;

export const StyledPasswordInput = styled(Input.Password)`
    height: 36px;
    border-radius: 4px;
`;

export const StyledSelect = styled(Select)`
  .ant-select-selection-placeholder {
    margin-top: 3px !important;
    margin-bottom: 6.1px !important;
  }
  .ant-select-selection-item {
    margin-top: 3px !important;
    margin-bottom: 6.1px !important;
  }
  .ant-select-selector {
    height: 36px !important;
    border: 1px solid rgb(38, 50, 56, 0.16) !important;
    border-radius: 4px !important;
  }
`;

export const StyledButton = styled(Button)`
    height: ${(prop) => prop.height ?? "32px"};
    width: ${(prop) => prop.width ?? "auto"};
    border-radius:${(prop) => prop.radius ?? "4px"};
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #00b2ff;
        color:#fff;
    }
    &:active {
        background: #00b2ff;
        color:#fff;
    }
    &:focus {
        background: #00b2ff;
        color:#fff;
    }
`;

export const StyledGhostButton = styled(Button)`
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #00b2ff;
    background: #fff;
    color: #00b2ff;
    &:hover {
        background: #fff;
        color: #00b2ff;
        border: 1px solid #00b2ff;
    }
    &:active {
        background: #fff;
        color: #00b2ff;
        border: 1px solid #00b2ff;
    }
    &:focus {
        background: #fff;
        color: #00b2ff;
        border: 1px solid #00b2ff;
    }
`;

export const StyledTextArea = styled(Input.TextArea)`
  background: #ffffff;
  border: 1px solid #C3C3C3;
  border-radius: 4px;
  padding: 13px 10px;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1023px) {
    padding: 7px 10px;
  }
`;

export const StyledInputSearch = styled(Input.Search)`
  .ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    padding: 9px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    border-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .ant-btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

export const QtyContainerItemsGrid = styled.div`
  width: 70px;
  height: 50px;
  background: #f5f7ff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000000;
`;

export const ShippingMethodSelect = styled(Select)`
  & .ant-select-selector {
    background: white !important;
  }
  & .ant-select-selector .ant-select-selection-placeholder {
    color: #6c757d !important;
  }
  &.ant-select > .ant-select-arrow {
    color: black !important;
  }
`;
export const ResendButton = styled(Button)`
  font-weight: 400;
  font-size: 23px;
  line-height: 34px;
  text-align: center;
  color: #ff8a00;
`;
export const QtyInputNumber = styled(InputNumber)`
  .ant-input-number-input {
    width: 70px;
    height: 50px;
    padding: 0 11px;
    text-align: center;
    border: 0;
    background: #f5f7ff;
    border-radius: 6px;
  }
  .ant-input-number-handler-wrap {
    border: 1px solid #f5f7ff;
  }
`;
export const SerialNbDiv = styled.div`
  width: 184px;
  height: 43px;
  background: rgba(82, 203, 255, 0.38);
  border-radius: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #353b48;
`;

export const InputNumberQTY = styled(InputNumber)`
  .ant-input-number-input {
    height: 53px;
    border: 1px solid rgba(0, 0, 0, 0.19);
    border-radius: 52px !important;
  }
  .ant-input-number-handler-wrap {
    border: 1px solid #f5f7ff;
  }
`;

export const CartInput = styled(InputNumber)`
  border-radius: 4px !important;
  border: 1px solid #D9D9D9 !important;
  height: 42px;
  .ant-input-number-wrapper {
    min-width: 120px;
    max-width: 150px;
  }
  .ant-input-number-handler-wrap {
    border: unset;
  }
  .ant-input-number-group-addon {
    border: unset;
    background-color: transparent;
    padding: 0 6px;
    height: 42px;
  }
  .ant-input-number {
    border-radius: 0px !important;
    border: unset !important;
    height: 40px !important;
  }
  input {
    text-align: center;
  }
  @media screen and (max-width: 400px) {
    .ant-input-number-wrapper {
      min-width: 100px;
      max-width: 100px;
    }
    .ant-input-number-group-addon {
      padding: 0px 2px;
    }
  }
`;

export const CartDiv = styled.div`
  .ant-badge-count {
    top: 2px;
    right: 9px;
    font-size: 12px;
    font-weight: 700;
    background-color: var(--black-text-color);
    border: unset;
    box-shadow: unset;
    line-height: 18px;
  }
  .ant-badge-count {
    min-width: 18px;
    height: 18px;
    padding: 0 6px;
  }
`;

export const TabsCustom = styled(Tabs)`
  .ant-tabs-tab {
    color: ${(props = null) => props?.inactiveColor ?? "var(--black-text-color)"};
    font-size: ${(props = null) => props?.fontSize ?? "20px"};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--black-text-color);
    font-size: ${(props = null) => props?.fontSize ?? "20px"};
    font-style: normal;
    font-weight: ${(props = null) => props?.fontWeight ?? "700"};
    line-height: normal;
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--primary-color);
  }
  .ant-tabs-ink-bar {
    background-color: var(--orange-pimary-color);
    height: 6px !important;
    width: ${(props = null) => props?.barWidth ?? "70px !important"} ;
  }

  @media screen and (max-width: 1023px) {
    .ant-tabs-tab {
      font-size: 16px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 16px;
    }
    .ant-tabs-ink-bar {
      height: 4px !important;
      width: ${(props = null) => props?.barWidth ?? "50px !important"};
    }
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
    margin-top: 7px;
  }
`;

export const CollapseCustom = styled(Collapse)`
  .ant-collapse-header {
    padding-left: 0px !important;
    padding-bottom: 5px !important;
  }
  .ant-collapse-content-box {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
`;

export const StyledDrawer = styled(Drawer)`
  z-index: 100;
  color: var(--black-text-color);

  .ant-drawer-header {
    padding-top: 20px;
    padding-bottom: 20px;
    border: unset;
    background-color: var(--ligh-blue-color);
  }

  .ant-menu {
    padding-top: 50px;
    background: transparent;
  }
  
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent !important;
  }

  .ant-menu-title-content {
    text-align: center;
  }

  .ant-drawer-content-wrapper {
    width: 250px !important;
  }
  
  .ant-drawer-body {
    padding: 0;
    .ant-layout-sider {
      width: 250px !important;
      max-width: 250px !important;
    }
  }

  .ant-drawer-mask {
    background: background: rgba(0, 0, 0, 0.64);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
    font-style: normal;
    font-weight: 800;
    color: var(--primary-color) !important;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: unset !important;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-title {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  text-decoration: none;
`;

export const StyledPagination = styled(Pagination)`
  
.ant-pagination-item {
  border-radius: 10px;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  border-radius: 50%;
}

.anticon svg {
    margin-top: -4px;
  }
`;


export const StyledPillLink = styled(Link)`
  border-radius: 20px;
  border: 1px solid ${(props = null) => props?.selected ? "#00B2FF" : "#D9D9D9"}  !important;
  background-color: ${(props = null) => props?.selected ? "#00B2FF" : "#FFF"}  !important;
  width: fit-content;
  color: ${(props = null) => props?.selected ? "#fff" : "#1D1D1B"} !important;
  padding: 8px 12px;
  min-width: fit-content;
`;