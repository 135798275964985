import ReactCodeInput from "react-code-input";
import styled from "styled-components";


const ReactCodeInputStyled = styled(ReactCodeInput)`
    input {
        font-family: monospace;
        border-radius: 4px;
        border: 1px solid #C3C3C3;
        margin: 0 20px 10px 0;
        padding-left: 8px;
        width: 70px;
        height: 90px;
        font-size: 32px;
        box-sizing: border-box;
        background-color: white;
        @media screen and (max-width: 500px) {
            width: 40px;
            height: 50px;
            margin: 0 10px 10px 0;
        }
    }
    
    input:hover {
        border-color: var(--primary-color) !important;
    }
    
    input:focus {
        border-color: var(--primary-color) !important;
        outline: 0 !important;
    }
    
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    /* Firefox */
    input[type="number"] {
        appearance: textfield;
        text-align: center;
        padding: 0px;
    }
`;

export default ReactCodeInputStyled;