import SignUpVendorsComponent from "components/sign-up-vendors";
import React from "react";

export default function SignUpVendors() {
    return (
        <div>
            <SignUpVendorsComponent />
        </div>
    );
}
