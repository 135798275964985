import { Col, Form, Input, Row } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import PasswordChecklist from "react-password-checklist";
import { MyAccountInputsPass } from "pages/my-account/my-account.styled";
import { isValidPhoneNumber } from "react-phone-number-input";
import { StyledInput } from "styles/globalStyling.styled";
import { useMediaQuery } from "react-responsive";
export default function VendorSignupInfo({
    hiddenInput,
    setHiddenInput,
    setEmail,
    email,
    setPassword,
    password,
    setConfirm,
    confirm,
    setisValid,
    setContactName,
}) {
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    return (
        <>
            <Row
                className={
                    isMobile
                        ? "d-flex justify-content-center mt-4"
                        : "d-flex justify-content-center"
                }
            >
                <Form.Item name="hiddenInput" style={{ display: "none" }}>
                    <Input
                        value={hiddenInput}
                        onChange={(e) => setHiddenInput(e.target.value)}
                    />
                </Form.Item>
            </Row>

            <Row className="d-flex justify-content-center" gutter={[15]}>
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Contact Name<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                        name="contact_name"
                    >
                        <StyledInput
                            style={{ width: "100%" }}
                            placeholder="Contact Name"
                            onChange={(e) => setContactName(e.target.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Company Name
                    </label>
                    <Form.Item name="company_name">
                        <StyledInput
                            style={{ width: "100%" }}
                            placeholder="Company Name"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Company Website
                    </label>
                    <Form.Item name="company_website">
                        <StyledInput
                            style={{ width: "100%" }}
                            placeholder="Company Website"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Email<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "This field is required!" }]}
                    >
                        <StyledInput
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ width: "100%" }}
                            placeholder="Email"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20} className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                    <label className="body-2 mb-2">
                        Phone number 1 <span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        name="msisdn"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                            () => ({
                                validator(_, value) {
                                    if (value && isValidPhoneNumber("+" + value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error("Invalid Phone Number!"));
                                },
                            }),
                        ]}
                    >
                        <PhoneInput
                            country={"us"}
                            inputStyle={{ width: "100%", height: "56px" }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={22} sm={22} md={22} lg={12} xl={12}>
                    <label className="body-2 mb-2">Work number</label>
                    <Form.Item
                        initialValue={undefined}
                        name="work_msisdn"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value || isValidPhoneNumber("+" + value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error("Invalid Phone Number!"));
                                },
                            }),
                        ]}
                    >
                        <PhoneInput
                            country={"us"}
                            inputStyle={{ width: "100%", height: "56px" }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Password<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <MyAccountInputsPass
                            autoComplete="new-password"
                            style={{ width: "100%" }}
                            placeholder="Enter Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col xs={22} sm={22} md={22} lg={24} xl={24}>
                    <label className="body-2 mb-2">
                        Confirm Password<span style={{ color: "#EB1E4B" }}>{"\xa0*"}</span>
                    </label>
                    <Form.Item
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: "This field is required!",
                            },
                        ]}
                    >
                        <MyAccountInputsPass
                            autoComplete="new-password"
                            onPaste={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            onChange={(e) => setConfirm(e.target.value)}
                            style={{ width: "100%" }}
                            placeholder="Confirm Password"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital"]}
                minLength={6}
                value={password}
                valueAgain={confirm}
                onChange={(isValid) => {
                    isValid ? setisValid(true) : setisValid(false);
                }}
                style={{
                    display: "none",
                }}
            />
        </>
    );
}
