import { INVALID_AUTH_TOKEN } from "constants/app-constants";
import { invalidAuthTokenFunc } from "services/authentication";

export const responseErrorInterceptor = (error) => {
  if (error?.message?.includes(INVALID_AUTH_TOKEN)) {
    invalidAuthTokenFunc();
  }
  return Promise.reject(error);
};

export const responseSuccessInterceptor = (response) => {
  if (response?.data?.status === "FAIL" && response?.data?.message?.includes(INVALID_AUTH_TOKEN)) {
    invalidAuthTokenFunc();
  }
  return response;
};
