import no_image from "assets/icons/no_image.jpg";
import EmptyProfile from "assets/icons/empty-profile-image.png";
import { getInstance } from "./axios-instance";

const {
  REACT_APP_BUCKET_ENV: BUCKET_ENV,
  REACT_APP_IMAGE_API_URL: BASE_IMAGE_API_URL,
} = process.env;

//POST API
export function apiPost(url, data) {
  const axiosInstance = getInstance(window.location.pathname);

  var form_data = new FormData();

  var new_data = Object.keys(data).map((key) => ({
    key,
    value: typeof data[key] == 'string'
      ? data[key].trim()
      : data[key]
  }));

  for (var i = 0; i < new_data.length; i += 1) {
    var x = new_data[i];
    form_data.append(x.key, x.value);
  }
  return axiosInstance.post(url, form_data);
}

//GET API
export function apiGet(url) {
  const axiosInstance = getInstance(window.location.pathname);
  return axiosInstance.get(url);
}

//POST IMAGE API
export function apiPostImage(rawImageFile) {
  const axiosInstance = getInstance(window.location.pathname);

  const formData = new FormData();
  formData.append("file", rawImageFile);

  let imageHeaders = {
    headers: {
      "Bucket-Env": BUCKET_ENV,
    },
  };

  return axiosInstance.post(`${BASE_IMAGE_API_URL}/image`, formData, imageHeaders);
}

export function getImageUrl(name, s = null) {
  if (s === null) {
    return `${BASE_IMAGE_API_URL}/${name}?bucketEnv=${BUCKET_ENV}`;
  }
  return `${BASE_IMAGE_API_URL}/${name}/${s}?bucketEnv=${BUCKET_ENV}`;
}

export function getImageUrlSrc(image, size = 200, profile = false) {
  return image && image !== "---"
    ? isValidUrl(image)
      ? image
      : getImageUrl(image, size)
    : profile
      ? EmptyProfile
      : no_image;
}

export function isValidUrl(_string) {
  const matchpattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/gm;
  return matchpattern.test(_string);
}
