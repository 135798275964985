import { createSlice } from "@reduxjs/toolkit";
import { getAuthToken } from "services/authentication";

const initialState = {
  user: {},
  permissions: [],
  isLoggedIn: Boolean(localStorage.getItem("isLoggedIn")),
};

export const credentialsSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    getCredentials: (state) => {
      if (getAuthToken() !== null) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    signOut: (state, action) => {
      state.isLoggedIn = false;
    }
  },
});

export const { getCredentials, getUser, signOut } = credentialsSlice.actions;

export default credentialsSlice.reducer;
