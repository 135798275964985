import { UNKNOWN_ERROR_MESSAGE } from "constants/app-constants";

export function errorResponseMapper(data) {
  let message = data?.message;
  let errorMessageInPOST = data?.payload?.POST?.join(" ");
  if (
    message?.toLowerCase()?.includes("deal_buyer") ||
    message?.toLowerCase()?.includes("deal buyer") ||
    message?.toLowerCase()?.includes("dealbuyer") ||
    message?.toLowerCase()?.includes("buyinggroup") ||
    message?.toLowerCase()?.includes("buying_group") ||
    message?.toLowerCase()?.includes("buying group") ||
    errorMessageInPOST?.toLowerCase()?.includes("deal_buyer") ||
    errorMessageInPOST?.toLowerCase()?.includes("deal buyer") ||
    errorMessageInPOST?.toLowerCase()?.includes("dealbuyer") ||
    errorMessageInPOST?.toLowerCase()?.includes("buyinggroup") ||
    errorMessageInPOST?.toLowerCase()?.includes("buying_group") ||
    errorMessageInPOST?.toLowerCase()?.includes("buying group")
  ) {
    return UNKNOWN_ERROR_MESSAGE
  }
  if (message === "UNKNOWN_ERROR") {
    return UNKNOWN_ERROR_MESSAGE;
  } else if (message === "NOT_ENOUGH_INFO") {
    let payload = data?.payload?.POST?.join(", ")
    let errMessage = "Missing " + payload;
    return errMessage;
  } else if (message === "INVALID_LOGIN") {
    return "Invalid email or password!";
  } else if (message === "ALREADY_REGISTERED") {
    return "User Already exist in the system!";
  } else if (message === "RECURRENT_LIMIT_EXCEEDED") {
    return "Too Many Tries";
  } else if (message === "DAILY_LIMIT_EXCEEDED") {
    return "Daily limit exceeded. Please try again tomorrow!";
  } else if (message === "INVALID_ID") {
    return "Invalid request!";
  } else if (message === "INVALID_UPC") {
    return "Invalid UPC code!";
  } else if (message === "ALREADY_COMMITED") {
    return "Deal already committed! You can change your amount from commitments page";
  } else if (message === "EXCEED_LIMIT") {
    return "Daily lookup limit exceeded!";
  } else if (message === "ALREADY_CREATED") {
    return "Order already created for this tracking number!";
  } else if (message === "PAYMENT_PROCESSED") {
    return "Cannot edit a paid/partially-paid orders!";
  } else if (message === "Password must contain:\n - At least 1 lowercase letter, a-z.\n") {
    return "Please use strong password (small letter, capital letter, number & special character)!";
  } else if (message === "MISSING_WHOLE_SALE_ACCOUNT") {
    return "Missing Wholesale Account! Please make sure you have a wholesale account in wholesale accounts page and a default one.";
  } else if (message === "INVALID_GROUP_NAME") {
    return "Group Name Already Used!";
  } else if (message === "NO_INVENTORY_RECORD") {
    return "No inventory record for this item!";
  } else if (message === "INVALID_TRACKING_ID") {
    return "Invalid Tracking Number!";
  } else if (message === "ITEM_UPC_ALREADY_EXISTS") {
    return "Item UPC Already Exists!";
  } else if (message === "LOGIN_BLOCKED ") {
    return "Too many failed login attempts. Please try again in 1 hour!";
  } else if (message === "ITEM_SERIAL_REQUIRED") {
    return "Item serial required. You may edit from the items tab!";
  } else {
    if (message) {
      const arr = message?.toString()?.replaceAll("_", " ")?.toLowerCase()?.split(" ");
      for (var i = 0; i < arr?.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    } else {
      return UNKNOWN_ERROR_MESSAGE;
    }
  }
}
