import { useEffect, useState } from "react";
import { MY_ORDERS_MENU, MY_ACCOUNT, MY_ACCOUNT_CHANGE_PASS, MY_ACCOUNT_EDIT, MY_ORDER_ID, NEW_ORDER, RMA_ROUTE, MY_CART, NEW_UPDATES, MY_ACCOUNT_RESET_PASS, RMA_REQ, RMA_VIEW, MOBILE_CHECKS, NOTIFICATION, ITEM_VIEW, MANAGE_INVENTORIES_TABS, MANAGE_INVENTORIES_ADD_PRODUCT, WISHLIST, INVOICE_DETAILS, PAYMENT_METHODS, MY_REVIEWS } from "constants/routes-constants";
import { useSelector } from "react-redux";
import { lazyWithRetry } from "services/lazyWithRetry";

const MyAccount = lazyWithRetry(() => import("pages/my-account"));
const MyAccountEdit = lazyWithRetry(() => import("pages/my-account/my-account-edit"));
const MyOrders = lazyWithRetry(() => import("pages/my-orders"));
const NewOrder = lazyWithRetry(() => import("pages/new-order"));
const NewUpdates = lazyWithRetry(() => import("pages/new-updates"));
const RMA = lazyWithRetry(() => import("pages/rma"));
const OrderDetails = lazyWithRetry(() => import("components/my-orders/orders/order-details"));
const MyCart = lazyWithRetry(() => import("pages/my-cart"));
const MyAccountResetPass = lazyWithRetry(() => import("components/my-account/my-account-edit/my-account-reset-pass"));
const RmaReq = lazyWithRetry(() => import("components/rma-comp/rma-req"));
const RmaView = lazyWithRetry(() => import("components/rma-comp/rma-view"));
const MobileChecks = lazyWithRetry(() => import("pages/mobile-checks/mobile-checks"));
const Notifications = lazyWithRetry(() => import("pages/notifications"));
const ProductView = lazyWithRetry(() => import("components/common/product-details"));
const MyAccountChangePass = lazyWithRetry(() => import("components/my-account/my-account-edit/my-account-change-pass"));
const ManageInventories = lazyWithRetry(() => import("pages/manage-inventories"));
const AddProduct = lazyWithRetry(() => import("pages/manage-inventories/tabs/product-listings/add-product"));
const Wishlist = lazyWithRetry(() => import("pages/wishlist"));
const InvoiceDetails = lazyWithRetry(() => import("components/my-orders/invoices/invoice-details"));
const PaymentMethods = lazyWithRetry(() => import("pages/payment-methods"));
const MyReviews = lazyWithRetry(() => import("pages/my-reviews"));

const useRoute = () => {
    const permissions = useSelector((state) => state?.app?.permissions);
    const credentials = useSelector((state) => state?.credentials);
    const userType = useSelector((state) => state?.app?.userType);
    const isRetail = credentials?.user?.type === "RETAIL";
    const [data, setData] = useState([]);

    useEffect(() => {
        setData([
            {
                path: NEW_ORDER,
                component: NewOrder,
                exact: true,
                hasPermission: permissions?.view_new_order,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MY_ORDERS_MENU,
                component: MyOrders,
                exact: true,
                hasPermission: permissions?.view_my_orders,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: INVOICE_DETAILS,
                component: InvoiceDetails,
                exact: true,
                hasPermission: permissions?.view_my_orders,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MY_ORDER_ID,
                component: OrderDetails,
                exact: true,
                hasPermission: permissions?.view_my_orders,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: NEW_UPDATES,
                component: NewUpdates,
                exact: true,
                hasPermission: permissions?.view_recent_activities,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: RMA_ROUTE,
                component: RMA,
                exact: true,
                hasPermission: permissions?.view_rma,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: RMA_REQ,
                component: RmaReq,
                exact: true,
                hasPermission: permissions?.view_rma,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: RMA_VIEW,
                component: RmaView,
                exact: true,
                hasPermission: permissions?.view_rma,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MY_ACCOUNT,
                component: MyAccount,
                exact: true,
                hasPermission: true,
                accessAllowed: true
            },
            {
                path: WISHLIST,
                component: Wishlist,
                exact: true,
                hasPermission: true,
                accessAllowed: true
            },
            {
                path: PAYMENT_METHODS,
                component: PaymentMethods,
                exact: true,
                hasPermission: isRetail,
                accessAllowed: true
            },
            {
                path: MY_ACCOUNT_EDIT,
                component: MyAccountEdit,
                exact: true,
                hasPermission: true,
                accessAllowed: false
            },
            {
                path: MY_CART,
                component: MyCart,
                exact: true,
                hasPermission: permissions?.view_cart,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MY_ACCOUNT_CHANGE_PASS,
                component: MyAccountChangePass,
                exact: true,
                hasPermission: true,
                accessAllowed: true
            },
            {
                path: MY_ACCOUNT_RESET_PASS,
                component: MyAccountResetPass,
                exact: true,
                hasPermission: true,
                accessAllowed: true
            },
            {
                path: MOBILE_CHECKS,
                component: MobileChecks,
                exact: true,
                hasPermission: permissions?.view_mobile_checks,
                accessAllowed: false /* userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true */
            },
            {
                path: NOTIFICATION,
                component: Notifications,
                exact: true,
                hasPermission: true,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: ITEM_VIEW,
                component: ProductView,
                exact: true,
                hasPermission: permissions?.view_products,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MANAGE_INVENTORIES_TABS,
                component: ManageInventories,
                exact: true,
                hasPermission: permissions?.view_manage_inventories,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MANAGE_INVENTORIES_ADD_PRODUCT,
                component: AddProduct,
                exact: true,
                hasPermission: permissions?.view_manage_inventories,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            },
            {
                path: MY_REVIEWS,
                component: MyReviews,
                exact: true,
                hasPermission: true,
                accessAllowed: userType?.toLowerCase() === "vendor" ? credentials?.user?.verified : true
            }
        ]);
    }, [credentials?.user?.verified, permissions, userType, isRetail]);

    return [data];
};

export default useRoute;