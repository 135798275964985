import React, { useRef, useState } from "react";

import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import { useHistory } from "react-router-dom";

import { HOME, LOG_IN } from "constants/routes-constants";
import { apiPost } from "services/apis";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import PasswordChecklist from "react-password-checklist";
import PasswordCounter from "components/password-counter/password-counter";
import { GOOGLE_RECAPTCHA_KEY, USER_TYPE_CUSTOMER } from "constants/app-constants";
import { errorResponseMapper } from "services/errorResponseMapper";
import ReactCodeComponent from "components/common/react-code-component";
import Breadcrumb from "components/common/breadcrumb";
import { TabsCustom } from "styles/globalStyling.styled";

// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";


export default function ForgetPassword() {
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const history = useHistory();
  const recaptchaRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [verif_req_id, setVerif_req_id] = useState("");
  const [isValid, setisValid] = useState(false);
  const [repeat, setRepeat] = useState("");
  const [password, setPassword] = useState("");
  const [counter, setCounter] = useState(true);
  const [callBackCounter, setCallBackCounter] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const validateEmail = () => {
    if (email !== "") {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var valid_email = re.test(email);
      if (valid_email) {
        return true;
      } else {
        setErrorMessage("Invalid email format");
        return false;
      }
    }
  };

  const validatePassword = () => {
    if (password !== "" && repeat !== "") {
      if (isValid) {
        if (password === repeat) {
          return true;
        } else {
          setErrorMessage("Password not matching!");
          return false;
        }
      } else {
        setErrorMessage(
          "Please use strong password (small letter, capital letter, number & special character)"
        );
        return false;
      }
    }
  };

  const identify = (resend) => {
    setErrorMessage("");
    setCounter(!counter);
    setCallBackCounter(true);
    apiPost("/identify", {
      user_id: email,
      verification_method: "EMAIL",
      verification_type: "RESET_PASSWORD",
      user_type: USER_TYPE_CUSTOMER, // form.getFieldValue(["user_type"])
      token: recaptchaToken
    })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setVerif_req_id(res.data.payload.verif_req_id);
          resend
            ? setCurrentStep((step) => step)
            : setCurrentStep((step) => step + 1);
        } else {
          setErrorMessage(errorResponseMapper(res.data));
        }
      })
      .finally(() => {
        recaptchaRef.current?.reset();
        setRecaptchaToken("");
      });
  };

  const verify = (value) => {
    setErrorMessage("");
    setIsLoading(true);
    apiPost("/verify", {
      user_id: email,
      code: value,
      verif_req_id: verif_req_id,
    })
      .then((res) => {
        if (res.data.status === "SUCCESS") {
          setCurrentStep((step) => step + 1);
        } else if (res.data.message === "INVALID_VERIFICATION_CODE") {
          form.resetFields(["code"]);
          setErrorMessage("Invalid Code!");
        } else {
          form1.resetFields();
          setErrorMessage(errorResponseMapper(res.data));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const resetPass = () => {
    if (validatePassword()) {
      setErrorMessage("");
      setIsLoading(true);
      apiPost("/reset_pass", {
        verif_req_id: verif_req_id,
        user_id: email,
        new_password: password,
        confirm_password: repeat,
        user_type: USER_TYPE_CUSTOMER // form.getFieldValue(["user_type"])
      })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            message.success("Password updated!");
            history.push(LOG_IN);
          } else if (res.data.message === "NOT_ENOUGH_INFO") {
            setErrorMessage("Unauthorized Access!");
          } else {
            setErrorMessage("Server Error! Please try again later.");
          }
        })
        .finally(() => setIsLoading(false));
    }
  };
  const onChange = (token) => { setRecaptchaToken(token); }

  const tabTitle =
    currentStep === 0
      ? "Enter your email"
      : currentStep === 1
        ? "Enter verification code "
        : "Enter new password"
    ;

  return (
    <div>
      <div className="mt-3">
        <Breadcrumb data={[{ path: HOME, title: "Home" }]} title="Forget Password" />
      </div>
      <div className="pt-lg-5 pt-4 w-100">
        <div className="mx-0 mx-lg-0">
          <Row className="justify-content-center">
            <Col
              className="p-lg-5 pt-lg-4 p-4 pt-3"
              style={{ border: "1px solid #F1F1F1" }}
              xl={currentStep === 1 ? 12 : 9}
              lg={currentStep === 1 ? 12 : 9}
              md={12}
              sm={24}
              xs={24}
            >
              <TabsCustom>
                <TabsCustom.TabPane tab={tabTitle} key="forget-password" className="pt-2">
                  {currentStep === 0 ? (
                    <Form
                      onFinish={() => {
                        if (validateEmail()) {
                          identify();
                        }
                      }}
                      layout="vertical"
                      form={form}
                    >
                      {/* <Form.Item
                    className="my-4 remove-asterisk"
                    name={"user_type"}
                    initialValue={null}
                    label={<label className="regular-14">User Type</label>}
                    rules={[{ required: true, message: "User type is required!" }]}
                  >
                    <Radio.Group defaultValue={null} >
                      <Radio value={USER_TYPE_CUSTOMER}>Customer</Radio>
                      <Radio value={USER_TYPE_VENDOR}>Vendor</Radio>
                    </Radio.Group>
                  </Form.Item> */}

                      <Form.Item
                        className="mb-4 remove-asterisk"
                        name="email"
                        label={<label className="regular-14">Email</label>}
                        rules={[
                          {
                            required: true,
                            message: "Email is required!",
                          },
                        ]}
                      >
                        <Input
                          className="py-2"
                          placeholder="Enter Username"
                          value={email}
                          onChange={(e) => {
                            if (e.target.value === "") {
                              setErrorMessage("");
                            }
                            setEmail(e.target.value);
                          }}
                        />
                      </Form.Item>

                      {errorMessage && (
                        <center>
                          <span style={{ color: "red" }}>{errorMessage}</span>
                        </center>
                      )}

                      <div className="w-100 d-flex justify-content-center">
                        <ReCAPTCHA
                          className="mx-auto g-recaptcha"
                          size="normal"
                          sitekey={GOOGLE_RECAPTCHA_KEY}
                          onChange={onChange}
                        />
                      </div>

                      <center>
                        <Button
                          className={`bold-16 py-2 mt-4 w-100`}
                          style={{ width: "100px" }}
                          htmlType="submit"
                          type="primary"
                          loading={isLoading}
                          disabled={!recaptchaToken || recaptchaToken === ""}
                        >
                          Submit
                        </Button>
                      </center>
                    </Form>
                  ) : currentStep === 1 ? (
                    <Form
                      onFinish={(value) => verify(value.code)}
                      layout="vertical"
                      form={form1}
                    >
                      <center>
                        <Form.Item
                          className="mt-3"
                          name="code"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <ReactCodeComponent />
                        </Form.Item>
                      </center>
                      {errorMessage && (
                        <center>
                          <span style={{ color: "red" }}>{errorMessage}</span>
                        </center>
                      )}
                      <center>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className={`bold-16 py-2 mt-4`}
                          style={{ width: "100px" }}
                          loading={isLoading}
                        >
                          Submit
                        </Button>
                        <Divider className="my-5" />
                        <div className="">
                          {
                            !counter && (
                              <>
                                <ReCAPTCHA
                                  className="mx-auto g-recaptcha"
                                  size="normal"
                                  sitekey={GOOGLE_RECAPTCHA_KEY}
                                  onChange={onChange}
                                />
                              </>
                            )}
                        </div>
                        <Button
                          className="mt-2 w-25"
                          disabled={counter || recaptchaToken === ""}
                          style={{ color: (counter || recaptchaToken === "") ? "lightgrey" : "#565f82" }}
                          type="link"
                          onClick={() => {
                            identify(true);
                            setCallBackCounter(true);
                          }}
                        >
                          Resend code
                        </Button>
                        {callBackCounter && (
                          <PasswordCounter
                            setCounter={setCounter}
                            setCallBackCounter={setCallBackCounter}
                          />
                        )}
                      </center>
                    </Form>
                  ) : (
                    <>
                      <Form onFinish={() => resetPass()} layout="vertical">
                        <Form.Item
                          name="newPassword"
                          label={<label className="regular-14">Password</label>}
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input.Password
                            value={password}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setErrorMessage("");
                              }
                              setPassword(e.target.value);
                            }}
                            iconRender={(visible) =>
                              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name="password2"
                          label={<label className="regular-14">Repeat Password</label>}
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input.Password
                            onPaste={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            value={repeat}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                setErrorMessage("");
                              }
                              setRepeat(e.target.value);
                            }}
                            iconRender={(visible) =>
                              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                            }
                          />
                        </Form.Item>
                        {errorMessage && (
                          <center>
                            <span style={{ color: "red" }}>{errorMessage}</span>
                          </center>
                        )}
                        <PasswordChecklist
                          rules={["minLength", "specialChar", "number", "capital"]}
                          minLength={6}
                          value={password}
                          valueAgain={repeat}
                          onChange={(isValid) => {
                            isValid ? setisValid(true) : setisValid(false);
                          }}
                          style={{
                            display: "none",
                          }}
                        />
                        <center>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                            className="w-25"
                          >
                            Save
                          </Button>
                        </center>
                      </Form>
                    </>
                  )}
                </TabsCustom.TabPane>
              </TabsCustom>
            </Col>
          </Row>
        </div>
      </div>

    </div>
  );
}
