import React from "react";
import { TabsCustom } from "styles/globalStyling.styled";
import ProductReviews from "./product-reviews";

export default function ProductDescription({ sortValue, setSortValue, pages, currentPage, setCurrentPage, getReviews, data, isGetReviewsLoading, reviewsList }) {

    if (data?.description || reviewsList?.reviews_list?.length > 0) {
        const description = data?.description?.replace(/\n/g, "<br>")?.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;");
        return (
            <section id="product-description-section" className=" w-100">
                <TabsCustom >
                    <TabsCustom.TabPane tab="DESCRIPTION" key="related-products" >
                        <div className="bold-18">About this item</div>
                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: description }} />
                    </TabsCustom.TabPane>
                    <TabsCustom.TabPane tab="REVIEWS" key="product-reviews" >
                        <ProductReviews
                            pages={pages}
                            sortValue={sortValue}
                            setSortValue={setSortValue}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            getReviews={getReviews}
                            itemKey={data?.key}
                            reviewsList={reviewsList}
                            isGetReviewsLoading={isGetReviewsLoading}
                        />
                    </TabsCustom.TabPane>
                </TabsCustom>
            </section>
        );
    } else {
        return null;
    }
}
