import { Col, Image, Modal, Row } from 'antd'
import React from 'react'
import ProductInfo from "./product-info";
import { getImageUrlSrc } from 'services/apis';
import DetailsIcon from "assets/icons/products/details-icon.svg";
import { PRODUCT_DETAIL } from 'constants/routes-constants';
import { getItemURI, modifyFileKey } from 'services/helpers';
import { Link, useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

export default function ProductQuickView({ data, isModalOpen, setIsModalOpen, allItems, setItems }) {
    const history = useHistory();
    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const link = PRODUCT_DETAIL.replace(":id", getItemURI(data));

    const updateAfterWishlist = (itemKey) => {
        let newItems = [...allItems];
        newItems.forEach((el) => {
            if (el.key === itemKey) {
                el.is_favorite = !el.is_favorite;
            }
        })
        setItems([...newItems]);
    }
    
    return (
        <Modal
            bodyStyle={{ minHeight: !isMobile && "650px", overflowY: "auto", padding: isMobile && "10px" }}
            open={isModalOpen}
            onCancel={() => { setIsModalOpen(false); }}
            footer={
                <Link to={{ pathname: link, state: { from: history.location.pathname?.concat(history.location.search ?? "") } }} className='text-end orange-text-color d-flex w-100 justify-content-end' >
                    <div className='me-2 bold-20'>
                        See Full Details
                    </div>
                    <img src={DetailsIcon} alt="" />
                </Link>
            }
            width={!isMobile && "80%"}
            centered
        >
            <Row>
                <Col lg={10} md={10} sm={24} xs={24}>
                    <div style={{ width: "80%" }} className='mx-auto text-center'>
                        <Image
                            style={{ height: isMobile ? "200px" : "400px", objectFit: "contain" }}
                            src={getImageUrlSrc(data?.[modifyFileKey("image")], null)}
                            alt="item"
                        />
                    </div>
                </Col>
                <Col lg={14} md={14} sm={24} xs={24} className={isMobile && 'mb-3'} >
                    <ProductInfo data={data} updateAfterWishlist={updateAfterWishlist} />
                </Col>
            </Row >
        </Modal>
    )
}
