import styled from "styled-components";

export const DealsContainer = styled.div`
  border: 1px solid #ff8a00;
  border-radius: 50px;
  width: 90%;
  padding: 10px 15px;
  height: 75px;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;
export const DiscountText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: var(--secondary-color);
`;
export const CategoriesGrid = styled.div`
  height: 200px;
  width: 200px;
  display: grid;
  gap: 10px;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  grid-auto-flow: column;
`;
export const SubCategoryMainMenu = styled.div`
  :hover {
    color: var(--primary-color) !important;
  }

  color: ${({ is_active }) => `${is_active ? "var(--primary-color)" : "black"}`};

`;

